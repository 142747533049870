import { AnyAction } from 'redux';
import { getProp } from 'sportnet-utilities';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Article, Section } from '../../api/CmsApi';
import {
  BreadCrumb,
  Codelist,
  ISportSectorCrews,
  ISportSectorDelegates,
  ISportSectorEvents,
  ISportSectorPhases,
  ISportSectorSetting,
} from '../../library/App';
import {
  ICompetition,
  ICompetitionGroup,
  ICompetitionPart,
} from '../../library/Competition';
import { IMatch } from '../../library/Match';
import { PO } from '../../library/PO';
import { PPO } from '../../library/PPO';
import { ISeason } from '../../library/Season';
import { loadArticle } from '../../pages/Article/actions';
import serializeParameters from '../../utilities/serializeParameters';
import { loadSection } from '../Section/actions';
import { loadPath } from '../UrlMap/actions';
import * as actions from './actions';

export type Writeable<T> = { -readonly [P in keyof T]-?: T[P] };

export type EntitiesState = Readonly<{
  CODELISTS: Readonly<{
    [key: string]: Readonly<Codelist>;
  }>;
  PPO: Readonly<{
    [key: string]: Readonly<PPO>;
  }>;
  PO: Readonly<{
    [key: string]: Readonly<PO>;
  }>;
  COMPETITION_GROUPS: Readonly<{
    [key: string]: Readonly<ICompetitionGroup>;
  }>;
  COMPETITIONS: Readonly<{
    [key: string]: Readonly<ICompetition>;
  }>;
  COMPETITION_PARTS: Readonly<{
    [key: string]: Readonly<ICompetitionPart>;
  }>;
  SEASONS: Readonly<{
    [key: string]: Readonly<ISeason>;
  }>;
  MATCHES: Readonly<{
    [key: string]: Readonly<IMatch>;
  }>;
  SPORT_SECTOR_PHASES: Readonly<{
    [key: string]: Readonly<ISportSectorPhases>;
  }>;
  SPORT_SECTOR_EVENTS: Readonly<{
    [key: string]: Readonly<ISportSectorEvents>;
  }>;
  SPORT_SECTOR_DELEGATES: Readonly<{
    [key: string]: Readonly<ISportSectorDelegates>;
  }>;
  SPORT_SECTOR_CREW: Readonly<{
    [key: string]: Readonly<ISportSectorCrews>;
  }>;
  SPORT_SECTOR_SETTINGS: Readonly<{
    [key: string]: Readonly<ISportSectorSetting>;
  }>;
  sections: Readonly<{
    [key: string]: Readonly<Section>;
  }>;
  articles: Readonly<{
    [key: string]: Readonly<Article>;
  }>;
}>;

export const EntitiesInitialState = {
  CODELISTS: {},
  PPO: {},
  PO: {},
  COMPETITION_GROUPS: {},
  COMPETITIONS: {},
  COMPETITION_PARTS: {},
  SEASONS: {},
  MATCHES: {},
  SPORT_SECTOR_PHASES: {},
  SPORT_SECTOR_EVENTS: {},
  SPORT_SECTOR_DELEGATES: {},
  SPORT_SECTOR_CREW: {},
  SPORT_SECTOR_SETTINGS: {},
  sections: {},
  articles: {},
};

export const entitiesReducer = (
  state: EntitiesState = EntitiesInitialState,
  action: AnyAction,
): EntitiesState => {
  if (getProp(action.payload, ['result', 'entities'])) {
    const k = Object.keys(
      action.payload.result.entities,
    ) as (keyof EntitiesState)[];

    return k.reduce(
      (acc: any, entity: keyof EntitiesState) => {
        acc[entity] = Object.keys(
          action.payload.result.entities[entity],
        ).reduce(
          (innerAcc: Writeable<EntitiesState[typeof entity]>, id) => {
            innerAcc[id] = {
              ...(acc[entity][id] || {}),
              ...action.payload.result.entities[entity][id],
            };
            return innerAcc;
          },
          { ...getProp(state, [entity], {}) },
        );
        return acc;
      },
      { ...state },
    );
  }
  return state;
};

export type ApplicationState = Readonly<{
  activeAppspace: string | null;
  activeCompetition: string | null;
  breadcrumbs: ReadonlyArray<BreadCrumb>;
  logo: string | null;
  favicon: string | null;
  domainPayload: {
    competitionId?: string;
    competitionGroupId?: string;
  };
}>;

const APPLICATION_INITIAL_STATE: ApplicationState = {
  activeAppspace: null,
  activeCompetition: null,
  domainPayload: {},
  breadcrumbs: [],
  logo: null,
  favicon: null,
};

export const applicationReducer = reducerWithInitialState<ApplicationState>(
  APPLICATION_INITIAL_STATE,
)
  .case(actions.setDomainPayload, (state, domainPayload) => ({
    ...state,
    domainPayload,
  }))
  .case(actions.setActiveAppspace, (state, activeAppspace) => ({
    ...state,
    activeAppspace,
  }))
  .case(actions.removeActiveAppspace, state => ({
    ...state,
    activeAppspace: null,
  }))
  .case(actions.setActiveCompetition, (state, activeCompetition) => ({
    ...state,
    activeCompetition,
  }))
  .case(actions.removeActiveCompetition, state => ({
    ...state,
    activeCompetition: null,
  }))
  .case(actions.setBreadcrumbs, (state, breadcrumbs) => ({
    ...state,
    breadcrumbs,
  }))
  .case(
    actions.loadIcons.async.done,
    (state, { result: { favicon, logo } }) => ({
      ...state,
      logo,
      favicon,
    }),
  );

export interface IDetailInitialState<
  R extends any | undefined = undefined,
  E extends any = any
> {
  [key: string]: {
    isFetching: boolean;
    error: E;
    data?: R;
  };
}

export const sectionTreeBySectionIdOrUniqIdReducer = reducerWithInitialState<
  any
>({})
  .case(actions.loadSectionTree.async.started, (state, params) => {
    const key = serializeParameters(params, ['sectionIdOrUniqId', 'treelevel']);
    return {
      ...state,
      [key]: {
        ...(state[key] || {}),
        isFetching: true,
      },
    };
  })
  .case(actions.loadSectionTree.async.done, (state, { params, result }) => {
    const key = serializeParameters(
      { ...params, sectionIdOrUniqId: result.data.sectionIdOrUniqId },
      ['sectionIdOrUniqId', 'treelevel'],
    );

    return {
      ...state,
      [key]: {
        ...(state[key] || {}),
        isFetching: false,
        error: null,
        data: {
          ...(state[key] ? state[key].data : {}),
          ...result.data,
        },
      },
    };
  })
  .case(actions.loadSectionTree.async.failed, (state, { params, error }) => {
    const key = serializeParameters(params, ['sectionIdOrUniqId', 'treelevel']);
    return {
      ...state,
      [key]: {
        ...state[key],
        isFetching: false,
        error,
      },
    };
  })
  .case(actions.resetSectionTree, () => {
    return {};
  });

export const loadPathReducer = reducerWithInitialState<any>({})
  .case(loadPath.async.started, (state, params) => {
    const key = serializeParameters(params, ['id']);
    return {
      ...state,
      [key]: {
        ...(state[key] || {}),
        isFetching: true,
      },
    };
  })
  .case(loadPath.async.done, (state, { params, result }) => {
    const key = serializeParameters(params, ['id']);
    return {
      ...state,
      [key]: {
        ...state[key],
        isFetching: false,
        error: null,
        data: result.data,
      },
    };
  })
  .case(loadPath.async.failed, (state, { params, error }) => {
    const key = serializeParameters(params, ['id']);
    return {
      ...state,
      [key]: {
        ...state[key],
        isFetching: false,
        error,
      },
    };
  });

export const loadSectionReducer = reducerWithInitialState<any>({})
  .case(loadSection.async.started, (state, params) => {
    const key = serializeParameters(params, ['idOrUniqId']);
    return {
      ...state,
      [key]: {
        ...(state[key] || {}),
        isFetching: true,
      },
    };
  })
  .case(loadSection.async.done, (state, { params, result }) => {
    const key = serializeParameters(params, ['sidOrUniqId']);
    return {
      ...state,
      [key]: {
        ...state[key],
        isFetching: false,
        error: null,
        data: result,
      },
    };
  })
  .case(loadSection.async.failed, (state, { params, error }) => {
    const key = serializeParameters(params, ['idOrUniqId']);
    return {
      ...state,
      [key]: {
        ...state[key],
        isFetching: false,
        error,
      },
    };
  });

export const loadArticleReducer = reducerWithInitialState<any>({})
  .case(loadArticle.async.started, (state, params) => {
    const key = serializeParameters(params, ['id']);
    return {
      ...state,
      [key]: {
        ...(state[key] || {}),
        isFetching: true,
      },
    };
  })
  .case(loadArticle.async.done, (state, { params, result }) => {
    const key = serializeParameters(params, ['id']);
    return {
      ...state,
      [key]: {
        ...state[key],
        isFetching: false,
        error: null,
        data: result,
      },
    };
  })
  .case(loadArticle.async.failed, (state, { params, error }) => {
    const key = serializeParameters(params, ['id']);
    return {
      ...state,
      [key]: {
        ...state[key],
        isFetching: false,
        error,
      },
    };
  });
