import { NormalizedEntities } from '../library/App';
import { EntitiesState, Writeable } from '../pages/App/reducer';

export default function<
  T extends keyof EntitiesState,
  E = EntitiesState[T]['']
>(type: T, arrayOfEntities: E[], id: string = '_id'): NormalizedEntities<T> {
  const startingAcc: any = {};
  return {
    entities: {
      [type]: arrayOfEntities.reduce(
        (acc: Writeable<EntitiesState[T]>, item: any) => {
          // acc[item[id]] = item;
          // return acc;
          return { ...acc, [item[id]]: item };
        },
        startingAcc,
      ),
    },
    results: arrayOfEntities.map((item: any) => item[id]),
  };
}
