import Tag from '@sportnet/ui/lib/SmarttagsControl/SmarttagTags/Tag';
import sportnetUiTheme from '@sportnet/ui/lib/Themes/baseTheme';
import { rem } from 'polished';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { Smarttag } from '../../../api/CmsApi';
import config from '../../../config';
import styled from '../../../theme/styled-components';
import { __ } from '../../../utilities';
import MaxWidthBox from '../../MaxWidthBox';
import ContentSectionHeader from '../ContentSectionHeader';

const Wrapper = styled('div')`
  margin: ${rem(35)} 0 0 0;
`;

interface OwnProps {
  tags: Smarttag[];
}

type Props = OwnProps;

const Tags: React.FC<Props> = ({ tags }) => {
  if (tags.length === 0) {
    return null;
  }

  function renderGroup(item: Smarttag) {
    return item.values.map(value => (
      <Tag
        key={`${item.key}-${value.key}`}
        disabled
        title={item.key}
        subtitle={value.title || value.key}
      />
    ));
  }

  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <Wrapper>
        <ContentSectionHeader>{__('Značky')}</ContentSectionHeader>
        <ThemeProvider theme={sportnetUiTheme}>
          <>{tags.map(renderGroup)}</>
        </ThemeProvider>
      </Wrapper>
    </MaxWidthBox>
  );
};

export default Tags;
