import { commit, CommitError, getListParameters } from 'redux-list';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { LIST_NAME } from '.';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import { NormalizedEntities } from '../../library/App';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';

const create = actionCreatorFactory(config.COMPETITIONS_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const getSeasons = createAsync<{}, NormalizedEntities<'SEASONS'>>(
  'GET_SEASONS',
  async (parameters, dispatch, getState, { Api }) => {
    const { appSpace } = appSettingsSelector(getState());
    const response = await Api.getSeasons(appSpace);
    return normalizeEntities('SEASONS', response.seasons);
  },
);

const loadCompetitions = createAsync<
  { offset?: number; seasonId?: string; q?: string; appSpace: string },
  NormalizedEntities<'COMPETITIONS'>
>('GET_COMPETITIONS', async (parameters, dispatch, getState, { Api }) => {
  const params: { [key: string]: any } = {
    limit: 1000,
  };
  if (typeof parameters.offset !== 'undefined') {
    params.offset = parameters.offset;
  }
  if (parameters.seasonId) {
    params.seasonId = parameters.seasonId;
  }
  if (parameters.q) {
    params.q = parameters.q;
  }
  const response = await Api.getCompetitions(parameters.appSpace, params);
  return normalizeEntities('COMPETITIONS', response.competitions);
});

export const getCompetitionsList = (): CustomThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    const { appSpace } = appSettingsSelector(getState());
    const parameters = getListParameters(LIST_NAME)(getState());
    return await dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          try {
            const { results } = await dispatch(
              loadCompetitions.action({ ...parameters, appSpace }),
            );
            return {
              total: results.length,
              results,
            };
          } catch (e) {
            throw new CommitError();
          }
        },
      }),
    );
  };
};

export const getCompetitionById = createAsync<
  {
    competitionId: string;
  },
  NormalizedEntities<'COMPETITIONS'>
>('GET_COMPETITION', async (parameters, dispatch, getState, { Api }) => {
  const appSpace = activeAppspaceSelector(getState());
  const competition = await Api.getCompetitionById(
    appSpace,
    parameters.competitionId,
  );
  return normalizeEntities('COMPETITIONS', [competition]);
});

export const getCompetitionGroupById = createAsync<
  {
    competitionGroupId: string;
  },
  NormalizedEntities<'COMPETITION_GROUPS'>
>('GET_COMPETITION_GROUP', async (parameters, dispatch, getState, { Api }) => {
  const appSpace = activeAppspaceSelector(getState());
  const competitionGroup = await Api.getCompetitionGroupById(
    appSpace,
    parameters.competitionGroupId,
  );
  return normalizeEntities('COMPETITION_GROUPS', [competitionGroup]);
});
