import { createSelector } from 'reselect';
import { Codelist, CodelistLabelByValue } from '../../library/App';
import { RootState } from '../../rootReducer';
import serializeParameters from '../../utilities/serializeParameters';
import { IDetailInitialState } from './reducer';

// Entities
export const entitiesSelector = (state: RootState) => {
  return state.entities;
};

export const entitiesCodelistsSelector = createSelector(
  entitiesSelector,
  entities => entities.CODELISTS || {},
);

export const entityPPOSelector = (id: string) =>
  createSelector(
    entitiesSelector,
    entities => entities.PPO[id] || {},
  );

export const entityPOSelector = (id: string) =>
  createSelector(
    entitiesSelector,
    entities => entities.PO[id] || {},
  );

export const entityCodelistSelector = (id: string) =>
  createSelector(
    entitiesSelector,
    entities => entities.CODELISTS[id] || {},
  );

export const codelistItemsSelector = (id: string) =>
  createSelector(
    entityCodelistSelector(id),
    (codelist: Readonly<Codelist>) => codelist.items || [],
  );

const applicationDomainSelector = (state: RootState) => {
  return state.application;
};

export const activeAppspaceSelector = createSelector(
  applicationDomainSelector,
  substate => substate.activeAppspace || '',
);

export const activeCompetitionSelector = createSelector(
  applicationDomainSelector,
  substate => substate.activeCompetition || '',
);

export const domainPayloadSelector = createSelector(
  applicationDomainSelector,
  substate => substate.domainPayload || {},
);

export const currentPPOSelector = createSelector(
  entitiesSelector,
  activeAppspaceSelector,
  (entities, id) => {
    if (id) {
      return entities.PPO[id] || {};
    }
    return {};
  },
);

export const currentPOSelector = createSelector(
  entitiesSelector,
  currentPPOSelector,
  (entities, PPO) => {
    const POId = PPO.organization_id;
    if (POId) {
      return entities.PO[POId] || {};
    }
    return {};
  },
);

export const breadcrumbsSelector = createSelector(
  applicationDomainSelector,
  substate => substate.breadcrumbs,
);

export const logoSrcSelector = createSelector(
  applicationDomainSelector,
  substate => substate.logo,
);

export const faviconSrcSelector = createSelector(
  applicationDomainSelector,
  substate => substate.favicon,
);

export const codelistLabelByValueSelector = (id: string) =>
  createSelector(
    codelistItemsSelector(id),
    codelistItems => {
      return codelistItems.reduce((acc: CodelistLabelByValue, codelistItem) => {
        acc[codelistItem.value] = codelistItem.label || codelistItem.value;
        return acc;
      }, {});
    },
  );

export const detailTypeSelector = <T extends keyof RootState['detail']>(
  type: T,
) =>
  createSelector(
    (state: RootState) => state.detail,
    () => type,
    (domain, detailType) => domain[detailType],
  );

const detailDomainSelector = <T extends keyof RootState['detail']>(
  type: T,
  params: { [key: string]: any },
) =>
  createSelector(
    detailTypeSelector(type),
    () => serializeParameters(params, Object.keys(params)),
    (detailType, key) => {
      return (detailType[key] || {}) as Partial<
        IDetailInitialState<RootState['detail'][T]['']['data']>['']
      >;
    },
  );

export const detailDataSelector = <T extends keyof RootState['detail']>(
  type: T,
  params: { [key: string]: any },
) =>
  createSelector(
    detailDomainSelector(type, params),
    domain => {
      return domain.data;
    },
  );
