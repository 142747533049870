/* tslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Article_Author = {
  sportnetId?: string;

  name: string;
};
export type Allowed_Users_List = {
  users?: Array<{
    user_id?: string;

    subsection_access?: boolean;
  }>;
};
export type Section_Path = {
  contentspace?: string;

  perex?: string | null;

  name?: string;

  parent?: Array<number>;

  show?: boolean;

  is_private?: boolean;

  url?: string | null;

  content?: Content;

  uniqid_key?: string;

  redirecturl?: string | null;

  _id?: number;

  order?: number;

  uniqid?: string;
};
export type Section_Path_List = {
  path?: Array<Section_Path>;
};
export type URL_Map = {
  url?: string | null;

  urltype?: string;

  contentspace?: string;

  object_id?: number;

  expanded_object?: {};

  location?: string;

  permanent?: boolean;
};
export type Smarttag = {
  key: string | number;

  values: Array<{
    key: string | number;

    title?: string | null;
  }>;
};
export type Article_Draft = {
  created?: string;

  createdBy?: {
    id?: string | null;

    displayName?: string | null;
  };
};
export type Article = {
  _id?: number;

  owner?: {
    sportnetId?: string | null;

    name?: string | null;
  };

  authors?: Array<Article_Author>;

  contentspace?: string;

  date?: string | null;

  doctype?: string;

  doctypeExpanded?: Doctype;

  is_private?: boolean;

  locked?: boolean;

  name?: string;

  created?: string;

  modified?: string | null;

  perex?: string | null;

  picture?: Picture;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | number | null;

  stats_view_total?: number;

  sfz?: Array<{}>;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  tags?: Array<{}>;

  url?: string | null;

  valid_from?: string | null;

  valid_to?: string | null;

  widgets?: Content;

  draft?: Article_Draft;

  layout?: string;

  externalIds?: Array<{
    service?: string;

    externalId?: string;

    lastUpdate?: string;

    error?: string;
  }>;

  discussion?: {
    type?: string;

    discussionId?: number;

    active?: boolean;

    url?: string;
  };
};
export type Widget = {
  type: string;
};
export type Authors_List = {
  authors: Array<{
    id: string;

    name?: string;

    nrofarticles: number;

    allnames?: Array<string>;
  }>;
};
export type Articles_List = {
  articles: Array<Article>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type Article_Post = {
  date?: string;

  doctype?: string;

  is_private?: boolean;

  locked?: boolean;

  name: string;

  perex?: string | null;

  picture?: Picture;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | null;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  url?: string | null;

  urlSuggestion?: string;

  valid_from?: string | null;

  valid_to?: string | null;

  widgets?: Content;

  layout?: string;

  authors?: Array<Article_Author>;
};
export type Article_Put = {
  doctype?: string;

  locked?: boolean;

  name?: string;

  perex?: string | null;

  picture?: Picture;

  is_private?: boolean;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | null;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  url?: string | null;

  valid_from?: string | null;

  valid_to?: string | null;

  widgets?: Content;

  layout?: string;

  authors?: Array<Article_Author>;
};
export type Section = {
  _id?: number;

  contentspace?: string;

  is_private?: boolean;

  name?: string;

  order?: number;

  parent?: Array<number>;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  sectionArticles?: SectionArticles;

  redirecturl?: string | null;

  content?: Content;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  publication_groups?: Array<string>;
};
export type SectionPublic = {
  _id?: number;

  contentspace?: string;

  is_private?: boolean;

  name?: string;

  order?: number;

  parent?: Array<number>;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;
};
export type SectionPublicDetail = {
  _id?: number;

  contentspace?: string;

  is_private?: boolean;

  name?: string;

  order?: number;

  parent?: Array<number>;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  sectionArticles?: SectionArticles;

  content?: Content;

  publication_groups?: Array<string>;
};
export type Section_List = {
  sections?: Array<Section>;
};
export type SectionPublic_List = {
  sections?: Array<SectionPublic>;
};
export type SectionPublic_Tree = {
  tree?: Array<SectionPublic>;
};
export type SectionAdmin_Tree = {
  tree?: Array<Section>;
};
export type Section_Post = {
  is_private?: boolean;

  name: string;

  parent?: number | null;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  sectionArticles?: SectionArticles;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url: string;

  publication_groups?: Array<string>;

  content: Content;
};
export type Section_Put = {
  _id?: number;

  is_private?: boolean;

  name?: string;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  sectionArticles?: SectionArticles;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  publication_groups?: Array<string>;

  newParent?: number | null;

  content?: Content;
};
export type Sections_Order = {
  order?: Array<Array<number>>;
};
export type Static_Content_Public = Static_Content;
export type Static_Content = {
  _id?: string;

  cid?: string;

  name?: string;

  content?: Content;
};
export type Content = Array<{}>;
export type Static_Contents_List = {
  static_content?: Array<{
    cid?: string;

    name?: string;
  }>;
};
export type Static_Content_PostPut = {
  name: string;

  cid: string;

  content: Content;
};
export type Doctype = {
  id: string;

  title: string;

  defaultPicture?: Picture;

  overrideAuthorBySmarttagKey?: string;

  showCreatedBy?: boolean;

  top?: {
    showAuthor?: boolean;

    showId?: boolean;

    showDate?: boolean;

    showSmarttags?: boolean;
  };

  bottom?: {
    showAuthor?: boolean;

    showSmarttags?: boolean;
  };

  social?: {};

  relatedArticles?: {
    show?: boolean;

    limit?: number;
  };
};
export type Doctype_Put = {
  id?: string;

  title?: string;

  defaultPicture?: Picture;

  overrideAuthorBySmarttagKey?: string;

  showCreatedBy?: boolean;

  top?: {
    showAuthor?: boolean;

    showId?: boolean;

    showDate?: boolean;

    showSmarttags?: boolean;

    showSocial?: boolean;
  };

  bottom?: {
    showAuthor?: boolean;

    showSmarttags?: boolean;

    showSocial?: boolean;
  };

  social?: {};

  relatedArticles?: {
    show?: boolean;

    limit?: number;
  };
};
export type Doctypes = Array<Doctype>;
export type Preview_Post = {
  type: 'article';

  valid_to: string;

  data: {};
};
export type Preview = {
  type: 'article';

  valid_to: string;

  data: {};

  _id: string;
};
export type Confirmation_Message = {
  message: string;
};
export type Listing_Patch = {
  name?: string;

  articles?: Array<number>;

  articleFilter?: Array<{
    type: string;
  }>;
};
export type Listing_Post = {
  listingId: string;

  name: string;

  articles?: Array<number>;

  articleFilter?: Array<{
    type: string;
  }>;
};
export type Listing = {
  _id: string;

  appSpace?: string;

  listingId: string;

  contentspace?: string;

  name?: string;

  articles?: Array<number>;

  articleFilter?: Array<{
    type: string;
  }>;

  created?: string;

  modified?: string | null;
};
export type Listing_Fixed_Articles = {
  articles: Array<Article>;
};
export type Listings_List = {
  listings: Array<Listing>;

  nextOffset?: number | null;

  total: number;

  limit: number;

  offset: number;
};
export type Picture = object | null;
export type SectionArticles = {
  header?: string;

  display?: string;

  articleFilter?: Array<{
    type: string;
  }>;
};
export type Expand_Content = {
  content: Content;
};
export type Error = {
  code: number;

  name: string;

  description: string | null;

  payload?: object | null;

  userinfo?: string;
};
export type Error_Private_Article = {
  code: number;

  name: string;

  description: string | null;

  payload?: {
    data?: Article;
  };

  userinfo?: string;
};
export type Error_Private_Section = {
  code: number;

  name: string;

  description: string | null;

  payload?: {
    data?: Section;
  };

  userinfo?: string;
};
export type Error_Private_URL_Map = {
  code: number;

  name: string;

  description: string | null;

  payload?: {
    data?: URL_Map;
  };

  userinfo?: string;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class CmsApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class CmsApi {
  protected baseUrl: string = 'https://cms.sportnet.online/api/v3';
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   *
   * @method
   * @name CmsApi#expandWidgets
   */
  expandWidgets(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Expand_Content,
  ): Promise<Expand_Content> {
    let path = '/public/{appId}:{appSpace}:{contentDivider}/expand-widgets';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch URL map for Given URL
   * @method
   * @name CmsApi#getContentByUrl
   */
  getContentByUrl(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      urlPath: string;
      expandObject?: number;
      expandWidgets?: boolean;
    },
  ): Promise<URL_Map> {
    let path = '/public/{appId}:{appSpace}:{contentDivider}/urlmap';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    if (typeof parameters['urlPath'] !== 'undefined') {
      queryParameters['url_path'] = parameters['urlPath'];
    }

    queryParameters['url_path'] = this.transformParameter(
      queryParameters['url_path'],
    );

    if (typeof parameters['urlPath'] === 'undefined') {
      throw new Error('Missing required parameter: urlPath');
    }

    if (typeof parameters['expandObject'] !== 'undefined') {
      queryParameters['expand_object'] = parameters['expandObject'];
    }

    queryParameters['expand_object'] = this.transformParameter(
      queryParameters['expand_object'],
    );

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch URL map for Given URL
   * @method
   * @name CmsApi#getContentByUrl_1
   */
  getContentByUrl_1(
    appId: string,
    appSpace: string,
    contentDivider: string,
    urlPath: string,
    parameters: {
      expandObject?: number;
      expandWidgets?: boolean;
    } = {},
  ): Promise<URL_Map> {
    let path = '/public/{appId}:{appSpace}:{contentDivider}/urlmap/{url_path}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{url_path}', urlPath.toString());

    if (typeof parameters['expandObject'] !== 'undefined') {
      queryParameters['expand_object'] = parameters['expandObject'];
    }

    queryParameters['expand_object'] = this.transformParameter(
      queryParameters['expand_object'],
    );

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Public Articles
   * @method
   * @name CmsApi#getPublicArticles
   */
  getPublicArticles(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      offset?: number;
      limit?: number;
      widgetsType?: Array<string>;
      excludetags?: Array<string>;
      smarttags?: Array<string>;
      doctypes?: Array<string>;
      q?: string;
      sorter?: string;
      excludeIds?: string;
      sectionId?: number;
    } = {},
  ): Promise<Articles_List> {
    let path = '/public/{appId}:{appSpace}:{contentDivider}/articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['widgetsType'] !== 'undefined') {
      queryParameters['widgets_type'] = parameters['widgetsType'];
    }

    queryParameters['widgets_type'] = this.transformParameter(
      queryParameters['widgets_type'],
    );

    if (typeof parameters['excludetags'] !== 'undefined') {
      queryParameters['excludetags'] = parameters['excludetags'];
    }

    queryParameters['excludetags'] = this.transformParameter(
      queryParameters['excludetags'],
    );

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['doctypes'] !== 'undefined') {
      queryParameters['doctypes'] = parameters['doctypes'];
    }

    queryParameters['doctypes'] = this.transformParameter(
      queryParameters['doctypes'],
      'joinUsingPipes',
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['excludeIds'] !== 'undefined') {
      queryParameters['exclude_ids'] = parameters['excludeIds'];
    }

    queryParameters['exclude_ids'] = this.transformParameter(
      queryParameters['exclude_ids'],
    );

    if (typeof parameters['sectionId'] !== 'undefined') {
      queryParameters['section_id'] = parameters['sectionId'];
    }

    queryParameters['section_id'] = this.transformParameter(
      queryParameters['section_id'],
    );

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch User Articles
   * @method
   * @name CmsApi#getArticles4User
   */
  getArticles4User(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      offset?: number;
      limit?: number;
      widgets?: Array<string>;
      widgetsType?: Array<string>;
      q?: string;
      sorter?: string;
      sectionId?: number;
    } = {},
  ): Promise<Articles_List> {
    let path = '/public/{appId}:{appSpace}:{contentDivider}/articles4user';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['widgets'] !== 'undefined') {
      queryParameters['widgets'] = parameters['widgets'];
    }

    queryParameters['widgets'] = this.transformParameter(
      queryParameters['widgets'],
    );

    if (typeof parameters['widgetsType'] !== 'undefined') {
      queryParameters['widgets_type'] = parameters['widgetsType'];
    }

    queryParameters['widgets_type'] = this.transformParameter(
      queryParameters['widgets_type'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['sectionId'] !== 'undefined') {
      queryParameters['section_id'] = parameters['sectionId'];
    }

    queryParameters['section_id'] = this.transformParameter(
      queryParameters['section_id'],
    );

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Public Article By Id
   * @method
   * @name CmsApi#getPublicArticlesById
   */
  getPublicArticlesById(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {
      expandWidgets?: boolean;
      outputVersion?: number;
      stripTags?: boolean;
    } = {},
  ): Promise<Article> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/articles/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    queryParameters['outputVersion'] = 1;

    if (typeof parameters['outputVersion'] !== 'undefined') {
      queryParameters['outputVersion'] = parameters['outputVersion'];
    }

    queryParameters['outputVersion'] = this.transformParameter(
      queryParameters['outputVersion'],
    );

    queryParameters['stripTags'] = false;

    if (typeof parameters['stripTags'] !== 'undefined') {
      queryParameters['stripTags'] = parameters['stripTags'];
    }

    queryParameters['stripTags'] = this.transformParameter(
      queryParameters['stripTags'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Edit Stats of Public Article By Id
   * @method
   * @name CmsApi#putArticleStats
   */
  putArticleStats(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {} = {},
  ): Promise<Confirmation_Message> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/articlestats/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Edit Stats of Public Article By Id
   * @method
   * @name CmsApi#getMostPopularArticles
   */
  getMostPopularArticles(
    appId: string,
    appSpace: string,
    contentDivider: string,
    hours: number,
    parameters: {
      offset?: number;
      limit?: number;
    } = {},
  ): Promise<Articles_List> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/articles-most-popular/{hours}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{hours}', hours.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Public Related Articles
   * @method
   * @name CmsApi#getPublicRelatedArticles
   */
  getPublicRelatedArticles(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {
      offset?: number;
      limit?: number;
    } = {},
  ): Promise<Articles_List> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/articles/{article_id}/related';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Public Section By Id or UniqId
   * @method
   * @name CmsApi#getPublicSectionByIdOrUniqId
   */
  getPublicSectionByIdOrUniqId(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionIdOrUniqId: string,
    parameters: {
      expandWidgets?: boolean;
    } = {},
  ): Promise<SectionPublicDetail> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/sections/{section_idOrUniqId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_idOrUniqId}', sectionIdOrUniqId.toString());

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Public Sections Tree
   * @method
   * @name CmsApi#getPublicSectionsTree
   */
  getPublicSectionsTree(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
  ): Promise<SectionPublic_Tree> {
    let path = '/public/{appId}:{appSpace}:{contentDivider}/sections-tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Public Section Tree By Id or Uniqid
   * @method
   * @name CmsApi#getPublicSectionTreeByIdOrUniqId
   */
  getPublicSectionTreeByIdOrUniqId(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionIdOrUniqId: string,
    parameters: {} = {},
  ): Promise<SectionPublic_Tree> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/sections/{section_idOrUniqId}/tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_idOrUniqId}', sectionIdOrUniqId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Public Subsections By Id
   * @method
   * @name CmsApi#getPublicSubsectionsByIdOrUniqId
   */
  getPublicSubsectionsByIdOrUniqId(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionIdOrUniqId: string,
    parameters: {
      treelevel?: number;
    } = {},
  ): Promise<SectionPublic_List> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/sections/{section_idOrUniqId}/sections';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_idOrUniqId}', sectionIdOrUniqId.toString());

    queryParameters['treelevel'] = 0;

    if (typeof parameters['treelevel'] !== 'undefined') {
      queryParameters['treelevel'] = parameters['treelevel'];
    }

    queryParameters['treelevel'] = this.transformParameter(
      queryParameters['treelevel'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Public Articles By Section Id
   * @method
   * @name CmsApi#getPublicArticlesBySectionId
   */
  getPublicArticlesBySectionId(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {
      offset?: number;
      limit?: number;
      includeNested?: boolean;
      excludeId?: number;
      excludeIds?: string;
      widgets?: Array<string>;
      widgetsType?: Array<string>;
    } = {},
  ): Promise<Articles_List> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/sections/{section_id}/articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['include_nested'] = false;

    if (typeof parameters['includeNested'] !== 'undefined') {
      queryParameters['include_nested'] = parameters['includeNested'];
    }

    queryParameters['include_nested'] = this.transformParameter(
      queryParameters['include_nested'],
    );

    if (typeof parameters['excludeId'] !== 'undefined') {
      queryParameters['exclude_id'] = parameters['excludeId'];
    }

    queryParameters['exclude_id'] = this.transformParameter(
      queryParameters['exclude_id'],
    );

    if (typeof parameters['excludeIds'] !== 'undefined') {
      queryParameters['exclude_ids'] = parameters['excludeIds'];
    }

    queryParameters['exclude_ids'] = this.transformParameter(
      queryParameters['exclude_ids'],
    );

    if (typeof parameters['widgets'] !== 'undefined') {
      queryParameters['widgets'] = parameters['widgets'];
    }

    queryParameters['widgets'] = this.transformParameter(
      queryParameters['widgets'],
    );

    if (typeof parameters['widgetsType'] !== 'undefined') {
      queryParameters['widgets_type'] = parameters['widgetsType'];
    }

    queryParameters['widgets_type'] = this.transformParameter(
      queryParameters['widgets_type'],
    );

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Section Path By Section Id
   * @method
   * @name CmsApi#getPathForSectionId
   */
  getPathForSectionId(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
  ): Promise<Section_Path_List> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/sections/{section_id}/path';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Static Content By Id
   * @method
   * @name CmsApi#getPublicStaticContentById
   */
  getPublicStaticContentById(
    appId: string,
    appSpace: string,
    contentDivider: string,
    cid: string,
    parameters: {
      expandWidgets?: boolean;
    } = {},
  ): Promise<Static_Content_Public> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/static-content/{cid}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{cid}', cid.toString());

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Static Content By Id
   * @method
   * @name CmsApi#getPublicStaticContentById_1
   */
  getPublicStaticContentById_1(
    appId: string,
    appSpace: string,
    contentDivider: string,
    cid: string,
    parameters: {
      expandWidgets?: boolean;
    } = {},
  ): Promise<Static_Content_Public> {
    let path =
      '/public/{appId}:{appSpace}:{contentDivider}/static_content/{cid}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{cid}', cid.toString());

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new CmsApi();
export { CmsApi };
