import CSMWidgets from 'content/lib/view';
import React from 'react';
import { Link } from 'react-router';
import getArticleUrl from '../../../utilities/getArticleUrl';
import getSectionUrl from '../../../utilities/getSectionUrl';

type Props = {
  items: Array<{
    type?: string;
  }>;
};

const Widgets: React.FC<Props> = ({ items }) => {
  return (
    <CSMWidgets
      items={items}
      renderLink={(link: any) => {
        if (link.type === 'article_detail') {
          return (
            <Link to={getArticleUrl('', link.payload)} {...link.linkProps}>
              {link.linkProps.children}
            </Link>
          );
        } else if (link.type === 'article_filter') {
          if (typeof link.linkProps.children !== 'string') {
            return null;
          }
          return (
            <span className={link.linkProps.className}>
              {link.linkProps.children}
            </span>
          );
        } else if (link.section) {
          return (
            <Link to={getSectionUrl('', link.section)} {...link.linkProps}>
              {link.linkProps.children}
            </Link>
          );
        }
        return null;
      }}
    />
  );
};

export default Widgets;
