import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { Article, Section } from '../../api/CmsApi';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { updateEntities } from '../../containers/App/actions';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import ForbiddenError from '../../ForbiddenError';
import { ArticleId, SectionId } from '../../library/App';
import NotFoundError from '../../NotFoundError';
import { RootState } from '../../rootReducer';
import UnauthorizedError from '../../UnauthorizedError';
import normalizeEntities from '../../utilities/normalizeEntities';
import reduceSectionProps from '../../utilities/reduceSectionProps';

const create = actionCreatorFactory('URL_MAP');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadByUrl = createAsync<
  {url: string, contentDivider: string},
  {
    data: {
      urltype: string;
      object_id: ArticleId | SectionId;
    };
  }
>('LOAD_BY_URL', async (parameters, dispatch, getState, { CmsApi }) => {
  const { appSpace } = appSettingsSelector(getState());
  try {
    const pathname = parameters.url.replace(/\//, '');
    const response = await CmsApi.getContentByUrl(
      config.APP_ID,
      appSpace,
      parameters.contentDivider,
      {
        urlPath: pathname,
        expandObject: 1,
        expandWidgets: true,
      },
    );

    const entities: {
      articles: {
        [key: string]: any;
      };
      sections: {
        [key: string]: any;
      };
    } = {
      articles: {},
      sections: {},
    };

    if (response.urltype === 'section') {
      const expandedObject = response.expanded_object as Section;
      entities.sections[response.object_id!] = reduceSectionProps(
        expandedObject,
      );
    } else if (response.urltype === 'article') {
      const expandedObject = response.expanded_object as Article;
      entities.articles[response.object_id!] = expandedObject;
    }

    dispatch(updateEntities(entities));

    return {
      data: {
        urltype: response.urltype!,
        object_id: response.object_id!,
      },
    };
  } catch (e) {
    if (e && e.details && e.details.code === 401) {
      throw new UnauthorizedError(e);
    }
    if (e && e.details && e.details.code === 403) {
      throw new ForbiddenError(e);
    }
    throw new NotFoundError(e);
  }
});

export const loadPath = createAsync<
  {
    id: SectionId;
    contentDivider: string;
  },
  { data: { path: string[] | number[] } }
>('LOAD_PATH', async ({ id, contentDivider }, dispatch, getState, { CmsApi }) => {
  const { appSpace } = appSettingsSelector(getState());

  const response = await CmsApi.getPathForSectionId(
    'sutaze',
    appSpace,
    contentDivider,
    id,
  );
  const { entities, results } = normalizeEntities(
    'sections',
    response.path!.map(reduceSectionProps), // @TODO csm/api path je optional
  );

  dispatch(updateEntities(entities));

  return {
    data: {
      path: results,
    },
  };
});
