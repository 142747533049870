import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import Loader from '../../components/Loader';
import config from '../../config';
import { ReduxConnectProps } from '../../configureStore';
import { setActiveCompetition } from '../../pages/App/actions';
import { activeCompetitionSelector } from '../../pages/App/selectors';
import { getCompetitionById } from '../../pages/Competitions/actions';
import { RootState } from '../../rootReducer';
import Article from '../Article';
// import Article from '../Article';
import Forbidden from '../Forbidden';
import NotFound from '../NotFound';
import Section from '../Section';
import Unauthorized from '../Unauthorized';
import { loadByUrl } from './actions';
import { urlDetailIsFetchingSelector, urlDetailSelector, urlErrorSelector } from './selectors';

type RouterProps = RouteComponentProps<
  {
    id: string;
    appspace: string;
  },
  void
>;

const mapStateToProps = (
  state: RootState,
  props: RouterProps &
    ReduxConnectProps &
    RouteComponentProps<{ competitionId: string }, {}>,
) => {
  let pathname = props.location.pathname;
  if (props.params.competitionId) {
    pathname = props.location.pathname.replace(
      `/competition/${props.params.competitionId}`,
      '',
    );
  }
  return {
    isFetching: urlDetailIsFetchingSelector(pathname)(state),
    detail: urlDetailSelector(pathname)(state),
    error: urlErrorSelector(pathname)(state),
    activeCompetition: activeCompetitionSelector(state),
  };
};

type Props = RouterProps &
  ReduxConnectProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ competitionId: string }, {}>;

class UrlMap extends React.PureComponent<Props> {
  static async init(props: Props) {
    const competitionId = props.params.competitionId || props.activeCompetition;
    let url = props.location.pathname;
    let contentDivider = config.DEFAULT_CONTENT_DIVIDER;
    if (competitionId) {
      props.dispatch(setActiveCompetition(competitionId));
      props.dispatch(getCompetitionById.action({ competitionId }));
      url = props.location.pathname.replace(
        `/competition/${competitionId}`,
        '',
      );
      contentDivider = `competitionId|${competitionId}`;
    }
    return props.dispatch(loadByUrl.action({ url, contentDivider }));
  }

  static async getInitialProps(props: Props) {
    const response = await UrlMap.init(props);
    if (response.data.urltype === 'section') {
      await Section.getInitialProps({ ...props, id: response.data.object_id });
    } else if (response.data.urltype === 'article') {
      await Article.getInitialProps({ ...props, id: response.data.object_id });
    }
  }

  async componentDidMount() {
    try {
      await UrlMap.init(this.props);
    } catch (e) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      try {
        await UrlMap.init(this.props);
      } catch (e) {
        /* */
      }
    }
  }

  render() {
    const { isFetching, detail, error, activeCompetition } = this.props;

    if (isFetching && detail === null) {
      return <Loader />;
    }

    if (detail) {
      if (detail.urltype === 'section') {
        return (
          <Section
            id={detail.object_id}
            contentDivider={
              activeCompetition
                ? `competitionId|${activeCompetition}`
                : config.DEFAULT_CONTENT_DIVIDER
            }
          />
        );
      }

      if (detail.urltype === 'article') {
        return (
          <Article
            id={detail.object_id}
            contentDivider={
              activeCompetition
                ? `competitionId|${activeCompetition}`
                : config.DEFAULT_CONTENT_DIVIDER
            }
          />
        );
      }
    }

    if (error && error.identifier === 'FORBIDDEN') {
      return <Forbidden />;
    }
    if (error && error.identifier === 'UNAUTHORIZED') {
      return <Unauthorized />;
    }

    return <NotFound />;
  }
}

export default compose(connect(mapStateToProps))(UrlMap);
