import { mb } from '@sportnet/ui/lib/Themes/utilities';
import {
  addSeconds,
  differenceInSeconds,
  format,
  isEqual,
  startOfDay,
  subSeconds,
} from 'date-fns';
import * as skLocale from 'date-fns/locale/sk';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import * as socketConnect from 'socket.io-client';
import { getProp } from 'sportnet-utilities';
import styled, { css } from 'styled-components';
import Api from '../../Api';
import { ISportSectorPhase, ISportSectorPhases } from '../../library/App';
import {
  ILiveState,
  IMatch,
  IMatchSettings,
  IProtocol,
} from '../../library/Match';
import { ThemeInterface } from '../../theme/theme';
import { __ } from '../../utilities';

interface OwnProps {
  match: IMatch;
  matchSize?: number;
  lastIndex: boolean;
  theme: ThemeInterface;
  sportSectorsPhases: { [key: string]: ISportSectorPhases };
  fullWidth: boolean;
  compact?: boolean;
  verbose?: boolean;
  settings: IMatchSettings;
  onLiveScoreChange?: ({
    liveState,
    timer,
    protocol,
  }: {
    liveState: ILiveState;
    timer: {
      [key: string]: {
        start: {
          date: string;
          seconds?: number;
        };
        end?: {
          date: string;
          seconds?: number;
        };
      };
    };
    protocol: IProtocol;
  }) => void;
}

interface MatchWrapperProps {
  lastIndex: boolean;
  matchSize?: number;
  fullWidth: boolean;
  compact: boolean;
  verbose: boolean;
}

export const MatchWrapper = styled.div<MatchWrapperProps>`
  display: flex;
  align-items: center;
  ${({ matchSize }) => {
    if (matchSize) {
      return css`
        width: ${rem(matchSize)};
      `;
    }
    return css`
      width: 100%;
    `;
  }}
  height: 100%;
  ${({ lastIndex, fullWidth, compact }) => {
    if (compact) {
      return css`
        width: 100%;
      `;
    }
    if (!lastIndex && !fullWidth) {
      return css`
        border-right: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
      `;
    }
    return css``;
  }}
  ${({ verbose, theme }) => {
    if (verbose) {
      return `${mb('m')} {
        position: relative;
        padding: ${rem(20)};
        margin: auto;
        ${TeamLogo} {
          max-height: ${rem(150)};
          max-width: 100%;
        }
        ${TeamName} {
          font-size: ${rem(17)};
          padding-top: ${rem(10)};
        }
        ${LiveScore} {
          font-size: ${rem(38)};
        }
        ${MatchStatusIndicator} {
          font-size: ${rem(11)};
        }
        ${GoalsByPhases} {
          font-size: ${rem(12)};
        }
        ${MatchDate} {
          display: block;
        }
        ${MatchTime} {
          display: block;
        }
      }`;
    }
    return css``;
  }}
`;

interface TeamProps {
  align: string;
  compact: boolean;
}

export const Team = styled.div<TeamProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  width: 30%;
  height: 100%;
  background: white;
  ${({ align, compact }) => {
    if (compact && align === 'left') {
      return css`
        flex-direction: row-reverse;
        justify-content: right;
        width: 40%;
        ${TeamName} {
          text-align: right !important;
        }
      `;
    } else if (compact && align === 'right') {
      return css`
        flex-direction: row;
        width: 40%;
        justify-content: left;
        ${TeamName} {
          text-align: left !important;
        }
      `;
    }
    return css``;
  }}
`;
export const TeamName = styled.div`
  font-size: ${rem(10)};
  font-weight: bold;
  color: #333333;
  text-transform: uppercase;
  padding: 0 ${rem(10)};
  text-align: center;
`;
export const TeamLogo = styled.img`
  max-height: ${rem(30)};
  padding: 0 ${rem(10)};
  margin: ${rem(10)} 0;
`;
export const LiveScore = styled.div<{ compact: boolean }>`
  font-size: ${rem(21)};
  padding: ${rem(5)} ${rem(10)};
  width: 40%;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ compact }) => {
    if (compact) {
      return css`
        width: 20%;
      `;
    }
    return css``;
  }}
`;
export const MatchStatusIndicator = styled.div<{ color: string }>`
  font-size: ${rem(9)};
  color: ${({ color }) => color};
  text-transform: uppercase;
  padding-top: ${rem(5)};
`;
export const LiveTime = styled.div`
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.color.danger};
  font-weight: normal;
`;
export const GoalsByPhases = styled.div`
  font-size: ${rem(11)};
  font-weight: normal;
`;
const MatchDate = styled.div`
  font-weight: 300;
  font-size: ${rem(17)};
  display: none;
`;
const MatchTime = styled.div`
  font-size: ${rem(15)};
  font-weight: 400;
  display: none;
`;
const MatchSportGround = styled.div`
  font-size: ${rem(14)};
  font-weight: 300;
`;

export type Props = OwnProps;

class Match<T extends Props> extends React.PureComponent<
  T,
  {
    live: {
      liveState: ILiveState;
      timer: {
        [key: string]: {
          start: {
            date: string;
            seconds?: number;
          };
          end?: {
            date: string;
            seconds?: number;
          };
        };
      };
      protocol: IProtocol;
    };
  }
> {
  state = {
    live: {
      timer: {},
      liveState: {
        ongoing: false,
        seconds: 0,
        phase: '',
        toggleDate: '',
      },
      protocol: {
        events: [],
      },
    },
  };

  timerEvent: any = null;

  componentDidMount() {
    const { match } = this.props;
    if (
      !match.closed &&
      isEqual(startOfDay(new Date()), startOfDay(new Date(match.startDate)))
    ) {
      this.registerLiveScore(match._id);
    }
  }

  getTeam = (teamSide: string) =>
    this.props.match.teams.find(
      i => getProp(i, ['additionalProperties', 'homeaway'], '') === teamSide,
    );

  getTeamsIds = () => {
    const homeTeam = this.getTeam('home');
    const homeTeamId = homeTeam ? homeTeam._id : this.props.match.teams[0]._id;
    const awayTeam = this.getTeam('away');
    const awayTeamId = awayTeam ? awayTeam._id : this.props.match.teams[1]._id;

    return { homeTeamId, awayTeamId };
  };

  getShootoutGoals = () => {
    const { match } = this.props;
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    let homeTeamGoals = 0;
    let awayTeamGoals = 0;
    if (getProp(this.state, ['live', 'protocol', 'events'], []).length) {
      homeTeamGoals = (this.state.live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string },
        ) => {
          if (
            e.team === homeTeamId &&
            e.eventType === 'goal' &&
            e.phase === 'shootout'
          ) {
            return acc + 1;
          }
          return acc;
        },
        homeTeamGoals,
      );
      awayTeamGoals = (this.state.live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string },
        ) => {
          if (
            e.team === awayTeamId &&
            e.eventType === 'goal' &&
            e.phase === 'shootout'
          ) {
            return acc + 1;
          }
          return acc;
        },
        awayTeamGoals,
      );
    } else if (match.protocol) {
      homeTeamGoals = match.protocol.events.reduce((acc, e) => {
        if (
          e.team === homeTeamId &&
          e.eventType === 'goal' &&
          e.phase === 'shootout'
        ) {
          return acc + 1;
        }
        return acc;
      }, homeTeamGoals);
      awayTeamGoals = match.protocol.events.reduce((acc, e) => {
        if (
          e.team === awayTeamId &&
          e.eventType === 'goal' &&
          e.phase === 'shootout'
        ) {
          return acc + 1;
        }
        return acc;
      }, awayTeamGoals);
    }
    return { homeTeamGoals, awayTeamGoals };
  };

  getGoalsByTeams = () => {
    const { match } = this.props;
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    let homeTeamGoals = 0;
    let awayTeamGoals = 0;
    if (getProp(this.state, ['live', 'protocol', 'events'], []).length) {
      homeTeamGoals = (this.state.live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string },
        ) => {
          if (
            (e.team === homeTeamId &&
              e.eventType === 'goal' &&
              e.type !== 'dropped' &&
              (e.phase !== 'shootout' ||
                match.rules.sport_sector === 'water-polo')) ||
            (e.team === awayTeamId &&
              e.eventType === 'goal' &&
              e.type === 'dropped')
          ) {
            return acc + 1;
          }
          return acc;
        },
        homeTeamGoals,
      );
      awayTeamGoals = (this.state.live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string },
        ) => {
          if (
            (e.team === awayTeamId &&
              e.eventType === 'goal' &&
              e.type !== 'dropped' &&
              (e.phase !== 'shootout' ||
                match.rules.sport_sector === 'water-polo')) ||
            (e.team === homeTeamId &&
              e.eventType === 'goal' &&
              e.type === 'dropped')
          ) {
            return acc + 1;
          }
          return acc;
        },
        awayTeamGoals,
      );
    } else if (match.protocol) {
      homeTeamGoals = match.protocol.events.reduce((acc, e) => {
        if (
          (e.team === homeTeamId &&
            e.eventType === 'goal' &&
            (e.phase !== 'shootout' ||
              match.rules.sport_sector === 'water-polo') &&
            e.type !== 'dropped') ||
          (e.team === awayTeamId &&
            e.eventType === 'goal' &&
            e.type === 'dropped')
        ) {
          return acc + 1;
        }
        return acc;
      }, homeTeamGoals);
      awayTeamGoals = match.protocol.events.reduce((acc, e) => {
        if (
          (e.team === awayTeamId &&
            e.eventType === 'goal' &&
            (e.phase !== 'shootout' ||
              match.rules.sport_sector === 'water-polo') &&
            e.type !== 'dropped') ||
          (e.team === homeTeamId &&
            e.eventType === 'goal' &&
            e.type === 'dropped')
        ) {
          return acc + 1;
        }
        return acc;
      }, awayTeamGoals);
    }
    return { homeTeamGoals, awayTeamGoals };
  };

  getGoalsByMatchPart = (events: any[]) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    const phases = this.props.sportSectorsPhases[
      this.props.match.rules.sport_sector
    ];

    return events.reduce(
      (
        acc: any[],
        event: {
          eventType: string;
          type: string;
          team: string;
          phase: string;
        },
      ) => {
        if (
          event.eventType === 'goal' &&
          (event.phase !== 'shootout' ||
            this.props.match.rules.sport_sector === 'water-polo')
        ) {
          const otherTeamId =
            event.team === homeTeamId ? awayTeamId : homeTeamId;
          if (event.type === 'dropped') {
            return {
              ...acc,
              [event.phase]: {
                ...acc[event.phase],
                [otherTeamId]:
                  (acc[event.phase] ? acc[event.phase][otherTeamId] || 0 : 0) +
                  1,
              },
            };
          }
          return {
            ...acc,
            [event.phase]: {
              ...acc[event.phase],
              [event.team]:
                (acc[event.phase] ? acc[event.phase][event.team] || 0 : 0) + 1,
            },
          };
        }
        return acc;
      },
      phases
        ? phases.items.reduce((acc, phase) => {
            if (
              getProp(this.props, [
                'match',
                'timer',
                phase._id,
                'start',
                'date',
              ])
            ) {
              return {
                ...acc,
                [phase._id]: {
                  [homeTeamId]: 0,
                  [awayTeamId]: 0,
                },
              };
            }
            return acc;
          }, {})
        : {},
    );
  };

  getCurrentMatchStatus = async (matchId: string) => {
    const currentState = await Api.getMatch(matchId);
    if (currentState.liveState) {
      this.setState({
        live: {
          liveState: currentState.liveState,
          timer: currentState.timer || {},
          protocol: {
            ...(currentState.protocol || {}),
            events: currentState.protocol
              ? currentState.protocol.events! || []
              : [],
          },
        },
      });
    }
  };

  registerLiveScore = async (matchId: string) => {
    this.setState(
      {
        live: {
          liveState: this.props.match.liveState || this.state.live.liveState,
          timer: this.props.match.timer || this.state.live.timer,
          protocol: {
            ...(this.props.match.protocol || {}),
            events: getProp(this.props.match.protocol, ['events'], []),
          },
        },
      },
      () => {
        this.registerTimer();
        const socket = socketConnect.default(
          process.env.REACT_APP_SOCKET_API_BASE_URL || '',
          { path: '/api/v1/socket.io' },
        );
        const $this = this;
        socket.on(`match_livestate_${matchId}`, (response: any) => {
          $this.setState(
            {
              live: {
                ...$this.state.live,
                ...response,
              },
            },
            () => {
              $this.registerTimer();
              if ($this.props.onLiveScoreChange) {
                $this.props.onLiveScoreChange({
                  ...$this.state.live,
                  ...response,
                });
              }
            },
          );
        });
      },
    );
    this.getCurrentMatchStatus(matchId);
  };

  registerTimer = () => {
    if (this.timerEvent) {
      clearInterval(this.timerEvent);
      this.timerEvent = 1;
    }
    this.forceUpdate();
    if (this.state.live.liveState.ongoing) {
      this.timerEvent = setInterval(() => {
        this.forceUpdate();
      }, 1000);
    }
  };

  renderContumatedMatch = (match: IMatch) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    const { homeTeamGoals, awayTeamGoals } = this.getGoalsByTeams();

    const goalsByMatchPart = this.getGoalsByMatchPart(
      (match.protocol || { events: [] }).events,
    );
    const {
      homeTeamGoals: homeTeamPenaltyGoals,
      awayTeamGoals: awayTeamPenaltyGoals,
    } = this.getShootoutGoals();
    const phases = this.props.sportSectorsPhases[match.rules.sport_sector];
    const penaltyPhase = (phases ? phases.items : []).find(
      i => i._id === 'shootout',
    );

    return (
      <>
        <div>{`${homeTeamGoals} - ${awayTeamGoals}`}</div>
        <GoalsByPhases>
          (
          {Object.keys(goalsByMatchPart)
            .map(phase => {
              return `${goalsByMatchPart[phase][homeTeamId] ||
                0}:${goalsByMatchPart[phase][awayTeamId] || 0}`;
            })
            .join(', ')}
          )
        </GoalsByPhases>
        {(!!homeTeamPenaltyGoals || !!awayTeamPenaltyGoals) && (
          <GoalsByPhases>
            {!!penaltyPhase && penaltyPhase.label}
            <br />
            {`${homeTeamPenaltyGoals}:${awayTeamPenaltyGoals}`}
          </GoalsByPhases>
        )}
        <MatchStatusIndicator color={this.props.theme.color.warning}>
          {__('Kontumované')}
        </MatchStatusIndicator>
      </>
    );
  };

  renderClosedMatch = (match: IMatch) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    const { homeTeamGoals, awayTeamGoals } = this.getGoalsByTeams();
    const goalsByMatchPart = this.getGoalsByMatchPart(
      (match.protocol || { events: [] }).events,
    );

    const {
      homeTeamGoals: homeTeamPenaltyGoals,
      awayTeamGoals: awayTeamPenaltyGoals,
    } = this.getShootoutGoals();
    const phases = this.props.sportSectorsPhases[match.rules.sport_sector];
    const penaltyPhase = (phases ? phases.items : []).find(
      i => i._id === 'shootout',
    );

    return (
      <>
        <div>{`${homeTeamGoals} - ${awayTeamGoals}`}</div>
        <GoalsByPhases>
          (
          {Object.keys(goalsByMatchPart)
            .map(phase => {
              return `${goalsByMatchPart[phase][homeTeamId] ||
                0}:${goalsByMatchPart[phase][awayTeamId] || 0}`;
            })
            .join(', ')}
          )
        </GoalsByPhases>
        {(!!homeTeamPenaltyGoals || !!awayTeamPenaltyGoals) && (
          <GoalsByPhases>
            {!!penaltyPhase && penaltyPhase.label}
            <br />
            {`${homeTeamPenaltyGoals}:${awayTeamPenaltyGoals}`}
          </GoalsByPhases>
        )}
        <MatchStatusIndicator color={this.props.theme.color.success}>
          {__('Ukončené')}
        </MatchStatusIndicator>
      </>
    );
  };

  renderFutureMatch = (match: IMatch) => {
    return (
      <>
        <div>{format(new Date(match.startDate), 'HH:mm')}</div>
        {!this.props.verbose && (
          <MatchStatusIndicator color={this.props.theme.inactiveColor}>
            {format(new Date(match.startDate), 'DD.MM.YYYY')}
          </MatchStatusIndicator>
        )}
      </>
    );
  };

  renderTime = (activePhase?: ISportSectorPhase | null) => {
    const nowDiff = differenceInSeconds(
      new Date(),
      new Date(this.state.live.liveState.toggleDate),
    );
    let currentTime = addSeconds(
      new Date(this.state.live.liveState.toggleDate),
      this.state.live.liveState.seconds,
    );
    if (this.state.live.liveState.ongoing) {
      if (this.props.settings.reverseTime) {
        currentTime = subSeconds(currentTime, nowDiff);
      } else {
        currentTime = addSeconds(currentTime, nowDiff);
      }
    }
    let diff = differenceInSeconds(
      currentTime,
      new Date(this.state.live.liveState.toggleDate),
    );
    let minutes = String(Math.floor(diff / 60));
    let seconds = String(diff % 60).padStart(2, '0');
    if (!this.props.settings.overlapTime && activePhase) {
      const phase = this.props.settings.phases[activePhase._id];
      if (
        phase &&
        ((this.props.settings.reverseTime && diff < phase.endTime) ||
          (!this.props.settings.reverseTime && diff > phase.endTime))
      ) {
        diff = phase.endTime;
      }
    }

    if (!minutes || minutes === 'NaN') {
      return null;
    }
    if (this.props.settings.displaySeconds) {
      minutes = String(Math.floor(diff / 60));
      seconds = String(diff % 60).padStart(2, '0');
      return (
        <LiveTime>
          {minutes}:{seconds} ({!!activePhase && activePhase.label})
        </LiveTime>
      );
    }
    return (
      <LiveTime>
        {String(Number(minutes) + 1)}' ({!!activePhase && activePhase.label})
      </LiveTime>
    );
  };

  renderLiveMatch = (match: IMatch) => {
    const phases = this.props.sportSectorsPhases[match.rules.sport_sector];
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    const { homeTeamGoals, awayTeamGoals } = this.getGoalsByTeams();

    let activePhaseIndex = 0;
    let activePhase: ISportSectorPhase | null | undefined = null;
    if (phases) {
      activePhaseIndex = phases.items.findIndex(
        phase => phase._id === this.state.live.liveState.phase,
      );
      activePhase = phases.items.find(
        phase => phase._id === this.state.live.liveState.phase,
      );
    }

    const goalsByMatchPart = this.getGoalsByMatchPart(
      this.state.live.protocol ? this.state.live.protocol.events || [] : [],
    );

    const {
      homeTeamGoals: homeTeamPenaltyGoals,
      awayTeamGoals: awayTeamPenaltyGoals,
    } = this.getShootoutGoals();
    const penaltyPhase = (phases ? phases.items : []).find(
      i => i._id === 'shootout',
    );

    return (
      <>
        <div>{`${homeTeamGoals} - ${awayTeamGoals}`}</div>
        <GoalsByPhases>
          (
          {Object.keys(goalsByMatchPart)
            .map((phase, idx) => {
              if (idx <= activePhaseIndex) {
                return `${goalsByMatchPart[phase][homeTeamId] ||
                  0}:${goalsByMatchPart[phase][awayTeamId] || 0}`;
              }
              return '-:-';
            })
            .join(', ')}
          )
        </GoalsByPhases>
        {this.state.live.liveState.phase === 'shootout' && (
          <GoalsByPhases>
            {!!penaltyPhase && penaltyPhase.label}
            <br />
            {`${homeTeamPenaltyGoals}:${awayTeamPenaltyGoals}`}
          </GoalsByPhases>
        )}
        {this.state.live.liveState.phase !== 'end' && (
          <>
            <MatchStatusIndicator color={this.props.theme.color.danger}>
              {__('LIVE')}
            </MatchStatusIndicator>{' '}
            {this.renderTime(activePhase)}
          </>
        )}
      </>
    );
  };

  getMatchState = (match: IMatch) => {
    let content = null;
    let isFutureMatch = false;
    if (match.contumation && match.contumation.isContumated) {
      content = this.renderContumatedMatch(match);
    } else if (match.closed) {
      content = this.renderClosedMatch(match);
    } else if (
      !match.closed &&
      !match.liveState &&
      !this.state.live.liveState.phase
    ) {
      content = this.renderFutureMatch(match);
      isFutureMatch = true;
    } else if (this.state.live.liveState.phase === 'end') {
      content = this.renderClosedMatch(match);
    } else {
      content = this.renderLiveMatch(match);
    }
    if (this.props.verbose) {
      return (
        <LiveScore compact={!!this.props.compact}>
          <MatchDate>
            {format(match.startDate, 'DD. MMMM YYYY', { locale: skLocale })}
          </MatchDate>
          {!isFutureMatch && (
            <MatchTime>{format(match.startDate, 'HH:mm')}</MatchTime>
          )}
          {!!match.sportGround && (
            <MatchSportGround>
              {match.sportGround.sportObjectName}
            </MatchSportGround>
          )}
          {content}
        </LiveScore>
      );
    }
    return <LiveScore compact={!!this.props.compact}>{content}</LiveScore>;
  };

  render() {
    const { match, lastIndex, fullWidth, compact, verbose } = this.props;
    const homeTeam = this.getTeam('home');
    const awayTeam = this.getTeam('away');

    const content = (
      <MatchWrapper
        fullWidth={fullWidth}
        lastIndex={lastIndex}
        matchSize={this.props.matchSize}
        compact={!!compact}
        verbose={!!verbose}
      >
        <Team align="left" compact={!!compact}>
          {homeTeam && homeTeam.organization.logo_public_url && (
            <TeamLogo
              title={
                homeTeam
                  ? homeTeam.organization.name
                  : match.teams[0].organization.name
              }
              alt={
                homeTeam
                  ? homeTeam.organization.name
                  : match.teams[0].organization.name
              }
              src={
                homeTeam
                  ? homeTeam.organization.logo_public_url || ''
                  : match.teams[0].organization.logo_public_url || ''
              }
            />
          )}
          <TeamName>
            {homeTeam ? homeTeam.displayName : match.teams[0].name}
          </TeamName>
        </Team>
        {this.getMatchState(match)}
        <Team align="right" compact={!!compact}>
          {awayTeam && awayTeam.organization.logo_public_url && (
            <TeamLogo
              title={
                awayTeam
                  ? awayTeam.organization.name
                  : match.teams[1].organization.name
              }
              alt={
                awayTeam
                  ? awayTeam.organization.name
                  : match.teams[1].organization.name
              }
              src={
                awayTeam
                  ? awayTeam.organization.logo_public_url || ''
                  : match.teams[1].organization.logo_public_url || ''
              }
            />
          )}
          <TeamName>
            {awayTeam ? awayTeam.displayName : match.teams[1].name}
          </TeamName>
        </Team>
      </MatchWrapper>
    );
    if (verbose) {
      return content;
    }
    return (
      <div style={{ width: '100%' }}>
        <Link to={`/matches/${match._id}`}>{content}</Link>
      </div>
    );
  }
}

export default Match;
