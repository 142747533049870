// App related config
export default {
  APP_ID: 'sutaze',
  DEFAULT_LIST_LIMIT: 20,
  APP_NS: 'APP',
  DATE_FORMAT: 'DD.MM.YYYY',
  DATE_TIME_FORMAT: 'DD.MM.YYYY HH:mm',
  CREATED_DATE_FORMAT: 'dddd, DD.MM.YYYY',
  THEMER_NS: 'THEMER',
  COMPETITION_GROUPS_NS: 'COMPETITION_GROUPS',
  COMPETITIONS_NS: 'COMPETITIONS',
  COMPETITION_PARTS_NS: 'COMPETITION_PARTS_NS',
  MATCHES_NS: 'MATCHES',
  SPORT_SECTOR_PHASES: 'SPORT_SECTOR_PHASES',
  DEFAULT_CONTENT_DIVIDER: 'default',
  CONTENT_MAX_WIDTH: 1200,
  WIDER_CONTENT_MAX_WIDTH: 870,
  LIST_SIMILAR_ARTICLES: 'SIMILAR_ARTICLES',
  LIST_SIMILAR_ARTICLES_LIMIT: 12,
  LIST_SECTION_ARTICLES: 'SECTION_ARTICLES',
  LIST_SECTION_ARTICLES_LIMIT: 12,
  LIST_SEARCH_RESULTS: 'SEARCH_RESULTS',
  LIST_SEARCH_RESULTS_LIMIT: 28,
  LIST_NOTFOUND_ARTICLES: 'NOTFOUND_ARTICLES',
  LIST_NOTFOUND_ARTICLES_LIMIT: 12,
  HEADER_ROOT_SECTION_ID_OR_UNIQ_ID: 'MAINMENU',
};
