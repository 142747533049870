import Article from '@sportnet/ui/lib/Article';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import NotFound from '@sportnet/ui/lib/NotFound';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import Select from '@sportnet/ui/lib/Select';
import styled, { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { isAfter, isBefore } from 'date-fns';
import { rem } from 'polished';
import connectQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'redux';
import { getListParameters, isCommiting } from 'redux-list';
import { getProp } from 'sportnet-utilities';
import { connectSsr } from 'ssr-service';
import { withTheme } from 'styled-components';
import EntryAnimation from '../../components/EntryAnimation';
import { ReduxConnectProps } from '../../configureStore';
import SearchFilter from '../../containers/Filters/SearchFilter';
import { ISeason } from '../../library/Season';
import Page from '../../pages/Page';
import { RootState } from '../../rootReducer';
import { getPPOUrl, __ } from '../../utilities';
import { initializeOrSetListParams, removeActiveCompetition } from '../App/actions';
import { currentPPOSelector } from '../App/selectors';
import { getCompetitionsList, getSeasons } from './actions';
import { competitionsSelector, seasonsSelector } from './selectors';

export const LIST_NAME = 'COMPETITIONS_LIST';

const ModifiedCol = styled(Col)`
  padding: ${rem(5)};
`;

const ModifiedRow = styled(Row)`
  margin-left: -${rem(5)};
  width: calc(100% + ${rem(10)});
`;

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  ${mb('m')} {
    flex-direction: row;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${rem(10)};
  margin-left: -${rem(5)};
  width: calc(100% + ${rem(10)});
  ${mb('m')} {
    flex-direction: row;
    padding-top: 0;
    margin-left: 0;
    width: auto;
  }
  & > div {
    padding: 0 ${rem(5)};
  }
`;

export const HeaderWrapper = styled.div`
  padding: ${rem(20)} 0;
`;

export const StyledHeaderXL = styled.h1`
  font-weight: lighter;
  font-size: ${rem(32)};
  color: #555555;
  padding: 0;
  margin: 0;
`;

export const StyledHeaderL = styled.h2`
  font-weight: lighter;
  font-size: ${rem(16)};
  color: #555555;
  padding: 0;
  margin: 0;
`;

export const StyledHeaderM = styled.h3`
  font-weight: lighter;
  font-size: ${rem(24)};
  color: #555555;
  padding: 0;
  margin: 0;
`;

const mapStateToProps = (state: RootState) => ({
  currentPPO: currentPPOSelector(state),
  listParameters: getListParameters(LIST_NAME)(state),
  competitions: competitionsSelector(LIST_NAME)(state),
  seasons: seasonsSelector()(state),
  isFetching: isCommiting(LIST_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = ReduxConnectProps & IMapStateToProps & QueryHocInterface & Theme;

class Competitions extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    let seasons: {
      entities: {
        SEASONS?: {
          [key: string]: ISeason;
        };
      };
    } = {
      entities: {
        SEASONS: (props.seasons || []).reduce(
          (acc: { [key: string]: Readonly<ISeason> }, season) => {
            return { ...acc, [season._id]: season };
          },
          {},
        ),
      },
    };
    if (!Object.keys(seasons.entities.SEASONS || {}).length) {
      seasons = await dispatch(getSeasons.action({}));
    }
    const actualSeasonId = Object.keys(seasons.entities.SEASONS || {}).find(
      id => {
        return (
          isBefore(
            new Date(
              seasons.entities.SEASONS
                ? seasons.entities.SEASONS[id].dateFrom
                : 0,
            ),
            new Date(),
          ) &&
          isAfter(
            new Date(
              seasons.entities.SEASONS
                ? seasons.entities.SEASONS[id].dateTo
                : 0,
            ),
            new Date(),
          )
        );
      },
    );

    const params: { [key: string]: any } = {
      offset: props.query.offset,
      seasonId: props.query.seasonId,
    };

    if (!params.seasonId && actualSeasonId) {
      params.seasonId = actualSeasonId;
    }
    if (props.query.q) {
      params.q = props.query.q;
    }

    dispatch(removeActiveCompetition());

    await dispatch(
      initializeOrSetListParams.action({
        listName: LIST_NAME,
        params,
      }),
    );
    await Promise.all([dispatch(getCompetitionsList())]);
  }

  changeSeason = (e: any) => {
    this.props.setParameter({ seasonId: e.target.value });
  };

  render() {
    const {
      currentPPO,
      competitions,
      seasons,
      listParameters,
      isFetching,
    } = this.props;
    const title = currentPPO.name ? currentPPO.name : __('Zoznam súťaží');
    return (
      // <App>
      <Page>
        <EntryAnimation key={'Competitions'}>
          <Helmet titleTemplate="%s">
            <title>{title}</title>
            <meta
              property="og:url"
              content={`${getPPOUrl(currentPPO._id || '')}`}
            />
            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <meta name="twitter:title" content={title} />
          </Helmet>
          <HeaderWrapper>
            <StyledHeaderXL>{title}</StyledHeaderXL>
            <StyledHeaderL>{__('Zoznam súťaží')}</StyledHeaderL>
          </HeaderWrapper>
          <Segment
            raised
            header={
              <SegmentHeader size="s" withSeparator>
                <FilterWrapper>
                  <div>{__('Aktuálne súťaže')}</div>
                  <Filters>
                    <SearchFilter
                      q={this.props.query.q as string}
                      setParameter={({ q }) => {
                        this.props.setParameter({ q });
                      }}
                      loading={!!isFetching}
                    />
                    <Select
                      value={listParameters.seasonId}
                      onChange={this.changeSeason}
                    >
                      {seasons.map(season => (
                        <option key={season._id} value={season._id}>
                          {season.name}
                        </option>
                      ))}
                    </Select>
                  </Filters>
                </FilterWrapper>
              </SegmentHeader>
            }
          >
            {!isFetching && !competitions.length && (
              <div style={{ padding: `${rem(10)} 0` }}>
                <NotFound title="Ľutujeme, nič sme nenašli" icon="search" />
              </div>
            )}
            <ModifiedRow>
              {competitions.map(competition => {
                return (
                  <ModifiedCol xs={12} s={6} m={3} key={competition._id}>
                    <Link
                      to={`/competition-groups/${competition.competitionGroupId}?competitionId=${competition._id}`}
                    >
                      <Article
                        title={competition.name}
                        subtitle={currentPPO.name}
                        imgSrc={String(
                          getProp(
                            competition,
                            ['publication', 'logo', 'public_url'],
                            currentPPO.logo_public_url || '',
                          ),
                        )}
                      />
                    </Link>
                  </ModifiedCol>
                );
              })}
            </ModifiedRow>
          </Segment>
        </EntryAnimation>
      </Page>
      // </App>
    );
  }
}

export default compose<React.FC<Props>>(
  connect(mapStateToProps),
  connectQueryHoc({
    parameters: {
      seasonId: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      q: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      offset: {
        type: QueryHocTypes.Number,
        defaultValue: 0,
      },
    },
  }),
  connectSsr({ displayName: 'COMPETITIONS' }),
  withTheme,
)(Competitions);
