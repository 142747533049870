import { CustomThunkDispatch } from '../../configureStore';
import { Writeable } from '../../pages/App/reducer';
import { entitiesSelector } from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';

interface IEntity {
  [key: string]: any;
}

interface IEntities {
  [key: string]: IEntity;
}

export const updateEntitiesUtility = (
  oldEntities: IEntities,
  entitiesToUpdate: IEntities,
): IEntities => {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return Object.keys(entities).reduce(
    (acc: Partial<typeof entities>, entityName: keyof typeof entities) => {
      if (entityName in oldEntities) {
        acc[entityName] = Object.keys(entities[entityName]).reduce(
          (innerAcc: { [key: string]: any }, entityId: string) => {
            if (entityId in oldEntities[entityName]) {
              innerAcc[entityId] = {
                ...oldEntities[entityName][entityId],
                ...entities[entityName][entityId],
              };
            } else {
              innerAcc[entityId] = entities[entityName][entityId];
            }
            return innerAcc;
          },
          {},
        );
      } else {
        acc[entityName] = entities[entityName];
      }
      return acc;
    },
    {},
  );
};

export const updateEntities = (
  entitiesToUpdate: Partial<RootState['entities']>,
) => {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return (dispatch: CustomThunkDispatch, getState: () => RootState) => {
    const oldEntities = entitiesSelector(getState());
    const nextEntities = updateEntitiesUtility(oldEntities, entities);
    return dispatch({
      type: 'UPDATE_ENTITIES',
      payload: {
        result: {
          entities: nextEntities,
        },
      },
    });
  };
};
