class UnauthorizedError extends Error {
  identifier: string;
  error: any;
  constructor(e: any) {
    super('UNAUTHORIZED');
    this.error = e;
    this.identifier = 'UNAUTHORIZED';
  }
}

export default UnauthorizedError;
