import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';

type PlayerStats = {
  name: string;
  stats: {
    [key: string]: number;
  };
  teamId: string;
  team: {
    name: string;
  };
  _id: string;
};

type OwnProps = {
  header: string;
  statName: string;
  items: PlayerStats[];
};

const StatNumber = styled.div``;

const PlayerWrapper = styled.div<{ top: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${rem(14)};
  padding: ${rem(5)} 0;
  color: #555555;
  ${({ top }) => {
    if (top) {
      return css`
        font-weight: bold;
        & > ${StatNumber} {
          font-size: ${rem(16)};
        }
      `;
    }
    return css``;
  }}
`;

const Player = styled.div``;
const PlayerClub = styled.div`
  color: #aaa;
  font-size: 12px;
  font-weight: normal;
`;

const PlayersLadder: React.FC<OwnProps> = ({ header, statName, items }) => {
  return (
    <Segment
      raised
      header={
        <SegmentHeader withSeparator size="xs">
          {header}
        </SegmentHeader>
      }
    >
      {items.map((i, idx) => {
        return (
          <PlayerWrapper key={i._id} top={idx === 0}>
            <Player>
              {i.name}
              <br />
              <PlayerClub>{i.team.name}</PlayerClub>
            </Player>
            <StatNumber>{i.stats[statName]}</StatNumber>
          </PlayerWrapper>
        );
      })}
    </Segment>
  );
};

export default PlayersLadder;
