import { ContentLoader } from '@sportnet/ui/lib/Loader';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { em, rem } from 'polished';
import * as React from 'react';
import Slider from 'react-slick';
import styled, { withTheme } from 'styled-components';
import { ISportSectorPhases, ISportSectorSetting } from '../../library/App';
import { IMatch } from '../../library/Match';
import theme from '../../theme/theme';
import { isBrowser, useWindowSize } from '../../utilities';
import Match from '../Match';
import { Dot, Dots } from './Paging';

interface OwnProps {
  matches: IMatch[];
  isFetching: boolean | null;
  sportSectorsPhases: { [key: string]: ISportSectorPhases };
  sportSectorsSettings: { [key: string]: ISportSectorSetting };
}

type Props = OwnProps & { theme: typeof theme };

const Wrapper = styled.div`
  .slick-list {
    margin: -5px;
  }
  .slick-slide > div {
    padding: 5px 0;
  }
  margin-bottom: ${rem(10)};
`;
export const StyledDots = styled(Dots)`
  padding: ${({ theme }) => `${em(theme.grid.gutterWidth)} 0 !important`};
`;

const MatchWrapper = styled.div`
  background: white;
  min-height: ${rem(100)};
  box-shadow: 0px 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  margin-bottom: ${rem(0)};
  display: flex !important;
  padding: ${rem(10)};
`;

const Col = styled.div`
  width: 50%;
  padding: 0 ${rem(5)};
  ${mb('s')} {
    width: 100%;
  }
`;

const MatchesList: React.FC<Props> = ({
  sportSectorsSettings,
  sportSectorsPhases,
  matches,
  theme,
  isFetching,
}) => {
  const resizeHook = useWindowSize();

  const [mobileLayout, setMobileLayout] = React.useState(
    isBrowser() && window.innerWidth < 370,
  );

  React.useEffect(() => {
    if (isBrowser()) {
      if (resizeHook.innerWidth < 370) {
        setMobileLayout(true);
      } else {
        setMobileLayout(false);
      }
    }
  }, [resizeHook.innerWidth]);

  const renderSliderChildren = () =>
    matches.map((match, idx) => (
      <Col key={match._id}>
        <MatchWrapper>
          <Match
            settings={{
              ...sportSectorsSettings[match.rules.sport_sector],
              ...match.settings,
            }}
            theme={theme}
            match={match}
            compact={!mobileLayout}
            fullWidth
            lastIndex={false}
            sportSectorsPhases={sportSectorsPhases}
          />
        </MatchWrapper>
      </Col>
    ));

  const sliderSettings = {
    infinite: false,
    slidesToShow: 1,
    slidesPerRow: 2,
    rows: 4,
    dots: true,
    autoplay: false,
    arrows: false,
    speed: 500,
    adaptiveHeight: true,
    swipeToSlide: true,
    appendDots: (dots: any) => {
      return <StyledDots>{dots}</StyledDots>;
    },
    customPaging: (i: number) => <Dot active={true} />,
    responsive: [
      {
        breakpoint: theme.sizes.m,
        settings: {
          slidesToShow: 1,
          slidesPerRow: 1,
          rows: 8,
        },
      },
    ],
  };
  return (
    <div>
      {!!isFetching ? (
        <ContentLoader size="xl" theme={theme} />
      ) : (
        <Wrapper>
          <Slider {...sliderSettings}>{renderSliderChildren()}</Slider>
        </Wrapper>
      )}
    </div>
  );
};

export default withTheme(MatchesList);
