import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { em } from 'polished';
import * as React from 'react';
import config from '../../../config';
import styled from '../../../theme/styled-components';
import MaxWidthBox from '../../MaxWidthBox';

const PerexParagraph = styled('p')`
  font-size: ${em(18)};
  text-align: start;
  line-height: 1.4;
  margin: 0;
  margin-bottom: 1em;
  ${mb('m')} {
    font-size: ${em(20)};
  }
`;

interface OwnProps {
  text: string | null;
}

type Props = OwnProps;

const Perex: React.FC<Props> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      {text.split('\n').map((p, idx) => (
        <PerexParagraph key={idx}>{p}</PerexParagraph>
      ))}
    </MaxWidthBox>
  );
};

export default Perex;
