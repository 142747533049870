import { ContentLoader } from '@sportnet/ui/lib/Loader';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/lib/Table';
import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import Tooltip from 'react-tooltip-lite';
import { getProp } from 'sportnet-utilities';
import styled, { css, withTheme } from 'styled-components';
import {
  ICompetitionLogo,
  IResultsTableItem,
  ITeam,
} from '../../library/Competition';
import { StyledSegmentHeader } from '../../pages/CompetitionMatch';
import { __ } from '../../utilities';

const Condition = styled.div`
  display: flex;
`;
const ConditionItem = styled.div<{ result: string }>`
  width: ${rem(24)};
  height: ${rem(24)};
  font-size: ${rem(12)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: ${({ theme, result }) => {
    if (result === 'W') {
      return theme.color.success;
    } else if (result === 'D') {
      return theme.color.warning;
    } else if (result === 'L') {
      return theme.color.danger;
    }
    return theme.color.primary;
  }};
  color: white;
`;

const StyledTr = styled(Tr)<{ hover: boolean }>`
  td {
    background: white;
  }
  ${({ hover }) => {
    if (hover) {
      return css`
        td {
          background: #f8f8f8;
        }
      `;
    }
    return css``;
  }}
`;

interface OwnProps {
  logoSrc?: ICompetitionLogo;
  teams: ITeam[];
  resultsTable: IResultsTableItem[];
  isFetching?: boolean | null;
  header?: string;
}

type Props = OwnProps & Theme;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Header = styled.div``;
const CompetitionLogo = styled.img`
  height: ${rem(40)};
  padding-right: ${rem(15)};
`;
const TeamLogo = styled.img`
  height: ${rem(20)};
`;

const CompetitionPartTable: React.FC<Props> = ({
  resultsTable,
  teams,
  logoSrc,
  header,
  isFetching,
  theme,
}) => {
  const [hoverTeams, setHoverTeams] = React.useState<string[]>([]);

  const getResultLabel = (result: 'W' | 'D' | 'L') => {
    switch (result) {
      case 'W':
        return __('V');
      case 'D':
        return __('R');
      case 'L':
        return __('P');
      default:
        return '';
    }
  };

  const getTeamCondition = (result: IResultsTableItem) => {
    const lastFive = [...(result.matches || [])].reverse().slice(0, 5);
    return lastFive.map(m => {
      return (
        <Tooltip
          hoverDelay={0}
          content={
            <div>
              <b>{m.score}</b> (
              {m.homeaway === 'home'
                ? result.team && result.team.name
                : m.team.name}
              &nbsp;-&nbsp;
              {m.homeaway === 'away'
                ? result.team && result.team.name
                : m.team.name}
              )
            </div>
          }
        >
          <Link to={`/matches/${m.matchId}`}>
            <div
              style={{ padding: `0 ${rem(2)}` }}
              onMouseEnter={() => {
                if (result.team) {
                  setHoverTeams([result.team._id, m.team._id]);
                }
              }}
              onMouseLeave={() => {
                setHoverTeams([]);
              }}
            >
              <ConditionItem result={m.result}>
                {getResultLabel(m.result)}
              </ConditionItem>
            </div>
          </Link>
        </Tooltip>
      );
    });
  };

  const results = (resultsTable || []).reduce((acc: any[], result: any) => {
    const team = teams.find(
      team => result.team && team._id === result.team._id,
    );
    if (team) {
      return [
        ...acc,
        {
          ...result,
          _id: result.team && result.team._id,
          team: team,
        },
      ];
    }
    return acc;
  }, []);

  return (
    <Segment
      raised
      header={
        <StyledSegmentHeader>
          <SegmentHeader withSeparator size="m">
            <HeaderWrapper>
              {!!logoSrc && (
                <CompetitionLogo
                  title={__('Logo súťaže')}
                  alt={__('Logo súťaže')}
                  src={logoSrc.public_url}
                />
              )}
              <Header>{header || __('Aktuálne poradie')}</Header>
            </HeaderWrapper>
          </SegmentHeader>
        </StyledSegmentHeader>
      }
    >
      {!!isFetching ? (
        <ContentLoader theme={theme} />
      ) : (
        <>
          <Table>
            <Thead>
              <Tr>
                <Th width={30} />
                <Th width={30} />
                <Th>{__('Klub')}</Th>
                <Th width={30}>{__('Z')}</Th>
                <Th width={30}>{__('V')}</Th>
                <Th width={30}>{__('R')}</Th>
                <Th width={30}>{__('P')}</Th>
                <Th width={60}>{__('Skóre')}</Th>
                <Th width={30}>{__('B')}</Th>
                <Th width={160}>{__('Forma')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {results.map((result, idx) => {
                const team = teams.find(
                  t => result.team && t._id === result.team._id,
                );
                return (
                  <StyledTr
                    hover={
                      result.team &&
                      hoverTeams.includes(String(result.team._id))
                    }
                  >
                    <Td>{idx + 1}</Td>
                    <Td>
                      <TeamLogo
                        title={result.team && result.team.name}
                        alt={result.team && result.team.name}
                        key={`logo_${result.team && result.team._id}`}
                        src={getProp(
                          team || {},
                          ['organization', 'logo_public_url'],
                          '',
                        )}
                      />
                    </Td>
                    <Td>{result.team && result.team.name}</Td>
                    <Td>{result.stats.matches.played}</Td>
                    <Td>{result.stats.matches.won}</Td>
                    <Td>{result.stats.matches.draw}</Td>
                    <Td>{result.stats.matches.lost}</Td>
                    <Td>
                      {result.stats.goals.given}:{result.stats.goals.received}
                    </Td>
                    <Td>{result.stats.points}</Td>
                    <Td>
                      <Condition>{getTeamCondition(result)}</Condition>
                    </Td>
                  </StyledTr>
                );
              })}
            </Tbody>
          </Table>
        </>
      )}
    </Segment>
  );
};

export default withTheme(CompetitionPartTable);
