import {
  getTheme as getDefaultTheme,
  Theme,
} from '@sportnet/ui/lib/Themes/baseTheme';
import { emphasizeColor } from '@sportnet/ui/lib/Themes/utilities';

const sizes = {
  xl: 1200,
  l: 992,
  m: 768,
  s: 480,
  xs: 320,
};

const customTheme = {
  backgroundColor: '#f7f7f7',
  buttonBackgroundColor: '#eee',
  sizes,
};

const app = {
  maxWidth: 1230,
  padding: 15,
  bgColor: 'rgb(247, 247, 247)',
  secondaryBgColor: '#FAFAFA',
  textColor: '#484848',
  secondaryTextColor: '#707070',
};

const title = {
  textColor: emphasizeColor(0.12, app.textColor),
};

const breadcrumbs = {
  textColor: app.secondaryTextColor,
};

const contentMetaInformation = {
  textColor: app.secondaryTextColor,
};

const author = {
  textColor: app.secondaryTextColor,
};

const articleList = {
  bgColor: app.secondaryBgColor,
};

type Color = string;

export interface ThemeInterface extends Theme {
  backgroundColor: string;
  buttonBackgroundColor: string;
  fontFamily: string;
  app: {
    maxWidth: number;
    padding: number;
    bgColor: string;
    [key: string]: any;
  };
  title: {
    textColor: Color;
  };
  breadcrumbs: {
    textColor: Color;
  };
  contentMetaInformation: {
    textColor: Color;
  };
  author: {
    textColor: Color;
  };
  articleList: {
    bgColor: Color;
  };
}

type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };

export const getTheme = (
  override?: RecursivePartial<Theme>,
): ThemeInterface & {
  sizes: {
    [key: string]: number;
  };
} => {
  return {
    ...getDefaultTheme(override || {}),
    ...customTheme,
    backgroundColor: 'rgb(247, 247, 247)',
    app,
    title,
    breadcrumbs,
    contentMetaInformation,
    author,
    articleList,
  };
};

export const theme = getTheme();
export default theme;
