import Icon from '@sportnet/ui/lib/Icon';
import Image from '@sportnet/ui/lib/Image';
import ResponsiveMenu from '@sportnet/ui/lib/ResponsiveMenu';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import MaxWidthBox from '../../components/MaxWidthBox';
import { ReduxConnectProps } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { currentPPOSelector } from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';
import styled, { css } from '../../theme/styled-components';
import { idealTextColor } from '../../utilities';

const Wrapper = styled('div') <{ noShadow?: boolean }>`
  display: flex;
  background-color: white;
  height: ${rem(80)};
  position: relative;
  ${({ noShadow }) => {
    if (!noShadow) {
      return css`
        box-shadow: 0 0 ${rem(12)} 0 rgba(0, 0, 0, 0.3);
      `;
    }
    return css``;
  }}
`;

const LogoLink = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: ${rem(56)};
`;

const InnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
`;

const BurgerWrapper = styled('div')`
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
  flex-shrink: 0;
  ${mb('m')} {
    display: none;
  }
`;

const Menu = styled('div')`
  display: none;
  ${mb('m')} {
    display: flex;
  }
`;

const MenuItem = styled(Link as any)`
  text-decoration: none;
  text-align: center;
  padding: ${rem(10)};
  display: block;
  font-size: ${rem(14)};
  cursor: pointer;
  color: inherit;
  min-width: ${rem(100)};
  &:hover {
    ${({ theme }) =>
    css`
        color: ${theme.color.primary};
        background-color: white;
      `}
  }
  &.active {
    ${({ theme }) =>
    css`
        background-color: ${theme.color.primary};
        color: ${idealTextColor(theme.color.primary)};
      `}
  }
  transition: all 0.2s ease-out;
`;

const ResponsiveMenuItem = styled(Link as any)``;

const mapStateToProps = (state: RootState) => ({
  currentPPO: currentPPOSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Section = {
  name: string;
  _id: string;
  url: string;
  absolute?: boolean;
  root?: boolean;
};

interface OwnProps {
  sections: Section[];
  logoSrc: string;
  logoLink: string;
  noShadow?: boolean;
}

type Props = IMapStateToProps & ReduxConnectProps & RouteProps<{}> & OwnProps;

class Header extends React.PureComponent<Props> {
  state = {
    menuOpened: false,
  };

  handleMenuOpen = () => {
    this.setState({
      menuOpened: true,
    });
  };

  handleMenuClose = () => {
    this.setState({
      menuOpened: false,
    });
  };

  handleLinkClick = (section: any) => {
    this.props.router.push(section.url);
    this.handleMenuClose();
  };

  renderMenu = (section: Section) => {
    const active = this.props.location.pathname === section._id || (section.root && this.props.location.pathname === '/');
    if (section.absolute) {
      return (
        <MenuItem key={section._id} href={section.url}>
          {section.name}
        </MenuItem>
      );
    }
    return (
      <MenuItem
        key={section._id}
        to={section.url}
        className={active ? 'active' : ''}
      >
        {section.name}
      </MenuItem>
    );
  };

  render() {
    const { logoSrc, logoLink, currentPPO, sections } = this.props;
    const { menuOpened } = this.state;

    return (
      <Wrapper noShadow={this.props.noShadow}>
        <MaxWidthBox noMargin>
          <InnerWrapper>
            {logoSrc && (
              <LogoLink to={logoLink}>
                <Image alt={currentPPO.name || ''} src={logoSrc} height={40} />
              </LogoLink>
            )}
            <BurgerWrapper onClick={this.handleMenuOpen}>
              <Icon name="burger" size="xl" />
            </BurgerWrapper>
            <Menu>{sections.map(this.renderMenu)}</Menu>
            <ResponsiveMenu
              sections={sections}
              renderLink={({ section, linkProps }) => {
                const i = sections.find(s => section._id === s._id);
                if (i && i.absolute) {
                  return (
                    <ResponsiveMenuItem href={i.url} {...linkProps}>
                      {linkProps.children}
                    </ResponsiveMenuItem>
                  );
                }
                return (
                  <ResponsiveMenuItem to={i ? i.url : '/'} {...linkProps}>
                    {linkProps.children}
                  </ResponsiveMenuItem>
                );
              }}
              onClose={this.handleMenuClose}
              onClickLink={this.handleLinkClick}
              visible={menuOpened}
              siteUrl={currentPPO.name || ''}
            />
          </InnerWrapper>
        </MaxWidthBox>
      </Wrapper>
    );
  }
}

export default connect(mapStateToProps)(Header);
