import { getListParameters, initialize, setParams } from 'redux-list';
import { ListParameters } from 'redux-list/lib/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import { BreadCrumb, INormalizedSectionNode, INormalizedSectionTree, ISportSectorSetting, NormalizedEntities, SectionNode, SectionTreeType } from '../../library/App';
import { PO } from '../../library/PO';
import { PPO } from '../../library/PPO';
import { RootState } from '../../rootReducer';
import { getSportCodelistKey, normalizeEntities } from '../../utilities';
import reduceSectionProps from '../../utilities/reduceSectionProps';
import { activeCompetitionSelector, currentPOSelector, currentPPOSelector } from './selectors';

const create = actionCreatorFactory(config.APP_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const setBreadcrumbs = create<BreadCrumb[]>('SET_BREADCRUMBS');

export const setActiveAppspace = create<string>('SET_ACTIVE_APPSPACE');

export const removeActiveAppspace = create('REMOVE_ACTIVE_APPSPACE');

export const setActiveCompetition = create<string>('SET_ACTIVE_COMPETITION');

export const setDomainPayload = create<{ [key: string]: any }>(
  'SET_DOMAIN_PAYLOAD',
);

export const removeActiveCompetition = create<void>(
  'REMOVE_ACTIVE_COMPETITION',
);

export const resetSectionTree = create<void>('RESET_SECTION_TREE');

export const setLogo = create<string | null>('SET_LOGO');

export const withAppSpace = createAsync<
  {
    action: any;
    callback: (appSpace?: string, currentPPO?: PPO) => any;
  },
  any
>('WITH_APPSPACE', async (parameters, dispatch, getState) => {
  const { appSpace } = appSettingsSelector(getState());
  const currentPPO = currentPPOSelector(getState());
  return dispatch(parameters.action(parameters.callback(appSpace, currentPPO)));
});

export const loadPpo = createAsync<
  {
    appSpace: string;
  },
  NormalizedEntities<'PPO'>
>('GET_PPO', async (parameters, dispatch, getState, { SportnetApi }) => {
  const ppo = await SportnetApi.organizationPPOProfile(parameters.appSpace);
  return normalizeEntities('PPO', [ppo]);
});

export const loadCodelist = createAsync<
  {
    codelist: string;
  },
  NormalizedEntities<'CODELISTS'>
>('GET_CODELIST', async ({ codelist }, dispatch, getState, { SportnetApi }) => {
  const response = await SportnetApi.getCodelist(codelist);
  return normalizeEntities('CODELISTS', [
    { _id: codelist, items: response.codelist },
  ]);
});

export const loadSportWithSectorsCodelist = createAsync<
  void,
  NormalizedEntities<'CODELISTS'>
>(
  'GET_CODELIST_WITH_SECTORS',
  async (parameters, dispatch, getState, { SportnetApi }) => {
    const response = await SportnetApi.getCodelist('sport');
    const sectors = await Promise.all(
      (response.codelist || []).map(async codelistItem => {
        const id = getSportCodelistKey(codelistItem.value);
        return {
          _id: id,
          items: (await SportnetApi.getCodelist(id)).codelist,
        };
      }),
    );
    const codelists = [{ _id: 'sport', items: response.codelist }].concat(
      sectors,
    );
    return normalizeEntities('CODELISTS', codelists);
  },
);

export const getSportSectorsPhases = createAsync<
  void,
  NormalizedEntities<'SPORT_SECTOR_PHASES'>
>(
  'GET_SPORT_SECTORS_PHASES',
  async (parameters, dispatch, getState, { Api }) => {
    const response = (await Api.getSettingById('sport_sector_phases')) as any;
    return {
      entities: {
        SPORT_SECTOR_PHASES: response.sport_sectors.reduce(
          (acc: { [key: string]: any }, item: any) => {
            acc[item._id] = item;
            return acc;
          },
          {},
        ),
      },
      results: response.sport_sectors.map((item: any) => item._id),
    };
  },
);

export const getSportSectorsEvents = createAsync<
  { sportSector: string },
  NormalizedEntities<'SPORT_SECTOR_EVENTS'>
>(
  'GET_SPORT_SECTOR_EVENTS',
  async (parameters, dispatch, getState, { Api }) => {
    const response = (await Api.getSettingBySportSector(
      'sport_sector_events',
      parameters.sportSector,
    )) as any;
    return {
      entities: {
        SPORT_SECTOR_EVENTS: {
          [response._id]: response,
        },
      },
      results: response.items.map((item: any) => item._id),
    };
  },
);

export const getSportSectorsDelegates = createAsync<
  { sportSector: string },
  NormalizedEntities<'SPORT_SECTOR_DELEGATES'>
>(
  'GET_SPORT_SECTOR_DELEGATES',
  async (parameters, dispatch, getState, { Api }) => {
    const response = (await Api.getSettingBySportSector(
      'sport_sector_delegates',
      parameters.sportSector,
    )) as any;
    return {
      entities: {
        SPORT_SECTOR_DELEGATES: {
          [response._id]: response,
        },
      },
      results: response.items.map((item: any) => item._id),
    };
  },
);

export const getSportSectorsCrew = createAsync<
  { sportSector: string },
  NormalizedEntities<'SPORT_SECTOR_CREW'>
>('GET_SPORT_SECTOR_CREW', async (parameters, dispatch, getState, { Api }) => {
  const response = (await Api.getSettingBySportSector(
    'sport_sector_crew',
    parameters.sportSector,
  )) as any;
  return {
    entities: {
      SPORT_SECTOR_CREW: {
        [response._id]: response,
      },
    },
    results: response.items.map((item: any) => item._id),
  };
});

export const getSportSectorsSettings = createAsync<
  { settingSubId: string },
  NormalizedEntities<'SPORT_SECTOR_SETTINGS'>
>(
  'GET_SPORT_SECTOR_SETTINGS',
  async (parameters, dispatch, getState, { Api }) => {
    const response = (await Api.getSettingById('sport_sector_settings')) as any;
    return {
      entities: {
        SPORT_SECTOR_SETTINGS: response.sport_sectors.reduce(
          (
            acc: { [key: string]: ISportSectorSetting },
            s: {
              _id: string;
              items: Array<{ _id: string; value: ISportSectorSetting }>;
            },
          ) => {
            const setting = s.items.find(
              (i: { _id: string }) => i._id === parameters.settingSubId,
            );
            return { ...acc, [s._id]: !!setting && setting.value };
          },
          {},
        ),
      },
      results: response.sport_sectors.map((item: any) => item._id),
    };
  },
);

export const loadIcons = createAsync<
  {},
  {
    logo: string | null;
    favicon: string | null;
  }
>('GET_ICONS', async (parameters, dispatch, getState) => {
  const currentPPO = currentPPOSelector(getState()) as PPO;
  const currentPO = currentPOSelector(getState()) as PO;
  const logo = currentPPO.logo_public_url || currentPO.logo_public_url || null;
  const favicon =
    currentPPO.favicon_public_url || currentPO.favicon_public_url || null;
  return {
    logo,
    favicon,
  };
});

export const initializeOrSetListParams = createAsync<
  {
    listName: string;
    params: ListParameters;
  },
  any
>('INITIALIZE_OR_SET_LIST_PARAMS', async (parameters, dispatch, getState) => {
  const reduxListParams = getListParameters(parameters.listName)(getState());
  if (Object.keys(reduxListParams).length === 0) {
    return dispatch(
      initialize({
        listName: parameters.listName,
        initialParams: parameters.params,
      }),
    );
  }
  return dispatch(
    setParams({
      listName: parameters.listName,
      parameters: parameters.params,
    }),
  );
});

export const loadPOInvoiceProfile = createAsync<void, NormalizedEntities<'PO'>>(
  'GET_PO_INVOICE_PROFILE',
  async (parameters, dispatch, getState, { SportnetApi }) => {
    const currentPPO = currentPPOSelector(getState());
    const invoiceProfile = await SportnetApi.organizationInvoiceProfile(
      currentPPO.organization_id || '',
    );
    return normalizeEntities('PO', [invoiceProfile]);
  },
);

export const loadSectionTree = createAsync<
  {
    treelevel: number;
  },
  {
    entities: {
      sections: {
        [key: string]: SectionNode;
      };
    };
    data: {
      tree: SectionTreeType;
      sectionIdOrUniqId: string;
    };
  }
>(
  'LOAD_SECTION_TREE',
  async ({ treelevel }, dispatch, getState, { CmsApi, Api }) => {
    const appSpace: Readonly<PPO> = currentPPOSelector(getState());
    const activeCompetition = activeCompetitionSelector(getState());
    let homepageSectionIdOrUniqId: string =
      appSpace.homepageSectionIdOrUniqId || '';
    let competition;

    if (activeCompetition) {
      competition = await Api.getCompetitionById(
        appSpace._id || '',
        activeCompetition,
      );

      homepageSectionIdOrUniqId =
        String(competition && competition.homepageSectionIdOrUniqId
          ? competition.homepageSectionIdOrUniqId
          : '');
    }

    if (!homepageSectionIdOrUniqId) {
      return {
        entities: {
          sections: {},
        },
        data: {
          tree: [],
          sectionIdOrUniqId: homepageSectionIdOrUniqId || '',
        },
      };
    }
    const response = await CmsApi.getPublicSubsectionsByIdOrUniqId(
      config.APP_ID,
      appSpace._id || '',
      competition && competition.homepageSectionIdOrUniqId
        ? `competitionId|${competition._id}`
        : config.DEFAULT_CONTENT_DIVIDER,
      homepageSectionIdOrUniqId,
      {
        treelevel,
      },
    );

    const sectionsEntities: {
      [key: string]: SectionNode;
    } = {};

    const normalizeTree = (
      sections: SectionTreeType,
    ): INormalizedSectionTree => {
      return sections.map(section => {
        sectionsEntities[section._id!] = reduceSectionProps(section);

        const sectionNode: INormalizedSectionNode = {
          _id: section._id!,
        };

        if (section.sections && section.sections.length > 0) {
          const normalizedSections = normalizeTree(section.sections);
          sectionNode.sections = normalizedSections;
        }

        return sectionNode;
      });
    };

    const tree = normalizeTree(response.sections!); // @TODO csm/api optional type

    return {
      entities: {
        sections: sectionsEntities,
      },
      data: {
        tree,
        sectionIdOrUniqId: appSpace.homepageSectionIdOrUniqId || '',
      },
    };
  },
);
