import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import { useAsyncData } from 'ssr-service';
import { Section } from '../../api/CmsApi';
import { ReduxConnectProps } from '../../configureStore';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import Footer from '../../containers/Footer';
import Header from '../../containers/Header';
import Loading from '../../containers/Loading';
import { RouteProps } from '../../library/App';
import Page from '../../pages/Page';
import { RootState } from '../../rootReducer';
import styled from '../../theme/styled-components';
import { getPPOUrl, __ } from '../../utilities';
import getSectionUrl from '../../utilities/getSectionUrl';
import { competitionByIdSelector } from '../Competitions/selectors';
import { sectionTreeSelector } from '../UrlMap/selectors';
import { getSportSectorsPhases, getSportSectorsSettings, loadSectionTree, resetSectionTree } from './actions';
import { activeCompetitionSelector, currentPPOSelector, faviconSrcSelector, logoSrcSelector } from './selectors';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

export const Content = styled('div')`
  display: flex;
`;

const mapStateToProps = (
  state: RootState,
  props: RouteProps<{ competitionId?: string }>,
) => {
  const activeCompetition = activeCompetitionSelector(state);
  const { appSpace } = appSettingsSelector(state);
  const currentPPO = currentPPOSelector(state);
  const sectionIdOrUniqId = currentPPO.homepageSectionIdOrUniqId || '';

  return {
    currentPPO,
    appSpace,
    logo: logoSrcSelector(state),
    favicon: faviconSrcSelector(state),
    activeCompetition: activeCompetitionSelector(state),
    contentSections: sectionTreeSelector({ sectionIdOrUniqId, treelevel: 0 })(
      state,
    ),
    ...(!!activeCompetition
      ? {
        competition: competitionByIdSelector(activeCompetition)(state),
      }
      : { competition: null }),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = {} & IMapStateToProps &
  ReduxConnectProps &
  RouteProps<{ competitionId?: string }>;

const App: React.FC<Props> = ({
  dispatch,
  children,
  currentPPO,
  logo,
  competition,
  contentSections,
  favicon: propsFavicon,
  location,
  router,
  route,
  routeParams,
  params,
  routes,
  activeCompetition,
}) => {
  useAsyncData(async () => {
    await getInitialProps();
  }, [activeCompetition]);

  const [isFetching, setIsFetching] = React.useState(false);

  const getInitialProps = React.useCallback(async () => {
    setIsFetching(true);
    try {
      dispatch(resetSectionTree());
      await Promise.all([
        dispatch(getSportSectorsPhases.action()),
        dispatch(
          getSportSectorsSettings.action({
            settingSubId: 'competitionPartSettings',
          }),
        ),
        dispatch(
          loadSectionTree.action({
            treelevel: 0,
          }),
        ),
      ]);
    } catch (e) {
      //
    } finally {
      setIsFetching(false);
    }
  }, [dispatch]);

  const title = currentPPO.name || __('Súťaže');
  const favicon = getProp(
    competition,
    ['publication', 'logo', 'public_url'],
    propsFavicon,
  );

  if (isFetching) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Helmet titleTemplate={`%s | ${title}`}>
        <title>{title}</title>
        <meta
          property="og:url"
          content={`${getPPOUrl(currentPPO._id || '')}/contact`}
        />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        {logo && <meta property="og:image" content={logo} />}
        {/* <meta name="description" content="" /> */}
        {/* <meta name="twitter:description" content=""> */}
        <meta name="twitter:title" content={title} />
        {logo && <meta name="twitter:image" content={logo} />}
        <meta name="twitter:image:alt" content={title} />
        <meta name="twitter:card" content="summary_large_image" />
        {favicon && <link rel="shortcut icon" href={favicon} />}
      </Helmet>
      {!!competition ? (
        <>
          <Header
            logoSrc={
              competition.publication && competition.publication.logo
                ? competition.publication.logo.public_url
                : logo || ''
            }
            logoLink={`/competition-groups/${competition.competitionGroupId}?competitionId=${competition._id}`}
            location={location}
            params={params}
            route={route}
            router={router}
            routes={routes}
            routeParams={routeParams}
            // noShadow
            sections={[
              {
                name: __('Prehľad'),
                _id: `/competition-groups/${competition.competitionGroupId}`,
                url: `/competition-groups/${competition.competitionGroupId}?competitionId=${competition._id}`,
                root: true
              },
              // {
              //   name: __('Stretnutia'),
              //   _id: `/competition-groups/${competition.competitionGroupId}/competitions/${competition._id}/matches`,
              //   url: `/competition-groups/${competition.competitionGroupId}/competitions/${competition._id}/matches`,
              // },
              ...contentSections.map(s => ({
                name: s.name || '',
                _id: getSectionUrl(
                  `/competition/${competition._id}`,
                  s as Section,
                ),
                url: getSectionUrl(
                  `/competition/${competition._id}`,
                  s as Section,
                ),
              })),
              {
                name: __('Ďalšie súťaže'),
                _id: `https://${currentPPO.domain}` || '',
                url: `https://${currentPPO.domain}` || '',
                absolute: true,
              },
            ]}
          />
          {/* <CompetitionSubMenu
              competition={competition}
              logo={getProp(
                competition.publication || {},
                ['logo', 'public_url'],
                currentPPO.logo_public_url || '',
              )}
            /> */}
        </>
      ) : (
          <Header
            logoSrc={logo || ''}
            logoLink="/"
            location={location}
            params={params}
            route={route}
            router={router}
            routes={routes}
            routeParams={routeParams}
            sections={[
              {
                name: __('Súťaže'),
                _id: '/',
                url: `/`,
              },
              ...contentSections.map(s => ({
                name: s.name || '',
                _id: getSectionUrl('', s as Section),
                url: getSectionUrl('', s as Section),
              })),
            ]}
          />
        )}
      <Content>
        <Page>{children}</Page>
      </Content>
      <Footer />
    </Wrapper>
  );
};

export default compose<React.FC<any>>(
  withRouter,
  connect(mapStateToProps),
)(App);
