import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ArticleId, SectionId } from '../../library/App';
import * as actions from './actions';

export type UrlDetailByUrlState = Readonly<{
  [key: string]: Readonly<{
    isFetching: boolean;
    data: Readonly<{
      urltype: string;
      object_id: ArticleId | SectionId;
    }>;
    error: any;
  }>;
}>;

const URL_DETAIL_INITIAL_STATE: UrlDetailByUrlState = {};

export const urlDetailByUrlReducer = reducerWithInitialState(
  URL_DETAIL_INITIAL_STATE,
)
  .case(
    actions.loadByUrl.async.started,
    (state, params): UrlDetailByUrlState => ({
      ...state,
      [params.url]: {
        ...state[params.url],
        isFetching: true,
        error: null,
      },
    }),
  )
  .case(
    actions.loadByUrl.async.done,
    (state, { params, result }): UrlDetailByUrlState => ({
      ...state,
      [params.url]: {
        isFetching: false,
        data: result.data,
        error: null,
      },
    }),
  )
  .case(
    actions.loadByUrl.async.failed,
    (state, { params, error }): UrlDetailByUrlState => ({
      ...state,
      [params.url]: {
        ...state[params.url],
        isFetching: false,
        error,
      },
    }),
  );
