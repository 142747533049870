import { getTime } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import config from '../../../config';
import styled from '../../../theme/styled-components';
import { __ } from '../../../utilities';
import formatDate from '../../../utilities/formatDate';
import MaxWidthBox from '../../MaxWidthBox';

const Wrapper = styled('div')`
  color: ${({ theme }) => theme.contentMetaInformation.textColor};
  margin: ${rem(20)} 0 ${rem(15)} 0;
  display: flex;
  flex-wrap: wrap;
`;

const Day = styled('span')`
  text-transform: capitalize;
`;

const Pipe = styled('span')`
  margin: 0 ${rem(3)};
`;

const Modified = styled('div')``;

interface Props {
  createdDate: string | null;
  modifiedDate?: string | null;
}

const ContentMetaInformation: React.FC<Props> = ({
  createdDate,
  modifiedDate,
}) => {
  let createDateElement = null;
  let modifiedDateElement = null;
  let createdDateInUnix = 0;
  let modifiedDateInUnix = 0;

  if (createdDate) {
    createDateElement = formatDate(createdDate, config.CREATED_DATE_FORMAT);
    createdDateInUnix = getTime(createdDate);
  }

  if (modifiedDate) {
    modifiedDateElement = `${__('Posledná aktualizácia')} ${formatDate(
      modifiedDate,
      config.DATE_TIME_FORMAT,
    )}`;
    modifiedDateInUnix = getTime(modifiedDate);
  }

  if (modifiedDateInUnix - createdDateInUnix < 2) {
    return (
      <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
        <Wrapper>{createDateElement && <Day>{createDateElement}</Day>}</Wrapper>
      </MaxWidthBox>
    );
  }

  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <Wrapper>
        <div>
          {createDateElement && <Day>{createDateElement}</Day>}
          {modifiedDateElement && createDateElement ? <Pipe>|</Pipe> : null}
        </div>
        <Modified>{modifiedDateElement}</Modified>
      </Wrapper>
    </MaxWidthBox>
  );
};

export default ContentMetaInformation;
