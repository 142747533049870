import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { NormalizedEntities } from '../../library/App';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';

const create = actionCreatorFactory(config.MATCHES_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const getMatchById = createAsync<
  {
    matchId: string;
  },
  NormalizedEntities<'MATCHES'>
>('GET_MATCH_BY_ID', async (parameters, dispatch, getState, { Api }) => {
  const match = await Api.getMatch(parameters.matchId);
  return normalizeEntities('MATCHES', [match]);
});
