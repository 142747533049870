import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { SectionPublicDetail } from '../../api/CmsApi';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { updateEntities } from '../../containers/App/actions';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import { NormalizedEntities, Pager } from '../../library/App';
import { SectionId } from '../../library/CMS';
import NotFoundError from '../../NotFoundError';
import { RootState } from '../../rootReducer';
import normalizeEntities from '../../utilities/normalizeEntities';
import reduceSectionProps from '../../utilities/reduceSectionProps';
import { loadPath } from '../UrlMap/actions';

const create = actionCreatorFactory('SECTION');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadSection = createAsync<
  { idOrUniqId: string; contentDivider: string },
  SectionPublicDetail
>(
  'LOAD_SECTION',
  async ({ idOrUniqId, contentDivider }, dispatch, getState, { CmsApi }) => {
    const { appSpace } = appSettingsSelector(getState());
    try {
      const response = await CmsApi.getPublicSectionByIdOrUniqId(
        config.APP_ID,
        appSpace,
        contentDivider,
        idOrUniqId,
        {
          expandWidgets: true,
        },
      );
      await dispatch(loadPath.action({ id: response._id!, contentDivider }));
      const { entities } = normalizeEntities('sections', [
        reduceSectionProps(response as any),
      ]); // @TODO publication_groups má zlý typ, počkať na Mišov fix
      dispatch(updateEntities(entities));
      return response;
    } catch (e) {
      throw new NotFoundError(e);
    }
  },
);

export const loadSectionArticles = createAsync<
  { sectionId: SectionId; contentDivider: string },
  Pick<NormalizedEntities<'articles'>, 'results'> & Pager
>(
  'LOAD_SECTION_ARTICLES',
  async ({ sectionId, contentDivider }, dispatch, getState, { CmsApi }) => {
    const { appSpace } = appSettingsSelector(getState());
    const {
      articles,
      limit,
      offset,
      total,
    } = await CmsApi.getPublicArticlesBySectionId(
      config.APP_ID,
      appSpace,
      contentDivider,
      sectionId,
      {
        limit: config.LIST_SECTION_ARTICLES_LIMIT,
        offset: 0,
      },
    );
    const { entities, results } = normalizeEntities('articles', articles!); // should be required
    dispatch(updateEntities(entities));

    return {
      results,
      limit: limit!,
      offset: offset!,
      total: total!,
    };
  },
);

// Set current article id
export const setCurrentSectionId = create<SectionId | null>(
  'SET_CURRENT_SECTION_ID',
);
