import { getListResults } from 'redux-list';
import { createSelector } from 'reselect';
import { at } from '../../utilities';
import { entitiesSelector } from '../App/selectors';

export const competitionMatchesSelector = (listName: string) =>
  createSelector(
    entitiesSelector,
    getListResults(listName),
    (entities, ids) => at(entities.MATCHES, ids || []),
  );

export const sportSectorsSettingsSelector = () =>
  createSelector(
    entitiesSelector,
    entities => entities.SPORT_SECTOR_SETTINGS,
  );

export const sportSectorSettingsSelector = (sportSector: string) =>
  createSelector(
    entitiesSelector,
    entities => entities.SPORT_SECTOR_SETTINGS[sportSector],
  );

export const sportSectorsPhasesSelector = () =>
  createSelector(
    entitiesSelector,
    entities => entities.SPORT_SECTOR_PHASES,
  );

export const sportSectorsDelegatesSelector = () =>
  createSelector(
    entitiesSelector,
    entities => entities.SPORT_SECTOR_DELEGATES,
  );

export const sportSectorsCrewSelector = () =>
  createSelector(
    entitiesSelector,
    entities => entities.SPORT_SECTOR_CREW,
  );

export const sportSectorEventsSelector = (sportSector: string) =>
  createSelector(
    entitiesSelector,
    entities => entities.SPORT_SECTOR_EVENTS[sportSector],
  );
