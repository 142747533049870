import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import { setActiveCompetition } from '../../pages/App/actions';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import { Content } from '../../api/CmsApi';
import ArticlesList from '../../components/CMS/ArticlesList';
import Widgets from '../../components/CMS/Widgets';
import EntryAnimation from '../../components/EntryAnimation';
import HorizontalSpacer from '../../components/HorizontalSpacer';
import Loader from '../../components/Loader';
import { ReduxConnectProps } from '../../configureStore';
import ContentHeader from '../../containers/CMS/ContentHeader';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import { ArticleId } from '../../library/CMS';
import Page from '../../pages/Page';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities';
import getIdFromProps from '../../utilities/getIdFromProps';
import getSectionUrl from '../../utilities/getSectionUrl';
import Forbidden from '../Forbidden';
import NotFound from '../NotFound';
import Unauthorized from '../Unauthorized';
import {
  loadSection,
  loadSectionArticles,
  setCurrentSectionId,
} from './actions';
import {
  currentSectionArticlesIsFetchingSelector,
  currentSectionArticlesSelector,
  currentSectionErrorSelector,
  currentSectionIsFetchingSelector,
  currentSectionSelector,
} from './selectors';

interface OwnProps {
  id: ArticleId;
  contentDivider: string;
}

type RouteProps = RouteComponentProps<{ competitionId?: string }, any>;

const mapStateToProps = (state: RootState, props: OwnProps & RouteProps) => {
  return {
    section: currentSectionSelector(state),
    error: currentSectionErrorSelector(state),
    isFetching: currentSectionIsFetchingSelector(state),
    sectionArticles: currentSectionArticlesSelector(state),
    sectionArticlesIsFetching: currentSectionArticlesIsFetchingSelector(state),
    appSettings: appSettingsSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & RouteProps & IMapStateToProps & ReduxConnectProps;

class Section extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const {
      dispatch,
      contentDivider,
      params: { competitionId },
    } = props;

    const sectionId = getIdFromProps(props);
    dispatch(setCurrentSectionId(sectionId));
    if (competitionId) {
      dispatch(setActiveCompetition(competitionId));
    }
    await Promise.all([
      dispatch(
        loadSection.action({ idOrUniqId: String(sectionId), contentDivider }),
      ),
      dispatch(loadSectionArticles.action({ sectionId, contentDivider })),
    ]);
  }

  async componentDidMount() {
    try {
      await Section.getInitialProps(this.props);
    } catch (e) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      try {
        await Section.getInitialProps(this.props);
      } catch (e) {
        /* */
      }
    }
  }

  render() {
    const {
      section,
      isFetching,
      appSettings,
      sectionArticles,
      error,
    } = this.props;
    if (isFetching && section === null) {
      return <Loader />;
    }
    if (error && error.identifier === 'FORBIDDEN') {
      return <Forbidden />;
    }
    if (error && error.identifier === 'UNAUTHORIZED') {
      return <Unauthorized />;
    }
    if (section === null) {
      return <NotFound />;
    }

    const sectionIcon = section.icon ? (section.icon as any) : null;
    const sectionContentPicture = section.picture
      ? (section.picture as any)
      : null;

    return (
      // <App>
      <Page>
        <EntryAnimation key={getIdFromProps(this.props).toString()}>
          <Helmet>
            <title>{section.name}</title>
            <meta
              property="og:url"
              content={getSectionUrl(appSettings.baseUri || '', section)}
            />
            <meta property="og:title" content={section.name} />
            <meta property="og:type" content="section" />
            {sectionContentPicture && (
              <meta
                property="og:image"
                content={getMediaManagerUrl(
                  sectionContentPicture,
                  1000,
                  0,
                  'resize',
                )}
              />
            )}
          </Helmet>
          <ContentHeader
            picture={
              sectionIcon
                ? getMediaManagerUrl(sectionIcon, 50, 50, 'resize')
                : null
            }
            title={section.name || ''}
          />
          <Widgets items={getProp(section, ['content'], []) as Content} />
          <HorizontalSpacer height={35} />
          <ArticlesList
            title={__('Ďalšie články v sekcii')}
            articles={sectionArticles}
          />
        </EntryAnimation>
      </Page>
      // </App>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps))(Section) as any;
