import FormField from '@sportnet/ui/lib/FormField';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import { ContentLoader } from '@sportnet/ui/lib/Loader';
import Segment from '@sportnet/ui/lib/Segment';
import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { isAfter, isBefore } from 'date-fns';
import { em, rem } from 'polished';
import connectToQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  commit,
  getListParameters,
  getListTotal,
  isCommiting,
} from 'redux-list';
import { getProp } from 'sportnet-utilities';
import { connectSsr } from 'ssr-service';
import styled, { css, withTheme } from 'styled-components';
import CompetitionDraw from '../../components/CompetitionDraw';
import EntryAnimation from '../../components/EntryAnimation';
import Loader from '../../components/Loader';
import PlayersLadder from '../../components/PlayersLadder';
import { ReduxConnectProps } from '../../configureStore';
import CompetitionPartTable from '../../containers/CompetitionPartTable';
import MatchesList from '../../containers/MatchesList';
import { RouteProps } from '../../library/App';
import {
  COMPETITION_PARTS_LIST_NAME,
  COMPETITION_PART_MATCHES_LIST_NAME,
} from '../../pages/CompetitionMatches';
import {
  getCompetitionPartById,
  getCompetitionParts,
  getCompetitionsPartMatchesList,
} from '../../pages/CompetitionMatches/actions';
import { competitionPartsSelector } from '../../pages/CompetitionMatches/selectors';
import { RootState } from '../../rootReducer';
import { getPPOUrl, __ } from '../../utilities';
import {
  initializeOrSetListParams,
  setActiveCompetition,
} from '../App/actions';
import { currentPPOSelector, domainPayloadSelector } from '../App/selectors';
import { sportSectorsSettingsSelector } from '../Competition/selectors';
import { HeaderWrapper, StyledHeaderL, StyledHeaderXL } from '../Competitions';
import {
  getCompetitionById,
  getCompetitionGroupById,
} from '../Competitions/actions';
import { competitionByIdSelector } from '../Competitions/selectors';
import {
  competitionGroupSelector,
  competitionMatchesSelector,
  sportSectorsPhasesSelector,
} from './selectors';

export const LIST_LIMIT = 12;

const OverflowPanel = styled.div`
  overflow-x: scroll;
  width: 100%;
  padding: ${em(15)} 0;
`;
const PartsWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`;
const PartButton = styled.div<{ active?: boolean }>`
  white-space: nowrap;
  padding: ${em(10)};
  margin: 0 ${em(5)};
  background: #fff;
  font-size: 0.875rem;
  color: #555;
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background: #eee;
  }
  ${({ active, theme }) =>
    active &&
    css`
      background: ${theme.color.primary} !important;
      color: white;
    `}
`;

const SeasonSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${em(15)};
  ${mb('s')} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
  }
`;

const FormFieldWrapper = styled('div')`
  div {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  select {
    min-width: ${em(150)};
  }
`;

type Props = RouteProps<{ competitionGroupId: string }> &
  ReduxConnectProps &
  Theme &
  QueryHocInterface;

const mapStateToProps = (state: RootState, props: Props) => {
  const parameters = getListParameters(COMPETITION_PART_MATCHES_LIST_NAME)(
    state,
  );
  return {
    sportSectorsPhases: sportSectorsPhasesSelector()(state),
    sportSectorsSettings: sportSectorsSettingsSelector()(state),
    competition: competitionByIdSelector(parameters.competitionId)(state),
    competitionGroup: competitionGroupSelector(parameters.competitionGroupId)(
      state,
    ),
    listParams: getListParameters(COMPETITION_PART_MATCHES_LIST_NAME)(state),
    competitionParts: competitionPartsSelector()(state),
    competitionPartMatches: competitionMatchesSelector(
      COMPETITION_PART_MATCHES_LIST_NAME,
    )(state),
    competitionPartMatchesTotal: getListTotal(
      COMPETITION_PART_MATCHES_LIST_NAME,
    )(state),
    competitionPartMatchesIsFetching: isCommiting(
      COMPETITION_PART_MATCHES_LIST_NAME,
    )(state),
    competitionPartIsFetching: isCommiting(COMPETITION_PARTS_LIST_NAME)(state),
    currentPPO: currentPPOSelector(state),
    domainPayload: domainPayloadSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

class Competition extends React.PureComponent<Props & IMapStateToProps> {
  static async getInitialProps(props: Props & IMapStateToProps, offset = 0) {
    const { dispatch } = props;

    let params = {
      competitionId:
        props.query && props.query.competitionId
          ? String(props.query.competitionId)
          : '',
      competitionGroupId: props.params.competitionGroupId,
      partId:
        props.query && props.query.partId ? String(props.query.partId) : '',
      roundId:
        props.query && props.query.roundId ? String(props.query.roundId) : '',
    };
    if (props.domainPayload && props.domainPayload.competitionId) {
      params = {
        ...params,
        competitionId:
          String(
            props.query.competitionId || props.domainPayload.competitionId,
          ) || '',
        competitionGroupId:
          String(
            props.params.competitionGroupId ||
              props.domainPayload.competitionGroupId,
          ) || '',
      };
    }
    dispatch(
      initializeOrSetListParams.action({
        listName: COMPETITION_PART_MATCHES_LIST_NAME,
        params,
      }),
    );

    const groups = await dispatch(
      getCompetitionGroupById.action({
        competitionGroupId: params.competitionGroupId,
      }),
    );
    if (!params.competitionId) {
      let competitions = getProp(groups, [
        'entities',
        'COMPETITION_GROUPS',
        params.competitionGroupId,
        'competitions',
      ]);
      if (competitions) {
        const recentCompetition = competitions.find(
          (competition: any) =>
            isBefore(new Date(competition.season.dateFrom), new Date()) &&
            isAfter(new Date(competition.season.dateTo), new Date()),
        );
        if (recentCompetition) {
          params.competitionId = recentCompetition._id;
        } else {
          competitions = competitions.sort((a: any, b: any) => {
            if (isAfter(new Date(a.season.dateTo), new Date(b.season.dateTo))) {
              return -1;
            }
            return 1;
          });
          if (
            competitions.length > 1 &&
            isAfter(new Date(competitions[0].season.dateFrom), new Date())
          ) {
            params.competitionId = competitions[1]._id;
          } else {
            params.competitionId = competitions[0]._id;
          }
        }
      }
    }
    const { items: parts } = await dispatch(
      getCompetitionParts.action({
        competitionId: params.competitionId,
      }),
    );

    if (!params.partId) {
      const sortedParts = parts.sort((a, b) =>
        isAfter(a.dateFrom, b.dateFrom) ? -1 : 1,
      );
      const anyActive = sortedParts.find(
        p => isAfter(p.dateFrom, new Date()) && isBefore(p.dateTo, new Date()),
      );
      if (anyActive) {
        params.partId = anyActive._id;
      } else {
        const finishedParts = sortedParts.filter(p =>
          isAfter(p.dateFrom, new Date()),
        );
        if (finishedParts.length) {
          params.partId = finishedParts[finishedParts.length - 1]._id;
        }
      }
      if (!params.partId) {
        params.partId = parts[0]._id;
      }
    }

    const activePart = parts.find(p => p._id === params.partId);
    if (activePart && !params.roundId) {
      const rounds = activePart.rounds || [];
      const sortedRounds = rounds.sort((a, b) =>
        isAfter(a.dateFrom, b.dateFrom) ? -1 : 1,
      );
      const anyActive = sortedRounds.find(
        p => isAfter(p.dateFrom, new Date()) && isBefore(p.dateTo, new Date()),
      );
      if (anyActive) {
        params.roundId = anyActive._id;
      } else {
        const finishedRounds = sortedRounds.filter(p =>
          isAfter(p.dateFrom, new Date()),
        );
        if (finishedRounds.length) {
          params.roundId = finishedRounds[finishedRounds.length - 1]._id;
        }
      }
      if (!params.roundId && rounds.length) {
        params.roundId = rounds[0]._id;
      }
    }

    dispatch(
      initializeOrSetListParams.action({
        listName: COMPETITION_PART_MATCHES_LIST_NAME,
        params,
      }),
    );
    if (offset === 0) {
      await dispatch(
        commit.action({
          listName: COMPETITION_PART_MATCHES_LIST_NAME,
          load: async () => ({
            total: 0,
            results: [],
          }),
        }),
      );
    }
    dispatch(setActiveCompetition(params.competitionId));
    await Promise.all([
      dispatch(getCompetitionById.action(params)),
      dispatch(getCompetitionPartById(params)),
      dispatch(
        getCompetitionsPartMatchesList({
          competitionId: params.competitionId,
          partId: params.partId,
          roundId:
            activePart && activePart.format !== 'draw' ? params.roundId : '',
          offset,
        }),
      ),
    ]);
  }

  state = {
    offset: 0,
    endReached: false,
  };

  interval: any = null;

  checkExist = () => {
    clearInterval(this.interval);
    if (
      this.props.listParams.partId &&
      document.getElementById(`partbutton_${this.props.listParams.partId}`)
    ) {
      this.scrollPartIntoView();
    } else {
      this.interval = setTimeout(() => {
        this.checkExist();
      }, 100);
    }
  };

  componentDidMount() {
    this.checkExist();
  }

  componentDidUpdate(prevProps: Props & IMapStateToProps) {
    if (prevProps.listParams.partId !== this.props.listParams.partId) {
      this.checkExist();
    }
  }

  scrollPartIntoView(props = this.props) {
    const {
      listParams: { partId },
    } = props;
    if (partId) {
      const el = document.getElementById(`partbutton_${partId}`);

      if (el) {
        const parent = el.parentNode as any;
        if (parent) {
          const w = (parent.offsetWidth - el.offsetWidth) / 2;
          if (parent.parentNode) {
            parent.parentNode.scrollLeft = el.offsetLeft - 15 - w;
          }
        }
      }
    }
  }

  onEndOfPanelReached = async () => {
    if (
      !this.state.endReached &&
      this.props.competitionPartMatches.length !==
        this.props.competitionPartMatchesTotal
    ) {
      const newOffset = this.state.offset + LIST_LIMIT;
      await Competition.getInitialProps(this.props, newOffset);
      this.setState({
        offset: newOffset,
      });
      if (
        this.props.competitionPartMatches.length ===
        this.props.competitionPartMatchesTotal
      ) {
        this.setState({
          endReached: true,
        });
      }
    }
  };

  changeCompetition = (competitionId: string) => {
    this.props.setParameter({
      competitionId,
      partId: '',
    });
  };

  getStatsByStatName = (statName: string) => {
    const activePart =
      this.props.competitionParts.find(
        p => p._id === this.props.listParams.partId,
      ) || this.props.competition;
    const results = activePart.resultsTable
      ? activePart.resultsTable.players
      : [];
    const sorted = (results || []).sort((a, b) => {
      if (a.stats[statName] > b.stats[statName]) {
        return -1;
      } else if (a.stats[statName] < b.stats[statName]) {
        return 1;
      }
      return 0;
    });
    const limited = [...sorted].slice(0, 5).filter(i => i.stats[statName] > 0);
    return limited.map(i => {
      const team = (activePart.teams || []).find(t => t._id === i.teamId);
      return { ...i, team };
    });
  };

  onChangeParameters = (name: string, option: { value: string }) => {
    this.props.setParameter({
      offset: 0,
      [name]: option.value,
    });
  };

  changePart = (partName: string) => {
    const part = this.props.competitionParts.find(p => p.name === partName);
    if (part && part._id !== this.props.listParams.partId) {
      this.props.setParameter({
        offset: 0,
        partId: part._id,
        roundId: '',
      });
    }
  };

  render() {
    const {
      competition,
      competitionGroup,
      competitionPartMatches,
      currentPPO,
      theme,
      competitionPartMatchesIsFetching,
      competitionPartIsFetching,
      sportSectorsPhases,
      sportSectorsSettings,
      competitionParts,
      listParams: { partId, roundId },
    } = this.props;

    if (!competition) {
      return <ContentLoader size="xl" theme={theme} />;
    }

    const goals = this.getStatsByStatName('goals');
    const ownGoals = this.getStatsByStatName('own_goals');
    const assists = this.getStatsByStatName('assists');
    const minutes = this.getStatsByStatName('minutes');
    const yellowCards = this.getStatsByStatName('yellow_cards');
    const redCards = this.getStatsByStatName('red_cards');
    const matchStarts = this.getStatsByStatName('match_starts');
    const substitutions = this.getStatsByStatName('substitutions');

    const activePart = competitionParts.find(p => p._id === partId);
    const activeRound =
      activePart &&
      roundId &&
      (activePart.rounds || []).find(r => r._id === roundId);

    if (competition) {
      const title = competition.name;
      return (
        // <App>
        <EntryAnimation key={'Competitions'}>
          <Helmet titleTemplate="%s">
            <title>{title}</title>
            <meta
              property="og:url"
              content={`${getPPOUrl(currentPPO._id || '')}/competitions/${
                this.props.query.competitionId
              }`}
            />
            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <meta name="twitter:title" content={title} />
          </Helmet>
          <SeasonSelectWrapper>
            <HeaderWrapper>
              <StyledHeaderXL>{title}</StyledHeaderXL>
              <StyledHeaderL>{__('Prehľad súťaže')}</StyledHeaderL>
            </HeaderWrapper>
            <FormFieldWrapper>
              {!!competitionGroup && (
                <FormField
                  label={__('Sezóna')}
                  type="theselect"
                  clearable={false}
                  value={{
                    value: competition._id,
                    label: competition.season.name,
                  }}
                  onChange={(i: { value: string }) => {
                    if (i && i.value) {
                      this.changeCompetition(i.value);
                    }
                  }}
                  options={competitionGroup.competitions
                    .sort((a, b) => {
                      if (
                        isAfter(
                          new Date(a.season.dateFrom),
                          new Date(b.season.dateFrom),
                        )
                      ) {
                        return -1;
                      }
                      return 1;
                    })
                    .map(c => ({
                      value: c._id,
                      label: c.season.name,
                    }))}
                />
              )}
            </FormFieldWrapper>
          </SeasonSelectWrapper>
          {/* <Tabber
            items={this.props.competitionParts.map(p => p.name)}
            onClickItem={this.changePart}
            active={activePart && activePart.name}
          /> */}
          <OverflowPanel>
            <PartsWrapper>
              {this.props.competitionParts
                .filter(p => p.competitionId === competition._id)
                .map(p => {
                  return (
                    <PartButton
                      onClick={() => {
                        this.changePart(p.name);
                      }}
                      active={activePart && p._id === activePart._id}
                      id={`partbutton_${p._id}`}
                    >
                      {p.name}
                    </PartButton>
                  );
                })}
            </PartsWrapper>
          </OverflowPanel>

          {competitionPartMatchesIsFetching || competitionPartIsFetching ? (
            <Loader />
          ) : (
            <>
              {activePart && activePart.format === 'points' && (
                <>
                  <>
                    <div style={{ height: rem(15) }} />
                    {!!getProp(activePart, ['rounds'], []).length && (
                      <FormField
                        value={
                          activeRound
                            ? {
                                label: activeRound.name,
                                value: activeRound._id,
                              }
                            : {}
                        }
                        type="theselect"
                        name="roundId"
                        label={__('Kolo')}
                        onChange={(i: { value: string }) => {
                          if (i && i.value) {
                            this.onChangeParameters('roundId', i);
                          }
                        }}
                        parse={(item: any) => {
                          if (!item) {
                            return '';
                          }
                          const option = (activePart
                            ? activePart.rounds || []
                            : []
                          ).find(o => o && o.name === item.value);

                          if (!option) {
                            return '';
                          }
                          return option;
                        }}
                        options={(activePart
                          ? activePart.rounds || []
                          : []
                        ).map(round => ({
                          label: round.name,
                          value: round._id,
                        }))}
                      />
                    )}
                    {(!!competitionPartMatchesIsFetching ||
                      !!competitionPartMatches.length) && (
                      <MatchesList
                        matches={competitionPartMatches}
                        isFetching={competitionPartMatchesIsFetching}
                        sportSectorsPhases={sportSectorsPhases}
                        sportSectorsSettings={sportSectorsSettings}
                      />
                    )}
                  </>
                  <CompetitionPartTable
                    logoSrc={competition.publication.logo}
                    teams={activePart.teams || []}
                    resultsTable={
                      activePart.resultsTable
                        ? activePart.resultsTable.results
                        : []
                    }
                  />
                </>
              )}
              {activePart && activePart.format === 'draw' && (
                <Segment raised>
                  <CompetitionDraw
                    theme={theme}
                    data={{
                      data: {
                        competitionPart: activePart,
                        matches: competitionPartMatches,
                      },
                    }}
                  />
                </Segment>
              )}
              <Row>
                {goals.length > 0 && (
                  <Col xs={12} s={6} l={3}>
                    <PlayersLadder
                      header={__('Najlepší strelci')}
                      statName="goals"
                      items={goals}
                    />
                  </Col>
                )}
                {assists.length > 0 && (
                  <Col xs={12} s={6} l={3}>
                    <PlayersLadder
                      header={__('Gólové prihrávky')}
                      statName="assists"
                      items={assists}
                    />
                  </Col>
                )}
                {ownGoals.length > 0 && (
                  <Col xs={12} s={6} l={3}>
                    <PlayersLadder
                      header={__('Vlastné góly')}
                      statName="own_goals"
                      items={ownGoals}
                    />
                  </Col>
                )}
                {matchStarts.length > 0 && (
                  <Col xs={12} s={6} l={3}>
                    <PlayersLadder
                      header={__('Štarty v základnej zostave')}
                      statName="match_starts"
                      items={matchStarts}
                    />
                  </Col>
                )}
                {substitutions.length > 0 && (
                  <Col xs={12} s={6} l={3}>
                    <PlayersLadder
                      header={__('Počet vystriedaní hráča')}
                      statName="substitutions"
                      items={substitutions}
                    />
                  </Col>
                )}
                {minutes.length > 0 && (
                  <Col xs={12} s={6} l={3}>
                    <PlayersLadder
                      header={__('Odohrané minúty')}
                      statName="minutes"
                      items={minutes}
                    />
                  </Col>
                )}
                {yellowCards.length > 0 && (
                  <Col xs={12} s={6} l={3}>
                    <PlayersLadder
                      header={__('Žlté karty')}
                      statName="yellow_cards"
                      items={yellowCards}
                    />
                  </Col>
                )}
                {redCards.length > 0 && (
                  <Col xs={12} s={6} l={3}>
                    <PlayersLadder
                      header={__('Červené karty')}
                      statName="red_cards"
                      items={redCards}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
        </EntryAnimation>
        // </App>
      );
    }
    return null;
  }
}

export default compose<React.FC<Props>>(
  connect(mapStateToProps),
  connectToQueryHoc({
    parameters: {
      competitionId: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
    },
  }),
  connectSsr(),
  withTheme,
)(Competition);
