import { em } from 'polished';
import styled from 'styled-components';

export const Dots = styled.ul`
  && {
    position: static;
    text-align: center;
  }
  & > li {
    text-align: center;
    vertical-align: middle;
    margin: 0;
  }
  & > li.slick-active > div:before {
    background: ${({ theme }) => theme.color.primary};
    width: ${em(12)};
    height: ${em(12)};
  }
`;

export const Dot = styled.div<{ active: boolean }>`
  margin: 0 ${em(3)};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    display: block;
    background: #dbdbdb;
    border-radius: 50%;
    width: ${em(8)};
    height: ${em(8)};
    transition-property: background-color, width, height;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }
`;
