import Input from '@sportnet/ui/lib/Input';
import debounce from 'lodash.debounce';
import * as React from 'react';
import { __ } from '../../utilities';

interface OwnProps {
  setParameter: (parameters: { [key: string]: any }) => void;
  q: string;
  loading: boolean;
}

type Props = OwnProps;
type State = {
  q: string;
};

const DEBOUNCE = 300;

class SearchFilter extends React.PureComponent<Props, State> {
  commitChangeSearch = debounce(this.props.setParameter, DEBOUNCE);

  constructor(props: Props) {
    super(props);
    this.state = {
      q: this.props.q,
    };
  }

  handleChangeSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const q = (e.target as HTMLInputElement).value;
    this.setState({ q });
    this.commitChangeSearch({ q });
  };

  render() {
    const { loading } = this.props;
    const { q } = this.state;
    return (
      <Input
        placeholder={__('Hľadať...')}
        value={q}
        onChange={this.handleChangeSearch}
      >
        <input />
        {loading ? <Input.Loading /> : <Input.Icon name="search" />}
      </Input>
    );
  }
}

export default SearchFilter;
