import * as React from 'react';
import { Route, Router } from 'react-router';
import { Page as CMSPage } from './components/CMS/Layout';
import DomainResolver from './containers/DomainResolver';
import DomainRoutes from './DomainRoutes';
import App from './pages/App';
import Article from './pages/Article';
import CompetitionGroup from './pages/CompetitionGroup';
import CompetitionMatch from './pages/CompetitionMatch';
import CompetitionMatches from './pages/CompetitionMatches';
import Section from './pages/Section';
import Themer from './pages/Themer';
import UrlMap from './pages/UrlMap';

const Routes: React.FC<any> = props => {
  return (
    <Router {...props}>
      <Route component={DomainResolver}>
        <Route component={Themer}>
          <Route component={App}>
            <Route path="/" component={DomainRoutes} />
            <Route
              path="/competition-groups/:competitionGroupId"
              component={CompetitionGroup}
            />
            <Route
              path="/competition-groups/:competitionGroupId/competitions/:competitionId/matches"
              component={CompetitionMatches}
            />
            <Route path="/matches/:matchId" component={CompetitionMatch} />
            <Route component={CMSPage}>
              <Route
                path="competition/:competitionId/article/:id"
                component={Article}
              />
              <Route
                path="competition/:competitionId/section/:id"
                component={Section}
              />
              <Route path="competition/:competitionId/*" component={UrlMap} />
              <Route path="article/:id" component={Article} />
              <Route path="section/:id" component={Section} />
              <Route path="*" component={UrlMap} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;
