import { rem } from 'polished';
import * as React from 'react';
import { Article as ArticleType } from '../../../api/CmsApi';
import config from '../../../config';
import styled from '../../../theme/styled-components';
import MaxWidthBox from '../../MaxWidthBox';
import Articles from '../Articles';
import ContentSectionHeader from '../ContentSectionHeader';

const Wrapper = styled('div')`
  background-color: ${({ theme }) => theme.articleList.bgColor};
  padding: ${rem(30)} 0 ${rem(70)} 0;
`;

const CenteredContentSectionHeader = styled(ContentSectionHeader)`
  text-align: center;
  margin-bottom: ${rem(30)};
`;

type OwnProps = {
  title: string;
  articles: ArticleType[];
};

const ArticlesList: React.FC<OwnProps> = ({ articles, title }) => {
  if (articles.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
        <CenteredContentSectionHeader>{title}</CenteredContentSectionHeader>
      </MaxWidthBox>
      <Articles articles={articles} />
    </Wrapper>
  );
};

export default ArticlesList;
