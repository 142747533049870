import { rem } from 'polished';
import * as React from 'react';
import config from '../../../config';
import styled from '../../../theme/styled-components';
import MaxWidthBox from '../../MaxWidthBox';

const Wrapper = styled('div')`
  margin: ${rem(25)} 0 0 0;
  color: ${({ theme }) => theme.author.textColor};
  text-align: right;
  font-style: italic;
`;

interface OwnProps {
  name: string | null;
}

type Props = OwnProps;

const Author: React.FC<Props> = ({ name }) => {
  if (!name) {
    return null;
  }

  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <Wrapper>{name}</Wrapper>
    </MaxWidthBox>
  );
};

export default Author;
