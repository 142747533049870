import Image from '@sportnet/ui/lib/Image';
import { emphasizeColor } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import { IMatch, ITeam } from '../../library/Match';

export const SEGMENT_HEIGHT = 60;

const SegmentMatchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .active {
    background: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => emphasizeColor(1, theme.color.primary)};
  }
`;

const SegmentRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${rem(11)};
  align-items: center;
  padding: 0 ${rem(8)};
  height: ${rem(SEGMENT_HEIGHT / 2)};
`;

const MatchesResults = styled.div`
  align-items: center;
  display: flex;
  font-weight: bold;
  & > div {
    padding: ${`0 ${rem(3)}`};
  }
  a {
    color: inherit;
  }
`;

const SegmentTeam = styled.div`
  display: flex;
  width: ${rem(SEGMENT_HEIGHT * 4 - 65)};
  img {
    min-width: ${rem(15)};
    margin-right: ${rem(10)};
  }
`;

const SegmentTeamName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

interface IOwnProps {
  segmentMatches: IMatch[];
  homeTeam?: ITeam;
  awayTeam?: ITeam;
}

const SegmentMatches: React.FC<IOwnProps> = ({
  segmentMatches,
  homeTeam,
  awayTeam,
}) => {
  const onClubMouseOver = (clubId: string) =>
    Array.from(document.getElementsByClassName(clubId)).forEach(c =>
      c.classList.add('active'),
    );

  const onClubMouseOut = (clubId: string) =>
    Array.from(document.getElementsByClassName(clubId)).forEach(c =>
      c.classList.remove('active'),
    );

  const renderSegmentRow = (team?: ITeam, opposingTeam?: ITeam) => {
    return (
      <SegmentRow
        onMouseOver={() => team && onClubMouseOver(`club_${team._id}`)}
        onMouseOut={() => team && onClubMouseOut(`club_${team._id}`)}
        className={team && `club_${team._id}`}
      >
        <SegmentTeam>
          <Image
            alt={team && team.displayName}
            src={(team && team.organization.logo_public_url) || ''}
            width={15}
            height={15}
          />
          <SegmentTeamName>{team && team.displayName}</SegmentTeamName>
        </SegmentTeam>
        <MatchesResults>
          {segmentMatches.map(m => {
            let teamGoals =
              m.protocol &&
              (m.protocol.events || []).reduce((acc: number, e: any) => {
                if (
                  team &&
                  e.team === team._id &&
                  e.eventType === 'goal' &&
                  e.type !== 'dropped' &&
                  (e.type !== 'goal_shootout' ||
                    m.rules.sport_sector === 'water-polo')
                ) {
                  return acc + 1;
                } else if (
                  opposingTeam &&
                  e.team === opposingTeam._id &&
                  e.eventType === 'goal' &&
                  e.type === 'dropped' &&
                  (e.type !== 'goal_shootout' ||
                    m.rules.sport_sector === 'water-polo')
                ) {
                  return acc + 1;
                }
                return acc;
              }, 0);
            const teamPenaltyGoals =
              m.protocol &&
              (m.protocol.events || []).reduce((acc: number, e: any) => {
                if (
                  team &&
                  e.team === team._id &&
                  e.eventType === 'goal' &&
                  e.type === 'goal_shootout'
                ) {
                  return acc + 1;
                }
                return acc;
              }, 0);
            const opposingTeamPenaltyGoals =
              m.protocol &&
              (m.protocol.events || []).reduce((acc: number, e: any) => {
                if (
                  opposingTeam &&
                  e.team === opposingTeam._id &&
                  e.eventType === 'goal' &&
                  e.type === 'goal_shootout'
                ) {
                  return acc + 1;
                }
                return acc;
              }, 0);
            if ((teamPenaltyGoals || 0) > (opposingTeamPenaltyGoals || 0)) {
              teamGoals = (teamGoals || 0) + 1;
            }
            return <div key={m._id}>{teamGoals}</div>;
          })}
        </MatchesResults>
      </SegmentRow>
    );
  };

  const content = (
    <SegmentMatchesWrapper>
      {renderSegmentRow(homeTeam, awayTeam)}
      {renderSegmentRow(awayTeam, homeTeam)}
    </SegmentMatchesWrapper>
  );

  if (segmentMatches.length === 1) {
    return <Link to={`/matches/${segmentMatches[0]._id}`}>{content}</Link>;
  }

  return content;
};

export default SegmentMatches;
