import FormField from '@sportnet/ui/lib/FormField';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import { ContentLoader } from '@sportnet/ui/lib/Loader';
import Segment from '@sportnet/ui/lib/Segment';
import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import { isAfter, isBefore } from 'date-fns';
import connectToQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { getListParameters, isCommiting } from 'redux-list';
import { getProp } from 'sportnet-utilities';
import { withTheme } from 'styled-components';
import EntryAnimation from '../../components/EntryAnimation';
import { ReduxConnectProps } from '../../configureStore';
import CompetitionPartTable from '../../containers/CompetitionPartTable';
import MatchesList from '../../containers/MatchesList';
import { RouteProps } from '../../library/App';
import { IRound } from '../../library/Competition';
import { RootState } from '../../rootReducer';
import { getPPOUrl, __ } from '../../utilities';
import {
  initializeOrSetListParams,
  setActiveCompetition,
} from '../App/actions';
import { currentPPOSelector } from '../App/selectors';
import {
  competitionMatchesSelector,
  sportSectorsPhasesSelector,
  sportSectorsSettingsSelector,
} from '../Competition/selectors';
import { HeaderWrapper, StyledHeaderL, StyledHeaderXL } from '../Competitions';
import { getCompetitionById } from '../Competitions/actions';
import { competitionByIdSelector } from '../Competitions/selectors';
import {
  getCompetitionPartById,
  getCompetitionParts,
  getCompetitionsPartMatchesList,
} from './actions';
import { competitionPartsSelector } from './selectors';

export const COMPETITION_PARTS_LIST_NAME =
  'COMPETITION_PARTS_BY_COMPETITION_ID';
export const COMPETITION_PART_MATCHES_LIST_NAME = 'COMPETITION_PART_MATCHES';
export const COMPETITION_PART_MATCHES_LIST_LIMIT = 1000;

type RouterProps = RouteProps<{ competitionId: string }>;

const mapStateToProps = (state: RootState, props: RouterProps) => {
  return {
    competition: competitionByIdSelector(props.params.competitionId)(state),
    competitionParts: competitionPartsSelector()(state),
    competitionPartIsFetching: isCommiting(COMPETITION_PARTS_LIST_NAME)(state),
    competitionPartMatches: competitionMatchesSelector(
      COMPETITION_PART_MATCHES_LIST_NAME,
    )(state),
    competitionPartMatchesIsFetching: isCommiting(
      COMPETITION_PART_MATCHES_LIST_NAME,
    )(state),
    activeParameters: getListParameters(COMPETITION_PART_MATCHES_LIST_NAME)(
      state,
    ),
    currentPPO: currentPPOSelector(state),
    sportSectorsPhases: sportSectorsPhasesSelector()(state),
    sportSectorsSettings: sportSectorsSettingsSelector()(state),
  };
};

type Props = ReturnType<typeof mapStateToProps> &
  Theme &
  ReduxConnectProps &
  RouterProps &
  QueryHocInterface;

class CompetitionMatches extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const {
      dispatch,
      query: { partId, roundId },
    } = props;
    let parameters: { partId: string; roundId?: string } = {
      partId: String(partId),
    };
    if (roundId) {
      parameters.roundId = String(roundId);
    }
    const { items: parts } = await dispatch(
      getCompetitionParts.action({
        competitionId: props.params.competitionId,
      }),
    );

    if (partId) {
      dispatch(
        initializeOrSetListParams.action({
          listName: COMPETITION_PARTS_LIST_NAME,
          params: {
            competitionId: props.params.competitionId,
          },
        }),
      );
      dispatch(
        initializeOrSetListParams.action({
          listName: COMPETITION_PART_MATCHES_LIST_NAME,
          params: {
            ...parameters,
            competitionId: props.params.competitionId,
          },
        }),
      );
    }

    if (!partId) {
      let activePart = parts.find(
        part =>
          isBefore(new Date(part.dateFrom), new Date()) &&
          isAfter(new Date(part.dateTo), new Date()),
      );
      if (!activePart) {
        const partsByDate = parts.sort((a, b) => {
          if (isAfter(new Date(a.dateTo), new Date(b.dateTo))) {
            return -1;
          } else if (isBefore(new Date(a.dateTo), new Date(b.dateTo))) {
            return 1;
          }
          return 0;
        });
        activePart = partsByDate[0];
      }
      let activeRound = (activePart.rounds || []).find(
        round =>
          isBefore(new Date(round.dateFrom), new Date()) &&
          isAfter(new Date(round.dateTo), new Date()),
      );

      if (!activeRound) {
        const roundsByDate = (activePart.rounds || []).sort((a, b) => {
          if (isAfter(new Date(a.dateTo), new Date(b.dateTo))) {
            return -1;
          } else if (isBefore(new Date(a.dateTo), new Date(b.dateTo))) {
            return 1;
          }
          return 0;
        });

        activeRound = roundsByDate.length ? roundsByDate[0] : undefined;
      }

      parameters = {
        ...parameters,
        partId: activePart._id,
      };
      if (activeRound && !roundId) {
        parameters.roundId = activeRound._id;
      }
    } else {
      const activePart = parts.find(part => part._id === partId);
      if (!roundId && activePart) {
        let activeRound = (activePart.rounds || []).find(
          part =>
            isBefore(new Date(part.dateFrom), new Date()) &&
            isAfter(new Date(), new Date(part.dateTo)),
        );
        if (!activeRound) {
          const roundsByDate = (activePart.rounds || []).sort((a, b) => {
            if (isAfter(new Date(a.dateTo), new Date(b.dateTo))) {
              return -1;
            } else if (isBefore(new Date(a.dateTo), new Date(b.dateTo))) {
              return 1;
            }
            return 0;
          });
          activeRound = roundsByDate.length ? roundsByDate[0] : undefined;
        }

        parameters = {
          partId: activePart._id,
        };
        if (activeRound) {
          parameters.roundId = activeRound._id;
        }
      }
    }

    // if (props.setParameter) {
    //   props.setParameter(parameters);
    // }
    dispatch(
      initializeOrSetListParams.action({
        listName: COMPETITION_PARTS_LIST_NAME,
        params: {
          competitionId: props.params.competitionId,
        },
      }),
    );
    dispatch(
      initializeOrSetListParams.action({
        listName: COMPETITION_PART_MATCHES_LIST_NAME,
        params: {
          ...parameters,
          competitionId: props.params.competitionId,
        },
      }),
    );
    dispatch(setActiveCompetition(props.params.competitionId));
    await Promise.all([
      dispatch(
        getCompetitionById.action({
          competitionId: props.params.competitionId,
        }),
      ),
      dispatch(
        getCompetitionPartById({
          ...parameters,
          competitionId: props.params.competitionId,
        }),
      ),
      dispatch(
        getCompetitionsPartMatchesList({
          ...parameters,
          competitionId: props.params.competitionId,
          offset: Number(props.query.offset),
        }),
      ),
    ]);
  }

  componentDidMount() {
    CompetitionMatches.getInitialProps(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.query.offset !== nextProps.query.offset) {
      nextProps.dispatch(
        getCompetitionsPartMatchesList({
          partId: String(nextProps.query.partId),
          ...(!!nextProps.query.roundId && {
            roundId: String(nextProps.query.roundId),
          }),
          competitionId: nextProps.params.competitionId,
          offset: Number(nextProps.query.offset),
        }),
      );
    } else if (this.props.serializedQuery !== nextProps.serializedQuery) {
      CompetitionMatches.getInitialProps(nextProps);
    }
  }

  onChangeParameters = (name: string, option: { value: string }) => {
    this.props.setParameter({
      offset: 0,
      [name]: option.value,
    });
  };

  onChangePage = (page: number) => {
    const offset = page * COMPETITION_PART_MATCHES_LIST_LIMIT;
    this.props.setParameter({
      offset,
    });
  };

  render() {
    const {
      competition,
      competitionParts,
      currentPPO,
      theme,
      competitionPartIsFetching,
      competitionPartMatches,
      competitionPartMatchesIsFetching,
      sportSectorsPhases,
      sportSectorsSettings,
      activeParameters,
    } = this.props;

    if (!competition) {
      return <ContentLoader size="xl" theme={theme} />;
    }

    const activePart = competitionParts.find(
      part => part._id === activeParameters.partId,
    );
    let activeRound: IRound | undefined;
    if (activePart && activeParameters.roundId) {
      activeRound = (activePart.rounds || []).find(
        round => round._id === activeParameters.roundId,
      );
    }
    const title = competition.name;
    return (
      <EntryAnimation key={'CompetitionMatches'}>
        <Helmet titleTemplate="%s">
          <title>{title}</title>
          <meta
            property="og:url"
            content={`${getPPOUrl(currentPPO._id || '')}/competitions/${
              competition._id
            }/matches`}
          />
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={title} />
        </Helmet>
        <HeaderWrapper>
          <StyledHeaderXL>{title}</StyledHeaderXL>
          <StyledHeaderL>{__('Stretnutia')}</StyledHeaderL>
        </HeaderWrapper>
        <Segment raised>
          {!activePart ? (
            <ContentLoader theme={this.props.theme} />
          ) : (
            <Row>
              <Col xs={12} s={6} m={3}>
                <FormField
                  value={{
                    value: activePart._id,
                    label: activePart.name,
                  }}
                  name="partId"
                  type="theselect"
                  label={__('Časť súťaže')}
                  onChange={(i: { value: string }) => {
                    if (i && i.value) {
                      this.onChangeParameters('partId', i);
                    }
                  }}
                  parse={(item: any) => {
                    if (!item) {
                      return '';
                    }
                    const option = competitionParts.find(
                      o => o && o.name === item.value,
                    );

                    if (!option) {
                      return '';
                    }
                    return option;
                  }}
                  options={competitionParts
                    .filter(
                      i => i.competitionId === this.props.params.competitionId,
                    )
                    .map(part => ({
                      label: part.name,
                      value: part._id,
                    }))}
                />
              </Col>
              {!!getProp(activePart, ['rounds'], []).length && (
                <Col xs={12} s={6} m={3}>
                  <FormField
                    value={
                      activeRound
                        ? {
                            label: activeRound.name,
                            value: activeRound._id,
                          }
                        : {}
                    }
                    type="theselect"
                    name="roundId"
                    label={__('Kolo')}
                    onChange={(i: { value: string }) => {
                      if (i && i.value) {
                        this.onChangeParameters('roundId', i);
                      }
                    }}
                    parse={(item: any) => {
                      if (!item) {
                        return '';
                      }
                      const option = (activePart
                        ? activePart.rounds || []
                        : []
                      ).find(o => o && o.name === item.value);

                      if (!option) {
                        return '';
                      }
                      return option;
                    }}
                    options={(activePart ? activePart.rounds || [] : []).map(
                      round => ({
                        label: round.name,
                        value: round._id,
                      }),
                    )}
                  />
                </Col>
              )}
            </Row>
          )}
        </Segment>
        {(!!competitionPartMatchesIsFetching ||
          !!competitionPartMatches.length) && (
          <MatchesList
            matches={competitionPartMatches}
            isFetching={competitionPartMatchesIsFetching}
            sportSectorsPhases={sportSectorsPhases}
            sportSectorsSettings={sportSectorsSettings}
          />
        )}
        {!!activePart && (
          <CompetitionPartTable
            header={`${activePart.name} ${
              !!activeRound ? `| ${activeRound.name}` : ''
            }`}
            resultsTable={
              activePart.resultsTable ? activePart.resultsTable.results : []
            }
            teams={activePart.teams || []}
            isFetching={competitionPartIsFetching}
            logoSrc={competition.publication.logo}
          />
        )}
      </EntryAnimation>
    );
  }
}

export default compose<React.FC<Props>>(
  connect(mapStateToProps),
  withRouter,
  withTheme,
  connectToQueryHoc({
    parameters: {
      partId: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      roundId: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      offset: {
        type: QueryHocTypes.Number,
        defaultValue: 0,
      },
    },
  }),
)(CompetitionMatches);
