import NotFoundComponent from '@sportnet/ui/lib/NotFound';
import * as React from 'react';
import EntryAnimation from '../../components/EntryAnimation';
import Footer from '../../containers/Footer';
import styled from '../../theme/styled-components';
import { __ } from '../../utilities';
import { Wrapper } from '../App';

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

class NotFound extends React.PureComponent<{}> {
  render() {
    return (
      <Wrapper>
        <ContentWrapper>
          <EntryAnimation key={'not-found'}>
            <NotFoundComponent
              title={__('Zadaná organizácia neexistuje')}
              icon="error"
            />
          </EntryAnimation>
        </ContentWrapper>
        <Footer />
      </Wrapper>
    );
  }
}

export default NotFound;
