import ArticleComponent from '@sportnet/ui/lib/Article';
import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'redux';
import { ThemeProvider } from 'styled-components';
import { Article as ArticleType } from '../../../api/CmsApi';
import config from '../../../config';
import { activeCompetitionSelector } from '../../../pages/App/selectors';
import { RootState } from '../../../rootReducer';
import formatDate from '../../../utilities/formatDate';
import getArticleUrl from '../../../utilities/getArticleUrl';

interface OwnProps {
  article: ArticleType;
  className?: string;
}

const mapStateToProps = (state: RootState) => ({
  activeCompetition: activeCompetitionSelector(state),
});

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const getArticleDate = (article: ArticleType) => {
  return article.modified
    ? formatDate(article.modified, config.DATE_TIME_FORMAT)
    : article.date
    ? formatDate(article.date, config.DATE_TIME_FORMAT)
    : '';
};

const Article: React.FC<Props> = ({
  article,
  className,
  activeCompetition,
}) => {
  return (
    <Link
      className={className}
      to={getArticleUrl(
        `${activeCompetition ? `/competition/${activeCompetition}` : ''}`,
        article,
      )}
      draggable={false}
    >
      <ThemeProvider
        theme={{
          separatorColor: '#eee',
        }}
      >
        <ArticleComponent
          title={article.name}
          subtitle={getArticleDate(article)}
          imgSrc={getMediaManagerUrl(article.picture, 360, 270, 'media')}
        />
      </ThemeProvider>
    </Link>
  );
};

export default compose(connect(mapStateToProps))(Article);
