import { getListResults } from 'redux-list';
import { createSelector } from 'reselect';
import { at } from '../../utilities';
import { entitiesSelector } from '../App/selectors';

export const competitionsSelector = (listName: string) =>
  createSelector(
    entitiesSelector,
    getListResults(listName),
    (entities, ids) => at(entities.COMPETITIONS, ids || []),
  );

export const competitionByIdSelector = (id: string) =>
  createSelector(
    entitiesSelector,
    entities => entities.COMPETITIONS[id],
  );

export const seasonsSelector = () =>
  createSelector(
    entitiesSelector,
    entities =>
      Object.keys(entities.SEASONS || {}).map(id => entities.SEASONS[id]),
  );
