import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import SegmentMatches, { SEGMENT_HEIGHT } from './SegmentMatches';

const SEGMENT_WIDTH = 180;
const BASE_SPACE = 5;

const BASE_MARGIN = (BASE_SPACE / 2 + SEGMENT_HEIGHT / 2) * 2;

const getMargin = (idx: number) => {
  let totalMargin = BASE_SPACE;
  let modifier = BASE_MARGIN;

  for (let i = 0; i <= idx; i++) {
    if (i > 0) {
      totalMargin = totalMargin + modifier;
      modifier = modifier * 2;
    }
  }
  return totalMargin / 2;
};

const Rounds = styled.div`
  display: flex;
  flex-shrink: 0;
  overflow-x: scroll;
  width: 100%;
`;

const RoundName = styled.div`
  padding: ${rem(10)} 0;
  text-align: center;
  margin-bottom: ${rem(10)};
  color: ${({ theme }) => theme.app.textColor};
  border: ${({ theme }) => `${rem(1)} solid ${theme.app.textColor}`};
  font-size: ${rem(13)};
  font-weight: bold;
`;

const RoundSegment = styled.div<{ margin: number; roundIdx: number }>`
  display: flex;
  align-items: center;
  border: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  margin: ${({ roundIdx }) => `${getMargin(roundIdx)}px 0`};
  height: ${rem(SEGMENT_HEIGHT)};
  position: relative;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const RoundConnector = styled.div<{
  margin: number;
  idx: number;
  roundIdx: number;
}>`
  height: ${({ roundIdx }) =>
    `${getMargin(roundIdx) * 2 + 2 * SEGMENT_HEIGHT - SEGMENT_HEIGHT}px`};
  position: absolute;
  left: ${`calc(100% + ${rem(SEGMENT_HEIGHT / 2 - 1)})`};
  width: 1px;
  top: ${({ idx, roundIdx }) =>
    rem(
      SEGMENT_HEIGHT / 2 +
      (getMargin(roundIdx) * 2 + 2 * SEGMENT_HEIGHT - SEGMENT_HEIGHT) * idx,
    )};
  background: ${({ theme }) => theme.separatorColor};
`;

const RoundWrapper = styled.div`
  margin-right: ${rem(SEGMENT_HEIGHT)};
  min-width: ${rem(SEGMENT_WIDTH)};
  &:not(:first-child) ${RoundSegment} {
    &:before {
      content: '';
      width: ${rem(SEGMENT_HEIGHT / 2 + 1)};
      height: ${rem(1)};
      position: absolute;
      top: 50%;
      right: 100%;
      background: ${({ theme }) => theme.separatorColor};
    }
  }
  &:not(:last-child) ${RoundSegment} {
    &:after {
      content: '';
      width: ${rem(SEGMENT_HEIGHT / 2 + 1)};
      height: ${rem(1)};
      position: absolute;
      top: 50%;
      left: 100%;
      background: ${({ theme }) => theme.separatorColor};
    }
  }
  &:last-child {
    ${RoundConnector} {
      display: none;
    }
  }
`;

const Round = styled.div<{ margin: number; roundIdx: number }>`
  margin-top: ${({ roundIdx }) => `${getMargin(roundIdx) + BASE_SPACE}px`};
  display: flex;
  flex-direction: column;
  position: relative;
`;

type IData = any;
interface IOwnProps {
  data: IData;
}

const WidgetCompetitionDraw: React.FC<IOwnProps & { theme: any }> = ({
  data,
}) => {
  const getBranches = (rounds: any[]) => {
    const o = [];
    let modifier = 0;
    let iterationIdx = 1;
    for (let i = rounds.length - 1; i >= 0; i--) {
      const segments = [];
      let segmentMargin = 0;
      let roundMargin = 0;
      for (let j = 0; j < iterationIdx; j++) {
        segmentMargin = segmentMargin + Math.pow(2, j);
        if (j > 0) {
          roundMargin = roundMargin + Math.pow(2, j - 1);
        }
      }
      for (let k = Math.pow(2, i) - 1; k >= 0; k--) {
        segments.push({ drawId: k + modifier });
      }
      modifier = modifier + Math.pow(2, i);
      iterationIdx = iterationIdx + 1;
      const round = rounds[rounds.length - 1 - i];
      o.push({
        _id: round._id,
        name: round.name,
        segments,
        segmentMargin,
        roundMargin,
      });
    }
    return o;
  };

  if (data.data) {
    const { competitionPart, matches } = data.data;

    const branches = getBranches(competitionPart.rounds || []);
    let totalSegments = 0;
    for (let a = 0; a < (competitionPart.rounds || []).length; a++) {
      totalSegments = totalSegments + Math.pow(2, a);
    }

    return branches.length > 0 ? (
      <Rounds>
        {branches.map((branch, roundIdx) => {
          const hasMatches = matches.find(
            (m: any) => m.round && m.round._id === branch._id && m.drawId,
          );
          if (!hasMatches) {
            return null;
          }
          return (
            <RoundWrapper key={`round_${roundIdx}`}>
              <RoundName>{branch.name}</RoundName>
              <Round roundIdx={roundIdx} margin={branch.roundMargin}>
                {branch.segments.map((segment, idx) => {
                  const segmentDrawId = totalSegments - segment.drawId;

                  const segmentMatches = matches.filter(
                    (m: any) => m.drawId && m.drawId === segmentDrawId,
                  );
                  const homeTeam =
                    segmentMatches.length > 0 &&
                    ((segmentMatches[0].teams || []).find(
                      (t: any) =>
                        t.additionalProperties &&
                        t.additionalProperties.homeaway &&
                        t.additionalProperties.homeaway === 'home',
                    ) ||
                      (segmentMatches[0].teams || [])[0]);
                  const awayTeam =
                    segmentMatches.length > 0 &&
                    ((segmentMatches[0].teams || []).find(
                      (t: any) =>
                        t.additionalProperties &&
                        t.additionalProperties.homeaway &&
                        t.additionalProperties.homeaway === 'away',
                    ) ||
                      (segmentMatches[0].teams || [])[1]);
                  return (
                    <>
                      <RoundSegment
                        roundIdx={roundIdx}
                        margin={branch.segmentMargin}
                        key={`segment_${idx}`}
                      >
                        {segmentMatches.length > 0 && (
                          <SegmentMatches
                            segmentMatches={segmentMatches}
                            homeTeam={homeTeam || undefined}
                            awayTeam={awayTeam || undefined}
                          />
                        )}
                      </RoundSegment>
                      {idx % 2 === 0 && roundIdx !== branches.length - 1 && (
                        <RoundConnector
                          margin={branch.segmentMargin}
                          idx={idx}
                          roundIdx={roundIdx}
                        />
                      )}
                    </>
                  );
                })}
              </Round>
            </RoundWrapper>
          );
        })}
      </Rounds>
    ) : null;
  }
  return null;
};

export default WidgetCompetitionDraw;
