import { createSelector } from 'reselect';
import IAppSettings from '../../library/AppSettings';
import { IAppSettingsState } from './reducer';

const appSettingsDomainSelector = (state: any) =>
  state.settings as IAppSettingsState;

export const appSettingsIsFetchingSelector = createSelector(
  appSettingsDomainSelector,
  domain => !domain.data || !!domain.isFetching,
);

export const appSettingsDidFailSelector = createSelector(
  appSettingsDomainSelector,
  domain => !!domain.error,
);

export const appSettingsSelector = createSelector(
  appSettingsDomainSelector,
  domain => (domain.data || {}) as IAppSettings, // AppSettings present in every component (except for DomainResolver)
);

export const appSettingsDomainPayloadSelector = createSelector(
  appSettingsSelector,
  domain => domain.domainPayload || {},
);
