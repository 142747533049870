import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { ExtraArgumentType } from '../../configureStore';
import { NormalizedEntities } from '../../library/App';
import IAppSettings from '../../library/AppSettings';
import NotFoundError from '../../NotFoundError';
import {
  loadIcons,
  setActiveAppspace,
  setActiveCompetition,
  setDomainPayload,
} from '../../pages/App/actions';
import { currentPPOSelector } from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';
import normalizeEntities from '../../utilities/normalizeEntities';

const create = actionCreatorFactory('DOMAIN_RESOLVER');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadAppSettings = createAsync<
  {
    domain: string;
  },
  IAppSettings
>('GET_SETTINGS', async ({ domain }, dispatch, getState, { DomainsApi }) => {
  try {
    const cleanDomain = domain.replace(/\.(localhost|127\.0\.0\.1|::1)$/, '');

    const resolvedDomain = await DomainsApi.getDomainInfo(cleanDomain);

    const appSpace = resolvedDomain.ppo;
    let baseUri = `https://${resolvedDomain._id}`.replace(/\/$/, '');

    if (domain !== cleanDomain) {
      // we are on localhost
      baseUri = `http://${resolvedDomain._id}.localhost:${process.env.REACT_APP_PORT}`;
    }

    // const settings = await TicketingApi.publicGetSettings(appSpace);
    const settings = {};

    dispatch(setActiveAppspace(appSpace));

    const resolvedDomainPayload = (resolvedDomain.payload || {}) as any;
    if (resolvedDomainPayload && resolvedDomainPayload.competitionId) {
      dispatch(setActiveCompetition(resolvedDomainPayload.competitionId));
    }
    dispatch(setDomainPayload(resolvedDomain.payload || {}));
    await dispatch(loadPpo.action({ appSpace }));
    await dispatch(loadPOInvoiceProfile.action());
    await dispatch(loadIcons.action());

    return {
      appSpace,
      baseUri,
      domainPayload: resolvedDomain.payload || {},
      ...settings,
    } as IAppSettings;
  } catch (e) {
    throw new NotFoundError(e);
  }
});

export const loadPpo = createAsync<
  {
    appSpace: string;
  },
  NormalizedEntities<'PPO'>
>('GET_PPO', async (parameters, dispatch, getState, { SportnetApi, Api }) => {
  const currentAppSpace = currentPPOSelector(getState());
  const ppo = await SportnetApi.organizationPPOProfile(parameters.appSpace);
  const settings = await Api.publicGetSettings(parameters.appSpace);

  return normalizeEntities('PPO', [
    { ...currentAppSpace, ...ppo, ...settings },
  ]);
});

export const loadPOInvoiceProfile = createAsync<void, NormalizedEntities<'PO'>>(
  'GET_PO_INVOICE_PROFILE',
  async (parameters, dispatch, getState, { SportnetApi }) => {
    const currentPPO = currentPPOSelector(getState());
    const invoiceProfile = await SportnetApi.organizationInvoiceProfile(
      currentPPO.organization_id || '',
    );
    return normalizeEntities('PO', [invoiceProfile]);
  },
);
