import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import { SectionTreeType } from '../../library/App';
import { RootState } from '../../rootReducer';
import { detailDataSelector, entitiesSelector } from '../App/selectors';

const urlDetailByUrlDomain = (state: RootState) => state.urlDetailByUrl;

const currentUrlDetailDomain = (pathname: string) =>
  createSelector(
    urlDetailByUrlDomain,
    byUrl => {
      const item = byUrl[pathname];
      if (item) {
        return item;
      }
      return null;
    },
  );

export const urlDetailIsFetchingSelector = (pathname: string) =>
  createSelector(
    currentUrlDetailDomain(pathname),
    detail => (detail !== null ? detail.isFetching : false),
  );

export const urlDetailSelector = (pathname: string) =>
  createSelector(
    currentUrlDetailDomain(pathname),
    detail => (detail && detail.data ? detail.data : null),
  );

export const urlErrorSelector = (pathname: string) =>
  createSelector(
    currentUrlDetailDomain(pathname),
    detail => (detail ? detail.error : undefined),
  );

// Section Tree
export const sectionTreeSelector = (params: {
  sectionIdOrUniqId: string;
  treelevel: number;
}) =>
  createSelector(
    entitiesSelector,
    detailDataSelector('sectionTreeBySectionIdOrUniqId', params),
    (entities, treeData): SectionTreeType => {
      const tree = getProp(treeData, ['tree'], []);

      const traverseTree = (sections: SectionTreeType): SectionTreeType => {
        return sections.map(section => {
          if (section.sections && section.sections.length > 0) {
            return {
              ...entities.sections[section._id!],
              sections: traverseTree(section.sections),
            };
          }
          return entities.sections[section._id!];
        });
      };

      return traverseTree(tree);
    },
  );
