import { rem } from 'polished';
import * as React from 'react';
import styled from '../../theme/styled-components';

export const MAX_PAGE_WIDTH = 1200;

const Wrapper = styled('div')<{ noMargin: boolean; elMaxWidth?: number }>`
  padding: 0 ${rem(15)};
  max-width: ${({ elMaxWidth, theme }) =>
    rem(elMaxWidth || theme.app.maxWidth)};
  margin: 0 auto;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : rem(15))};
  width: 100%;
  flex: 1 0 0;
`;

const MaxWidthBox: React.FC<{
  width?: number;
  className?: string;
  noMargin?: boolean;
}> = ({ children, width, className, noMargin }) => {
  return (
    <Wrapper className={className} elMaxWidth={width} noMargin={!!noMargin}>
      {children}
    </Wrapper>
  );
};

export default MaxWidthBox;
