import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ArticleId, SectionId } from '../../library/CMS';
import * as actions from './actions';

export type SectionArticlesByIdState = Readonly<{
  [key: string]: Readonly<{
    isFetching: boolean;
    articles: ReadonlyArray<ArticleId>;
    nextOffset: Readonly<number | null>;
  }>;
}>;

const SECTION_ARTICLES_INITIAL_STATE: SectionArticlesByIdState = {};

export const sectionArticlesByIdReducer = reducerWithInitialState(
  SECTION_ARTICLES_INITIAL_STATE,
)
  .case(
    actions.loadSectionArticles.async.started,
    (state, { sectionId }): SectionArticlesByIdState => ({
      ...state,
      [sectionId]: {
        isFetching: true,
      },
    }),
  )
  .case(
    actions.loadSectionArticles.async.done,
    (state, { params: { sectionId }, result }): SectionArticlesByIdState => ({
      ...state,
      [sectionId]: {
        isFetching: false,
        articles: result.results,
      },
    }),
  )
  .case(
    actions.loadSectionArticles.async.failed,
    (state, { params: { sectionId } }): SectionArticlesByIdState => ({
      ...state,
      [sectionId]: {
        isFetching: false,
      },
    }),
  );

export const currentSectionIdReducer = reducerWithInitialState<SectionId | null>(
  null,
).case(actions.setCurrentSectionId, (state, sectionId) => sectionId);
