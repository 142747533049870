import {
  commit,
  CommitError,
  getListParameters,
  getListResults,
} from 'redux-list';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import {
  COMPETITION_PARTS_LIST_NAME,
  COMPETITION_PART_MATCHES_LIST_LIMIT,
  COMPETITION_PART_MATCHES_LIST_NAME,
} from '.';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import { ICompetitionPart } from '../../library/Competition';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';

const create = actionCreatorFactory(config.COMPETITION_PARTS_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const getCompetitionParts = createAsync<
  {
    competitionId: string;
  },
  {
    results: any;
    items: ICompetitionPart[];
    total: number;
  }
>('GET_COMPETITION_PARTS', async (parameters, dispatch, getState, { Api }) => {
  const appSpace = activeAppspaceSelector(getState());
  const params: { [key: string]: any } = {};
  const response = await Api.getCompetitionParts(
    appSpace,
    parameters.competitionId,
    params,
  );
  return {
    ...normalizeEntities(
      'COMPETITION_PARTS',
      (response.parts || []).map(i => ({
        ...i,
        competitionId: parameters.competitionId,
      })),
    ),
    items: response.parts.map(i => ({
      ...i,
      competitionId: parameters.competitionId,
    })),
    total: (response.parts || []).length,
  };
});

export const loadCompetitionPartById = createAsync<
  {
    appSpace: string;
    competitionId: string;
    partId: string;
    roundId?: string;
  },
  {
    results: any;
    total: number;
  }
>(
  'GET_COMPETITION_PART_BY_ID',
  async (parameters, dispatch, getState, { Api }) => {
    const params: { [key: string]: any } = {};
    if (parameters.roundId) {
      params.roundId = parameters.roundId;
    }
    const response = await Api.getCompetitionPartById(
      parameters.appSpace,
      parameters.competitionId,
      parameters.partId,
      params,
    );
    return {
      ...normalizeEntities(
        'COMPETITION_PARTS',
        [{ ...response, competitionId: parameters.competitionId }] || [],
      ),
      total: 1,
    };
  },
);

export const getCompetitionPartById = (params: {
  competitionId: string;
  partId: string;
  roundId?: string;
}): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const appSpace = activeAppspaceSelector(getState());
    const currentResults = getListResults(COMPETITION_PARTS_LIST_NAME)(
      getState(),
    );
    const parameters = getListParameters(COMPETITION_PARTS_LIST_NAME)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: COMPETITION_PARTS_LIST_NAME,
        load: async () => {
          try {
            const res = await dispatch(
              loadCompetitionPartById.action({
                ...parameters,
                appSpace,
                competitionId: params.competitionId,
                partId: params.partId,
                roundId: params.roundId,
              }),
            );
            return {
              total: res.total,
              results: [...(currentResults || []), ...res.results],
            };
          } catch (e) {
            throw new CommitError();
          }
        },
      }),
    );
  };
};

export const getCompetitionPartMatches = createAsync<
  {
    appSpace: string;
    competitionId: string;
    partId: string;
    roundId?: string;
    offset?: number;
    limit?: number;
  },
  {
    results: any;
    total: number;
  }
>(
  'GET_COMPETITION_PART_MATCHES',
  async (parameters, dispatch, getState, { Api }) => {
    const params: { [key: string]: any } = {};
    if (typeof parameters.offset !== 'undefined') {
      params.offset = parameters.offset;
    }
    if (typeof parameters.offset !== 'undefined') {
      params.limit = parameters.limit;
    }
    if (parameters.roundId) {
      params.roundId = parameters.roundId;
    }
    const response = await Api.getCompetitionPartMatches(
      parameters.appSpace,
      parameters.competitionId,
      parameters.partId,
      params,
    );
    return {
      ...normalizeEntities('MATCHES', response.matches),
      total: response.total,
    };
  },
);

export const getCompetitionsPartMatchesList = (params: {
  competitionId: string;
  partId: string;
  roundId?: string;
  offset: number;
}): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const appSpace = activeAppspaceSelector(getState());
    const parameters = getListParameters(COMPETITION_PART_MATCHES_LIST_NAME)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: COMPETITION_PART_MATCHES_LIST_NAME,
        load: async () => {
          try {
            const res = await dispatch(
              getCompetitionPartMatches.action({
                ...parameters,
                offset: params.offset,
                limit: COMPETITION_PART_MATCHES_LIST_LIMIT,
                appSpace,
                competitionId: params.competitionId,
                partId: params.partId,
                roundId: params.roundId,
              }),
            );
            return {
              total: res.total,
              results: res.results,
            };
          } catch (e) {
            throw new CommitError();
          }
        },
      }),
    );
  };
};
