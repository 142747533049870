import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import MaxWidthBox from '../../components/MaxWidthBox';
import { ReduxConnectProps } from '../../configureStore';
import { breadcrumbsSelector } from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';
import styled from '../../theme/styled-components';

export const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
`;

export const CenteredContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state: RootState) => ({
  breadcrumbs: breadcrumbsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = IMapStateToProps & ReduxConnectProps;

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <MaxWidthBox>
      <Wrapper>
        {/* <Breadcrumbs breadcrumbs={breadcrumbs} /> */}
        {children}
      </Wrapper>
    </MaxWidthBox>
  );
};

export default compose<React.FC<{}>>(
  withRouter,
  connect(mapStateToProps),
)(Layout);
