import DefaultArticleList from '@sportnet/ui/lib/ArticleList';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { Article as ArticleType } from '../../../api/CmsApi';
import config from '../../../config';
import { withTheme } from '../../../theme/styled-components';
import { ThemeInterface } from '../../../theme/theme';
import MaxWidthBox from '../../MaxWidthBox';
import Article from '../Article';

type OwnProps = {
  articles: ArticleType[];
  theme: ThemeInterface;
};

const Articles: React.FC<OwnProps> = ({ articles, theme }) => {
  if (articles.length === 0) {
    return null;
  }

  return (
    <MaxWidthBox width={config.WIDER_CONTENT_MAX_WIDTH}>
      <ThemeProvider theme={{ grid: { gutterWidth: theme.grid.gutterWidth } }}>
        <DefaultArticleList>
          {articles.map(article => (
            <Article article={article} key={article._id} />
          ))}
        </DefaultArticleList>
      </ThemeProvider>
    </MaxWidthBox>
  );
};

export default withTheme(Articles);
