import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { format } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { getProp } from 'sportnet-utilities';
import styled, { css, withTheme } from 'styled-components';
import { ISportSectorEvent, ISportSectorPhase } from '../../library/App';
import { IMatchSettings, IProtocol, ITeam } from '../../library/Match';
import { __ } from '../../utilities';

export const Icon = (
  props: Theme & {
    eventType: string;
    subType?: string;
    title: string;
    size?: number;
  },
) => {
  switch (props.eventType) {
    case 'phase':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 20}
          height={props.size || 20}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill={props.theme.inactiveColor}
            d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
          />
        </svg>
      );
    case 'goal':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          width={props.size || 20}
          height={props.size || 20}
        >
          <title>{props.title}</title>
          <path
            fill={
              props.subType && props.subType === 'dropped'
                ? props.theme.color.danger
                : '#333'
            }
            d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm0 2.5c.7 0 1.4.1 2.2.2L16 4.2l-2.2-1.5c.7-.2 1.5-.2 2.2-.2zm-5.2 1l4.4 3.2.8.6.7-.5 4.4-3.2c2 .8 3.7 2.1 5.1 3.7l-1.7 5.3-.3.8.7.5 4.5 3.3c-.2 2.2-.8 4.2-1.9 6h-6.4l-.3.8-1.7 5.3c-1 .2-2 .3-3.1.3s-2.2-.1-3.2-.4l-1.7-5.3-.3-.9H4.4c-1.1-1.8-1.7-3.8-1.9-6L7 13.8l.7-.5-.3-.8-1.7-5.3c1.4-1.6 3.1-2.9 5.1-3.7zM16 8.7l-.7.5-5.6 4.1-.7.6.3.8 2.2 6.6.3.8H20.5l.3-.8 2.2-6.6.3-.8-.7-.5L17 9.3l-1-.6zm12 1c.7 1.3 1.1 2.7 1.3 4.2l-2.2-1.6.9-2.6zM4 9.8l.8 2.5-2.2 1.6c.3-1.4.7-2.8 1.4-4.1zm12 2l4.2 3-1.6 4.9h-5.2l-1.6-4.9 4.2-3zm6.9 13.7h2.8c-1 1.1-2.3 2-3.6 2.7l.8-2.7zm-16.6 0H9l.8 2.6c-1.2-.7-2.4-1.6-3.5-2.6z"
          />
        </svg>
      );
    case 'failed_goal':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 20}
          height={props.size || 20}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill={props.theme.color.warning}
            d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
          />
        </svg>
      );
    case 'yellow_card':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="card"
          width={props.size || 20}
          height={props.size || 20}
        >
          <title>{props.title}</title>
          <path
            fill="#ffc600"
            d="M385.2 512H126.8c-27.5 0-50-22.5-50-50V50c0-27.5 22.5-50 50-50h258.4c27.5 0 50 22.5 50 50v412c0 27.5-22.5 50-50 50z"
          />
        </svg>
      );
    case 'red_card':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="card"
          width={props.size || 20}
          height={props.size || 20}
        >
          <title>{props.title}</title>
          <path
            fill="#FF0C00"
            d="M385.2 512H126.8c-27.5 0-50-22.5-50-50V50c0-27.5 22.5-50 50-50h258.4c27.5 0 50 22.5 50 50v412c0 27.5-22.5 50-50 50z"
          />
        </svg>
      );
    case 'second_yellow_card':
      return (
        <svg
          version="1.1"
          viewBox="0 0 512 512"
          width={props.size || 20}
          height={props.size || 20}
        >
          <title>{props.title}</title>
          <g>
            <path
              fill="#FF0C00"
              d="M367.6,512c37.6,0,67.8-30.5,67.8-68.2V68.4c0-16.3-5.7-31.5-15.2-43.3L92.3,487.5   c12.5,15.1,31.1,24.5,52.2,24.5H367.6z"
            />
            <path
              fill="#FFC700"
              d="M76.7,68.4v375.4c0,16.6,5.9,31.8,15.6,43.7L420.1,25.2C407.7,9.9,388.9,0,367.6,0H144.4   C106.8,0,76.7,30.8,76.7,68.4z"
            />
          </g>
        </svg>
      );
    case 'substitution':
      return (
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          width={props.size || 20}
          height={props.size || 20}
        >
          <title>{props.title}</title>
          <path
            fill="#FF0C00"
            d="M303.1,133.3L151,379.2L0,132.8h303.4L303.1,133.3z"
          />
          <path
            fill="#63C509"
            d="M208.9,378.7l152.1-246l151,246.5H208.6L208.9,378.7z"
          />
        </svg>
      );
    case 'exclusion':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 20}
          height={props.size || 20}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill={props.theme.color.danger}
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"
          />
        </svg>
      );
    case 'timeout':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 20}
          height={props.size || 20}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path
            fill="#333"
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
          <path fill="#333" d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
        </svg>
      );
    default:
      return null;
  }
};

const Wrapper = styled.div`
  text-align: center;
  color: #333;
`;

const Events = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    height: calc(100% - ${rem(70)});
    width: ${rem(1)};
    background: ${({ theme }) => theme.separatorColor};
    position: absolute;
    top: calc(0% + ${rem(35)});
    left: calc(0% + ${rem(32)});
    ${mb('s')} {
      left: calc(50% - ${rem(1)});
    }
  }
`;
const EventItem = styled.div<{ align: string; last?: boolean }>`
  display: flex;
  align-items: center;
  ${({ last }) => {
    if (last) {
      return css`
        ${Minute} {
          &:after {
            height: 0;
          }
        }
      `;
    }
    return css``;
  }}
  ${({ align }) => {
    if (align === 'left') {
      return css`
        flex-direction: row-reverse;
        justify-content: flex-end;
        ${mb('s')} {
          flex-direction: row;
        }
      `;
    }
    return css``;
  }}
`;
const EventItemPart = styled.div`
  width: calc(100% - ${rem(32.5)});
  padding: ${rem(5)} 0;
  ${mb('s')} {
    width: calc(50% - ${rem(32.5)});
  }
`;
const Spacer = styled(EventItemPart)`
  display: none;
  ${mb('s')} {
    display: block;
  }
`;
const Minute = styled.div<{ phase?: boolean }>`
  text-align: center;
  padding: ${rem(10)};
  border: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  border-width: 0;
  background: white;
  font-weight: bold;
  color: #333;
  border-radius: 100%;
  height: ${rem(35)};
  width: ${rem(35)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: ${rem(10)} ${rem(15)};
  font-size: ${rem(11)};
  z-index: 1;
  ${({ phase }) => {
    if (phase) {
      return css`
        border-width: 0;
        visibility: hidden;
      `;
    }
    return css``;
  }}
`;
const MinuteMask = styled.div<{ align: string }>`
  /* position: absolute;
  left: ${({ align }) => (align === 'left' ? rem(-1) : rem(17.5))};
  width: ${rem(35 / 2)};
  height: 120%;
  background: white;
  transform: translate(-${rem(1)}, 0); */
`;
const EventItemInfo = styled(EventItemPart)``;
const EventIcon = styled.div<{ align: string }>`
  display: flex;
  align-items: center;
  ${({ align }) => {
    if (align === 'left') {
      return css`
        flex-direction: row;
        justify-content: flex-start;
        ${Player} {
          align-items: flex-start;
          text-align: left;
        }
        ${mb('s')} {
          justify-content: flex-start;
          flex-direction: row-reverse;
          ${Player} {
            text-align: right;
          }
        }
      `;
    }
    return css``;
  }}
`;
const Player = styled.div`
  padding: 0 ${rem(15)};
  text-align: left;
`;
const PlayerName = styled.div<{ phase?: boolean }>`
  font-size: ${rem(12)};
  font-weight: ${({ phase }) => (phase ? 'normal' : 'bold')};
`;
const EventType = styled.div<{ mobile?: boolean }>`
  font-size: ${rem(11)};
  color: ${({ mobile }) => (mobile ? 'inherit' : '#bbb')};
  ${({ theme, mobile }) => `${mb('s')} {
    display: ${mobile ? 'none' : 'block'};
  }`}
`;
const IconWrapper = styled.div`
  width: ${rem(20)};
`;

interface OwnProps {
  teams: ITeam[];
  protocol?: IProtocol;
  timer?: {
    [key: string]: {
      start: {
        date: string;
        seconds?: number;
      };
      end?: {
        date: string;
        seconds?: number;
      };
    };
  };
  eventTypes: ISportSectorEvent[];
  phases: ISportSectorPhase[];
  settings: IMatchSettings;
}

type Props = OwnProps & Theme;

class TimeLine extends React.PureComponent<Props> {
  renderEventDetail = (event: any, currentTeam: ITeam, align: string) => {
    const phase = this.props.eventTypes.find(e => e._id === event.type);
    // const team = this.props.teams.find(t => t._id === event.team);
    return (
      <EventItemInfo>
        <EventIcon align={align}>
          <IconWrapper>
            <Icon
              title={phase ? phase.label : ''}
              eventType={event.eventType}
              subType={event.type}
              theme={this.props.theme}
            />
          </IconWrapper>
          <Player>
            {!!event.player && (
              <div>
                <PlayerName>{event.player.name}</PlayerName>
              </div>
            )}
            {event.eventType === 'timeout' && (
              <PlayerName>{__('Timeout')}</PlayerName>
            )}
            <EventType mobile>{currentTeam.displayName}</EventType>
            {!!event.replacement && (
              <EventType>{`Striedajúci hráč: ${event.replacement.name}`}</EventType>
            )}
            {!!event.assist && (
              <EventType>{`Asistencia: ${event.assist.name}`}</EventType>
            )}
            {!!event.type &&
            getProp(
              this.props.eventTypes.find(i => i._id === event.type) || {},
              ['label'],
            ) ? (
              <EventType>
                {this.props.eventTypes.find(i => i._id === event.type)!.label}
              </EventType>
            ) : null}
            {!!event.reason && <EventType>{event.reason}</EventType>}
          </Player>
        </EventIcon>
      </EventItemInfo>
    );
  };

  getTeam = (teamSide: string) =>
    this.props.teams.find(
      i => getProp(i, ['additionalProperties', 'homeaway'], '') === teamSide,
    );

  getTeamsIds = () => {
    const homeTeam = this.getTeam('home');
    const homeTeamId = homeTeam ? homeTeam._id : this.props.teams[0]._id;
    const awayTeam = this.getTeam('away');
    const awayTeamId = awayTeam ? awayTeam._id : this.props.teams[1]._id;

    return { homeTeamId, awayTeamId };
  };

  getEventTime = (event: any) => {
    let minutes = `${Math.ceil(event.eventTime / 60)}`;
    if (this.props.settings.displaySeconds) {
      minutes = `${Math.floor(event.eventTime / 60)}`;
      const seconds = String(event.eventTime % 60).padStart(2, '0');
      return `${minutes}:${seconds}`;
    }
    if (this.props.settings.overlapTime) {
      const phase = this.props.settings.phases[event.phase];
      if (phase.endTime && phase.endTime < event.eventTime) {
        const remaind = Math.ceil((event.eventTime - phase.endTime) / 60);
        return <div>{`${phase.endTime / 60}+${remaind}'`}</div>;
      }
      return `${minutes}'`;
    }
    return `${minutes}'`;
  };

  renderEventItem = (teams: ITeam[], event: any) => {
    const currentTeam = teams.find(i => i._id === event.team)!;
    const { homeTeamId } = this.getTeamsIds();

    return (
      <EventItem align={event.team === homeTeamId ? 'left' : 'right'}>
        {event.team === homeTeamId ? (
          this.renderEventDetail(event, currentTeam, 'left')
        ) : (
          <Spacer />
        )}
        <Minute>
          <MinuteMask align={event.team === homeTeamId ? 'left' : 'right'} />
          <div style={{ position: 'relative' }}>{this.getEventTime(event)}</div>
        </Minute>
        {event.team === homeTeamId ? (
          <Spacer />
        ) : (
          this.renderEventDetail(event, currentTeam, 'right')
        )}
      </EventItem>
    );
  };
  render() {
    const { teams, protocol, timer, phases } = this.props;
    let playablePhases = phases.filter(a => a.playPhase);
    playablePhases = playablePhases.sort((a, b) => {
      if (a.startTime < b.startTime) {
        return 1;
      } else if (a.startTime > b.startTime) {
        return -1;
      }
      return 0;
    });
    const PHASES = playablePhases.reduce((acc: any, phase: any) => {
      if (timer && timer[phase._id]) {
        return [
          ...acc,
          {
            ...phase,
            startDate: getProp(timer[phase._id], ['start', 'date']),
            endDate: getProp(timer[phase._id] || {}, ['end', 'date']),
            startTime:
              getProp(timer[phase._id], ['start', 'seconds']) ||
              getProp(this.props.settings, ['phases', phase._id, 'startTime']),
            endTime:
              getProp(timer[phase._id] || {}, ['end', 'seconds']) ||
              getProp(this.props.settings, ['phases', phase._id, 'endTime']),
          },
        ];
      }
      return acc;
    }, []);

    let events = (protocol || { events: [] }).events
      .filter(event => event.eventTime && event.type !== 'goal_shootout')
      .map(event => {
        const timeParts = event.eventTime.split(':');
        const seconds = Number(timeParts[0]) * 60 + Number(timeParts[1]);
        return {
          ...event,
          eventTime: seconds,
        };
      });

    events = events.sort((a, b) => (a.eventTime > b.eventTime ? -1 : 1));

    const eventsByPhase = events.reduce(
      (acc, event) => {
        return { ...acc, [event.phase]: [...(acc[event.phase] || []), event] };
      },
      PHASES.reduce((acc: any, phase: any) => {
        if (phase && phase.startDate) {
          return { ...acc, [phase._id]: [] };
        }
        return acc;
      }, {}),
    );

    const keys = Object.keys(eventsByPhase)
      .sort()
      .reverse();

    console.log('TimeLine.tsx {512} eventsByPhase:', eventsByPhase); // REMOVE: remove console log

    return (
      <Wrapper>
        <Events>
          {keys
            .filter(p => p !== 'shootout')
            .map(phase => {
              const phaseData = PHASES.find((i: any) => i._id === phase);
              console.log(
                'TimeLine.tsx {521} phase, PHASES, phaseData:',
                phase,
                PHASES,
                playablePhases,
                phaseData,
              ); // REMOVE: remove console log

              if (phaseData) {
                return (
                  <React.Fragment key={phase}>
                    <EventItem align="right">
                      <Spacer />
                      <Minute phase>
                        {Math.floor(phaseData.startTime / 60)}'
                      </Minute>
                      <EventItemInfo>
                        <EventIcon align="right">
                          <IconWrapper>
                            <Icon
                              title={phaseData.label}
                              eventType="phase"
                              theme={this.props.theme}
                            />
                          </IconWrapper>
                          <Player>
                            <PlayerName
                              phase
                            >{`Koniec - ${phaseData.label}`}</PlayerName>
                            {phaseData.endDate && (
                              <EventType>
                                {format(new Date(phaseData.endDate), 'HH:mm')}
                              </EventType>
                            )}
                          </Player>
                        </EventIcon>
                      </EventItemInfo>
                    </EventItem>
                    {eventsByPhase[phase].map((event: any, idx: number) => (
                      <div key={`event_${idx}`}>
                        {this.renderEventItem(teams, event)}
                      </div>
                    ))}
                    {!!phaseData.startDate && (
                      <EventItem align="right">
                        <Spacer />
                        <Minute phase>
                          {Math.floor(
                            (phaseData.startTime + phaseData.playTime) / 60,
                          )}
                          '
                        </Minute>
                        <EventItemInfo>
                          <EventIcon align="right">
                            <IconWrapper>
                              <Icon
                                title={phaseData.label}
                                eventType="phase"
                                theme={this.props.theme}
                              />
                            </IconWrapper>
                            <Player>
                              <PlayerName
                                phase
                              >{`Začiatok - ${phaseData.label}`}</PlayerName>
                              <EventType>
                                {format(new Date(phaseData.startDate), 'HH:mm')}
                              </EventType>
                            </Player>
                          </EventIcon>
                        </EventItemInfo>
                      </EventItem>
                    )}
                  </React.Fragment>
                );
              }
              return null;
            })}
        </Events>
      </Wrapper>
    );
  }
}

export default withTheme(TimeLine);
