import { Section } from '../api/CmsApi';
import { ISection } from '../library/App';

export default (section: Section) => {
  const {
    _id,
    content,
    name,
    picture,
    redirecturl,
    url,
    uniqid,
    icon,
  } = section;
  const newSection: ISection = {
    _id: _id!, // @TODO csm/api
    name: name!, // @TODO csm/api
    icon: icon!, // @TODO csm/api
  };
  if (content) {
    newSection.content = content;
  }
  if (picture) {
    newSection.picture = picture;
  }
  if (redirecturl) {
    newSection.redirecturl = redirecturl;
  }
  if (url) {
    newSection.url = url;
  }
  if (uniqid) {
    newSection.uniqid = uniqid;
  }
  return newSection;
};
