import isBrowser from './isBrowser';

export default function(props: { hostname?: string }) {
  let domain = '';
  if (props && props.hostname) {
    domain = props.hostname;
  }
  if (isBrowser()) {
    domain = window.location.hostname;
  }
  return domain;
}
