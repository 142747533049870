import { em, rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { Title } from '../../components/CMS/Layout';
import EntryAnimation from '../../components/EntryAnimation';
import MaxWidthBox from '../../components/MaxWidthBox';
import config from '../../config';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import { RootState } from '../../rootReducer';
import styled from '../../theme/styled-components';
import { __ } from '../../utilities';
import { initializeOrSetListParams } from '../App/actions';

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  padding: ${rem(16)};
`;

const Subtitle = styled('p')`
  font-size: ${em(16)};
  text-align: center;
  line-height: 1.4;
`;

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  initializeOrSetListParams: initializeOrSetListParams.action,
};
type IMapDispatchToProps = typeof mapDispatchToProps;

type Props = IMapStateToProps &
  IMapDispatchToProps &
  RouteComponentProps<{}, {}>;

const NotFound: React.FC<Props> = ({ appSettings, location: { pathname } }) => {
  const title = __('Zamknutý obsah');
  const subtitle = __(
    'Stránka, ktorú sa pokúšate zobraziť, nie je k dispozícii pre verejnosť. Ak ju chcete zobraziť, prosím prihláste sa.',
  );

  return (
    <EntryAnimation key={'not-found'}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:url" content={`${appSettings.baseUri}${pathname}`} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="section" />
        <meta name="description" content={subtitle} />
      </Helmet>

      <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
        <ContentWrapper>
          <Title isCentered>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </ContentWrapper>
      </MaxWidthBox>
    </EntryAnimation>
  );
};

export default compose<React.FC<any>>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NotFound);
