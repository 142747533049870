import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import Api from './Api';
import CmsApi from './api/CmsApi';
import DomainsApi from './DomainsApi';
import rootReducer, { RootState } from './rootReducer';
import SportnetApi from './SportnetApi';

if (process.env.REACT_APP_SPORTNET_API_BASE_URL) {
  SportnetApi.setBaseUrl(String(process.env.REACT_APP_SPORTNET_API_BASE_URL));
} else {
  throw Error('REACT_APP_SPORTNET_API_BASE_URL not set');
}

if (process.env.REACT_APP_API_BASE_URL) {
  Api.setBaseUrl(String(process.env.REACT_APP_API_BASE_URL));
} else {
  throw Error('REACT_APP_API_BASE_URL not set');
}

if (process.env.REACT_APP_CMS_API_BASE_URL) {
  CmsApi.setBaseUrl(String(process.env.REACT_APP_CMS_API_BASE_URL));
} else {
  throw Error('REACT_APP_CMS_API_BASE_URL not set');
}

let composeEnhancers;
if (typeof window !== 'undefined') {
  composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

const extraArgument = { SportnetApi, Api, DomainsApi, CmsApi };
export type ExtraArgumentType = typeof extraArgument;
const thunk: ThunkMiddleware<{}, AnyAction> = thunkMiddleware.withExtraArgument(
  extraArgument,
);

const enhancer = composeEnhancers(applyMiddleware(thunk));

export type CustomThunkAction<R> = (
  dispatch: ThunkDispatch<RootState, ExtraArgumentType, AnyAction>,
  getState: () => RootState,
  extraArgument: {
    SportnetApi: typeof SportnetApi;
    Api: typeof Api;
    DomainsApi: typeof DomainsApi;
  },
) => R;

export type CustomThunkDispatch = ThunkDispatch<
  RootState,
  ExtraArgumentType,
  AnyAction
>;

export interface ReduxConnectProps {
  dispatch: CustomThunkDispatch;
}

export default function configureStore(initialState?: object) {
  return createStore(rootReducer, initialState!, enhancer);
}
