import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import { ReduxConnectProps } from '../../configureStore';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import { RouteProps } from '../../library/App';
import { RootState } from '../../rootReducer';
import { ThemeProvider } from '../../theme/styled-components';
import { getTheme } from '../../theme/theme';
import { loadPpoTheme } from './actions';
import { activePPOThemeSelector } from './selectors';

const mapStateToProps = (state: RootState) => {
  return {
    theme: activePPOThemeSelector(state),
    appSettings: appSettingsSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = ReduxConnectProps & IMapStateToProps & RouteProps;

const Themer: React.FC<Props> = ({
  theme,
  dispatch,
  children,
  appSettings: { appSpace },
}) => {
  useAsyncData(async () => {
    await Promise.all([dispatch(loadPpoTheme.action())]);
  }, [appSpace]);

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <>{children}</>
    </ThemeProvider>
  );
};

export default compose(connect(mapStateToProps))(Themer);
