// import { rem } from 'polished';
import * as React from 'react';

// const Wrapper = styled('div')`
//   display: flex;
//   background-color: white;
//   height: ${rem(80)};
//   align-items: center;
//   margin-top: auto;
// `;

// const Content = styled('div')`
//   display: flex;
// `;

// const Logo = styled('img')`
//   height: ${rem(40)};
//   margin-left: auto;
//   display: block;
// `;

type Props = {};

class Footer extends React.PureComponent<Props> {
  render() {
    return <div />;
  }
}

export default Footer;
