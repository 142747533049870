import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  appSettingsDomainPayloadSelector,
  appSettingsIsFetchingSelector,
} from './containers/DomainResolver/selectors';
import CompetitionGroup from './pages/CompetitionGroup';
import Competitions from './pages/Competitions';
import { RootState } from './rootReducer';

const mapStateToProps = (state: RootState) => ({
  domainPayload: appSettingsDomainPayloadSelector(state),
  appSettingsIsFetching: appSettingsIsFetchingSelector(state),
});

class DomainRoutes extends React.PureComponent<
  ReturnType<typeof mapStateToProps>
> {
  render() {
    if (this.props.domainPayload && this.props.domainPayload.competitionId) {
      return <CompetitionGroup {...(this.props as any)} />;
    }
    return <Competitions {...(this.props as any)} />;
  }
}

export default compose(connect(mapStateToProps))(DomainRoutes);
