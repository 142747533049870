import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import { NormalizedEntities } from '../../library/App';
import { currentPPOSelector } from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';

const create = actionCreatorFactory(config.THEMER_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadPpoTheme = createAsync<void, NormalizedEntities<'PPO'>>(
  'GET_PPO_THEME',
  async (parameters, dispatch, getState, { SportnetApi }) => {
    const { appSpace } = appSettingsSelector(getState());
    const currentValues = currentPPOSelector(getState());
    const theme = await SportnetApi.organizationPPOTheme(appSpace);
    return normalizeEntities('PPO', [{ ...currentValues, theme }]);
  },
);
