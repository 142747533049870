import { createSelector } from 'reselect';
import { entitiesSelector } from '../App/selectors';

export const competitionPartsSelector = () =>
  createSelector(
    entitiesSelector,
    entities =>
      Object.keys(entities.COMPETITION_PARTS).map(
        id => entities.COMPETITION_PARTS[id],
      ),
  );
