class ForbiddenError extends Error {
  identifier: string;
  error: any;
  constructor(e: any) {
    super('FORBIDDEN');
    this.error = e;
    this.identifier = 'FORBIDDEN';
  }
}

export default ForbiddenError;
