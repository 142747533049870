import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import ArticlesList from '../../components/CMS/ArticlesList';
import Author from '../../components/CMS/Author';
import ContentMetaInformation from '../../components/CMS/ContentMetaInformation';
import Perex from '../../components/CMS/Perex';
import Tags from '../../components/CMS/Tags';
import Widgets from '../../components/CMS/Widgets';
import EntryAnimation from '../../components/EntryAnimation';
import HorizontalSpacer from '../../components/HorizontalSpacer';
import Loader from '../../components/Loader';
import config from '../../config';
import { ReduxConnectProps } from '../../configureStore';
import ContentHeader from '../../containers/CMS/ContentHeader';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import { ArticleId } from '../../library/CMS';
import Page from '../../pages/Page';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities';
import getArticleUrl from '../../utilities/getArticleUrl';
import getIdFromProps from '../../utilities/getIdFromProps';
import { initializeOrSetListParams } from '../App/actions';
import Forbidden from '../Forbidden';
import NotFound from '../NotFound';
import Unauthorized from '../Unauthorized';
import {
  loadArticle,
  loadSimilarArticlesList,
  setCurrentArticleId,
} from './actions';
import {
  currentArticleErrorSelector,
  currentArticleIsFetchingSelector,
  currentArticleSelector,
  currentArticleSimilarArticlesSelector,
} from './selectors';

interface OwnProps {
  id: ArticleId;
  contentDivider: string;
}

type RouteProps = RouteComponentProps<
  {
    id: string;
  },
  void
>;

const mapStateToProps = (state: RootState, props: OwnProps & RouteProps) => {
  return {
    article: currentArticleSelector(state),
    error: currentArticleErrorSelector(state),
    isFetching: currentArticleIsFetchingSelector(state),
    appSettings: appSettingsSelector(state),
    similarArticles: currentArticleSimilarArticlesSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & RouteProps & IMapStateToProps & ReduxConnectProps;

class Article extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch, contentDivider } = props;

    const id = getIdFromProps(props);
    dispatch(setCurrentArticleId(id));
    await dispatch(
      initializeOrSetListParams.action({
        listName: config.LIST_SIMILAR_ARTICLES,
        params: {
          offset: 0,
          limit: config.LIST_SIMILAR_ARTICLES_LIMIT,
          articleId: id,
        },
      }),
    );
    await Promise.all([
      dispatch(loadArticle.action({ id, contentDivider })),
      dispatch(loadSimilarArticlesList({ contentDivider })),
    ]);
  }

  async componentDidMount() {
    try {
      await Article.getInitialProps(this.props);
    } catch (e) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      try {
        await Article.getInitialProps(this.props);
      } catch (e) {
        /* */
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentArticleId(null));
  }

  render() {
    const {
      article,
      isFetching,
      appSettings,
      similarArticles,
      error,
    } = this.props;
    if (isFetching) {
      return <Loader />;
    }
    if (error && error.identifier === 'FORBIDDEN') {
      return <Forbidden />;
    }
    if (error && error.identifier === 'UNAUTHORIZED') {
      return <Unauthorized />;
    }
    if (!article) {
      return <NotFound />;
    }
    const articleTitlePicture = article.picture
      ? (article.picture as any)
      : null;
    return (
      // <App>
      <Page>
        <EntryAnimation key={getIdFromProps(this.props).toString()}>
          <Helmet>
            <title>{article.name}</title>
            <meta
              property="og:url"
              content={getArticleUrl(appSettings.baseUri || '', article)}
            />
            <meta property="og:title" content={article.name} />
            <meta property="og:type" content="article" />
            {articleTitlePicture && (
              <meta
                property="og:image"
                content={getMediaManagerUrl(
                  articleTitlePicture,
                  1000,
                  0,
                  'resize',
                )}
              />
            )}
            <meta name="description" content={article.perex || ''} />
          </Helmet>
          <ContentHeader title={article.name || ''} />
          <ContentMetaInformation
            createdDate={article.date || null}
            modifiedDate={article.modified || null}
          />
          <Perex text={article.perex || null} />
          <Widgets items={article.widgets || []} />
          <Author name={String(getProp(article, ['author', 'name'], ''))} />
          <Tags tags={article.smarttags || []} />
          <HorizontalSpacer height={40} />
          <ArticlesList
            title={__('Mohlo by vás zaujímať')}
            articles={similarArticles}
          />
        </EntryAnimation>
      </Page>
      // </App>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps))(Article) as any;
