import Col, { Row } from '@sportnet/ui/lib/Grid';
import Icon from '@sportnet/ui/lib/Icon';
import Label from '@sportnet/ui/lib/Label/Label';
import { ContentLoader } from '@sportnet/ui/lib/Loader';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { em } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import { connectSsr } from 'ssr-service';
import styled, { css, withTheme } from 'styled-components';
import EntryAnimation from '../../components/EntryAnimation';
import { ReduxConnectProps } from '../../configureStore';
import Match from '../../containers/Match';
import { RouteProps } from '../../library/App';
import {
  IAthlete,
  IMatchNomination,
  IMatchNominationCrewItem,
  ITeam
} from '../../library/Match';
import { RootState } from '../../rootReducer';
import { ThemeInterface } from '../../theme/theme';
import { getPPOUrl, __ } from '../../utilities';
import {
  getSportSectorsCrew,
  getSportSectorsDelegates,
  getSportSectorsEvents,
  setActiveCompetition
} from '../App/actions';
import { currentPPOSelector } from '../App/selectors';
import {
  sportSectorEventsSelector,
  sportSectorsCrewSelector,
  sportSectorsDelegatesSelector,
  sportSectorSettingsSelector,
  sportSectorsPhasesSelector
} from '../Competition/selectors';
import { HeaderWrapper, StyledHeaderL, StyledHeaderXL } from '../Competitions';
import { getCompetitionById } from '../Competitions/actions';
import { competitionByIdSelector } from '../Competitions/selectors';
import { getMatchById } from './actions';
import { matchByIdSelector } from './selectors';
import TimeLine, { Icon as EventIcon } from './TimeLine';

const FormGroup = styled.div`
  margin: ${em(10)} 0;
`;

const Text = styled.div`
  font-size: ${em(13)};
  color: #3e3e3e;
`;

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    color: ${({ theme }) => theme.inactiveColor};
    padding: ${em(10)} 0;
    font-weight: lighter;
  }
`;

const NominationItems = styled.div`
  & > div {
    margin-left: -${em(5)};
  }
`;

const NominationHeader = styled.div`
  display: flex;
  align-items: center;
  & > img {
    height: ${em(30)};
    padding-right: ${em(15)};
  }
`;

const ListRow = styled.div<{ lastIndex: boolean }>`
  display: flex;
  color: #3e3e3e;
  font-size: ${em(13)};
  padding: ${em(10)} 0;
  border-bottom: ${em(1)} solid ${({ theme }) => theme.separatorColor};
  justify-content: space-between;
  ${({ lastIndex }) => {
    if (lastIndex) {
      return css`
        border-width: 0;
      `;
    }
    return css``;
  }}
`;

const ListCol = styled.div<{ flexWrap?: boolean }>`
  display: flex;
  flex-wrap: ${({ flexWrap }) => (!!flexWrap ? 'wrap' : 'no-wrap')};
`;

const EventIconWrapper = styled.div`
  padding: 0 ${em(3)};
`;

export const StyledSegmentHeader = styled.div`
  h3 {
    font-weight: lighter;
    font-size: ${em(22)};
    color: #555555;
    padding: 0;
    margin: 0;
  }
  h4 {
    font-weight: lighter;
    font-size: ${em(18)};
    color: #555555;
    padding: 0;
    margin: 0;
  }
`;

type RouterProps = RouteProps<{ matchId: string }>;

const mapStateToProps = (state: RootState, props: RouterProps) => {
  const match = matchByIdSelector(props.params.matchId)(state);
  const sportSector = getProp(match, ['rules', 'sport_sector'], '');

  return {
    ...(match && {
      competition: competitionByIdSelector(match.competition._id)(state),
    }),
    match,
    currentPPO: currentPPOSelector(state),
    sportSectorsPhases: sportSectorsPhasesSelector()(state),
    sportSectorDelegates: sportSectorsDelegatesSelector()(state),
    sportSectorCrew: sportSectorsCrewSelector()(state),
    sportSectorEvents: sportSectorEventsSelector(sportSector)(state),
    sportSectorSettings: sportSectorSettingsSelector(sportSector)(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = RouterProps &
  IMapStateToProps &
  ReduxConnectProps & { theme: ThemeInterface };

class CompetitionMatch extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const {
      dispatch,
      params: { matchId },
    } = props;
    const match = await dispatch(getMatchById.action({ matchId }));
    const sportSector = getProp(match.entities.MATCHES, [
      matchId,
      'rules',
      'sport_sector',
    ]);

    if (match.entities.MATCHES && match.entities.MATCHES[matchId]) {
      dispatch(
        setActiveCompetition(match.entities.MATCHES[matchId].competition._id),
      );
      await Promise.all([
        dispatch(
          getCompetitionById.action({
            competitionId: match.entities.MATCHES[matchId].competition._id,
          }),
        ),
        dispatch(getSportSectorsEvents.action({ sportSector })),
        dispatch(getSportSectorsDelegates.action({ sportSector })),
        dispatch(getSportSectorsCrew.action({ sportSector })),
      ]);
    }
  }

  state = {
    protocol: null,
    liveState: null,
    timer: {},
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (!this.props.match && nextProps.match) {
      CompetitionMatch.getInitialProps(nextProps);
    }
  };

  renderAthletesList = (appSpace: string, athletes: IAthlete[]) => {
    const { sportSectorEvents } = this.props;
    return athletes.map((athlete, idx) => {
      let athleteEvents = [];
      const protocol = this.state.protocol || this.props.match.protocol;
      if (protocol) {
        const events = (protocol || { events: [] }).events
          .filter(event => event.eventTime)
          .map(event => {
            const timeParts = event.eventTime.split(':');
            const seconds = Number(timeParts[0]) * 60 + Number(timeParts[1]);
            return {
              ...event,
              eventTime: seconds,
            };
          })
          .filter(i => i.type !== 'goal_shootout');
        athleteEvents = events.filter(
          event =>
            !!event.player &&
            !!athlete.sportnetUser &&
            event.player._id === athlete.sportnetUser._id,
        );
      }
      return (
        <ListRow
          key={athlete.sportnetUser._id}
          lastIndex={athletes.length === idx + 1}
        >
          <ListCol>
            <div style={{ display: 'flex' }}>
              {!!athlete.additionalData && !!athlete.additionalData.nr && (
                <div style={{ width: em(40) }}>{athlete.additionalData.nr}</div>
              )}
              <b>{athlete.sportnetUser.name}</b>
            </div>
          </ListCol>
          <ListCol flexWrap>
            {athleteEvents.map((event, eventIdx) => {
              let eventDetail = (sportSectorEvents
                ? sportSectorEvents.items
                : []
              ).find(i => i._id === event.type);
              if (!eventDetail) {
                eventDetail = (sportSectorEvents
                  ? sportSectorEvents.items
                  : []
                ).find(i => i.eventType === event.eventType);
              }
              return (
                <EventIconWrapper key={`${event.eventType}_${eventIdx}`}>
                  <EventIcon
                    size={15}
                    title={`${
                      eventDetail ? `${eventDetail.label} - ` : ''
                    }${Math.ceil(event.eventTime / 60)}'`}
                    eventType={event.eventType}
                    subType={event.type}
                    theme={this.props.theme}
                  />
                </EventIconWrapper>
              );
            })}
          </ListCol>
        </ListRow>
      );
    });
  };

  renderCrewList = (
    crew:
      | { [key: string]: IMatchNominationCrewItem }
      | Array<{ position: string; sportnetUser: {_id: string, name: string} }>,
  ) => {
    const sportSectorCrew = this.props.sportSectorCrew[
      this.props.match.rules.sport_sector
    ];
    if (Array.isArray(crew)) {
      return crew.map((crewMember, idx) => {
        const crewItem = (sportSectorCrew ? sportSectorCrew.items : []).find(
          i => i._id === crewMember.position,
        );
        if (crewItem) {
          return (
            <ListRow
              key={`${crewMember.sportnetUser._id}_${idx}`}
              lastIndex={crew.length === idx + 1}
            >
              <ListCol>
                <b>{getProp(crewMember, ['sportnetUser', 'name'])}</b>
              </ListCol>
              <ListCol>{crewItem.label}</ListCol>
            </ListRow>
          );
        }
        return null;
      });
    }
    return Object.keys(crew).map((key, idx) => {
      const crewItem = (sportSectorCrew ? sportSectorCrew.items : []).find(
        i => i._id === key,
      );
      if (crewItem) {
        return (
          <ListRow
            key={`${key}_${idx}`}
            lastIndex={Object.keys(crew).length === idx + 1}
          >
            <ListCol>
              <b>
                {getProp(
                  crew as any,
                  [key, 'label'],
                  getProp(crew, [key, 'displayName'], crew[key]),
                )}
              </b>
            </ListCol>
            <ListCol>{crewItem.label}</ListCol>
          </ListRow>
        );
      }
      return null;
    });
  };

  renderNomination = (
    homeAway: string,
    team: ITeam,
    nomination?: IMatchNomination | null,
  ) => {
    const athletes = nomination ? nomination.athletes || [] : [];
    const substitutes = athletes.filter(
      i => i.additionalData && i.additionalData.substitute,
    );
    return (
      <Segment
        raised
        header={
          <StyledSegmentHeader>
            <SegmentHeader size="m">
              <NominationHeader>
                {team.organization.logo_public_url && (
                  <img
                    alt="org_logo"
                    src={team.organization.logo_public_url || ''}
                  />
                )}
                {team.displayName}
              </NominationHeader>
            </SegmentHeader>
          </StyledSegmentHeader>
        }
      >
        {!!nomination ? (
          <NominationItems>
            {!!athletes.length && (
              <Segment
                noBottomGutter
                header={
                  <StyledSegmentHeader>
                    <SegmentHeader withSeparator size="s">
                      {homeAway === 'homeTeam' ? __('Domáci') : __('Hostia')}
                    </SegmentHeader>
                  </StyledSegmentHeader>
                }
              >
                {this.renderAthletesList(
                  team.appSpace,
                  nomination.athletes.filter(
                    i => !i.additionalData || !i.additionalData.substitute,
                  ),
                )}
              </Segment>
            )}
            {!!substitutes.length && (
              <Segment
                noBottomGutter
                header={
                  <StyledSegmentHeader>
                    <SegmentHeader withSeparator size="s">
                      {__('Náhradníci')}
                    </SegmentHeader>
                  </StyledSegmentHeader>
                }
              >
                {this.renderAthletesList(team.appSpace, substitutes)}
              </Segment>
            )}
            {((Array.isArray(nomination.crew) &&
              (nomination.crew || []).length) ||
              !!Object.keys(nomination.crew || {}).length) && (
              <Segment
                noBottomGutter
                header={
                  <StyledSegmentHeader>
                    <SegmentHeader withSeparator size="s">
                      {__('Realizačný tím')}
                    </SegmentHeader>
                  </StyledSegmentHeader>
                }
              >
                {this.renderCrewList(nomination.crew as any)}
              </Segment>
            )}
          </NominationItems>
        ) : (
          <NotFound>
            <Icon name="info" size="l" color={this.props.theme.inactiveColor} />
            <div>{__('Nominácia zatiaľ nebola uzatvorená')}</div>
          </NotFound>
        )}
      </Segment>
    );
  };

  render() {
    const {
      competition,
      match,
      currentPPO,
      sportSectorsPhases,
      sportSectorDelegates,
      sportSectorEvents,
      theme,
    } = this.props;

    if (!competition || !match) {
      return <ContentLoader theme={theme} />;
    }

    const homeTeam = match.teams.find(
      i => getProp(i, ['additionalProperties', 'homeaway'], '') === 'home',
    );
    const awayTeam = match.teams.find(
      i => getProp(i, ['additionalProperties', 'homeaway'], '') === 'away',
    );

    const title = `${homeTeam ? homeTeam.name : ''} - ${
      awayTeam ? awayTeam.name : ''
    }`;
    const subtitle = `${competition.name} | ${match.competitionPart.name}`;

    let homeTeamNomination = null;
    if (homeTeam && match.nominations) {
      homeTeamNomination = match.nominations.find(
        i => i.teamId === homeTeam._id,
      );
    }
    let awayTeamNomination = null;
    if (awayTeam && match.nominations) {
      awayTeamNomination = match.nominations.find(
        i => i.teamId === awayTeam._id,
      );
    }

    const protocolObj = this.state.protocol || match.protocol;

    return (
      <EntryAnimation key="CompetitionMatch">
        <Helmet titleTemplate="%s">
          <title>{title}</title>
          <meta
            property="og:url"
            content={`${getPPOUrl(currentPPO._id || '')}/competitions/${
              competition._id
            }/matches`}
          />
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={title} />
        </Helmet>
        <HeaderWrapper>
          <StyledHeaderXL>{title}</StyledHeaderXL>
          <StyledHeaderL>{subtitle}</StyledHeaderL>
        </HeaderWrapper>
        <Segment raised>
          <Match
            theme={this.props.theme}
            match={match}
            lastIndex
            sportSectorsPhases={sportSectorsPhases}
            fullWidth
            settings={{
              ...this.props.sportSectorSettings,
              ...match.settings,
            }}
            verbose
            onLiveScoreChange={({ liveState, timer, protocol }) => {
              this.setState({
                liveState,
                timer,
                protocol,
              });
            }}
          />
        </Segment>
        {!!protocolObj && protocolObj.events.length > 0 && (
          <Segment
            raised
            header={
              <StyledSegmentHeader>
                <SegmentHeader withSeparator size="m">
                  {__('Priebeh stretnutia')}
                </SegmentHeader>
              </StyledSegmentHeader>
            }
          >
            <TimeLine
              teams={match.teams}
              protocol={this.state.protocol || match.protocol}
              timer={
                this.state.timer && !!Object.keys(this.state.timer).length
                  ? this.state.timer
                  : match.timer
              }
              settings={{
                ...this.props.sportSectorSettings,
                ...match.settings,
              }}
              eventTypes={sportSectorEvents ? sportSectorEvents.items : []}
              phases={
                sportSectorsPhases[match.rules.sport_sector]
                  ? sportSectorsPhases[match.rules.sport_sector].items
                  : []
              }
            />
          </Segment>
        )}
        <Row>
          {!!homeTeam && (
            <Col xs={12} m={6}>
              {this.renderNomination('homeTeam', homeTeam, homeTeamNomination)}
            </Col>
          )}
          {!!awayTeam && (
            <Col xs={12} m={6}>
              {this.renderNomination('awayTeam', awayTeam, awayTeamNomination)}
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} m={!!match.managers && !!match.managers.length ? 6 : 12}>
            <Segment
              raised
              header={
                <StyledSegmentHeader>
                  <SegmentHeader withSeparator size="m">
                    {__('Miesto konania')}
                  </SegmentHeader>
                </StyledSegmentHeader>
              }
            >
              <Row>
                <Col xs={12} s={6}>
                  <FormGroup>
                    <Label>{__('Majiteľ')}</Label>
                    <Text>{match.sportGround.owner}</Text>
                  </FormGroup>
                </Col>
                <Col xs={12} s={6}>
                  <FormGroup>
                    <Label>{__('Adresa')}</Label>
                    <Text>{`${match.sportGround.street} ${match.sportGround.number}, ${match.sportGround.city}`}</Text>
                  </FormGroup>
                </Col>
                {!!match.sportGround.additionalData &&
                  !!match.sportGround.additionalData.totalCapacity && (
                    <Col xs={12} s={6}>
                      <FormGroup>
                        <Label>{__('Celková kapacita')}</Label>
                        <Text>
                          {match.sportGround.additionalData.totalCapacity}
                        </Text>
                      </FormGroup>
                    </Col>
                  )}
                {!!match.sportGround.additionalData &&
                  !!match.sportGround.additionalData.seatingCapacity && (
                    <Col xs={12} s={6}>
                      <FormGroup>
                        <Label>{__('Miest na sedenie')}</Label>
                        <Text>
                          {match.sportGround.additionalData.seatingCapacity}
                        </Text>
                      </FormGroup>
                    </Col>
                  )}
                {!!match.sportGround.additionalData &&
                  !!match.sportGround.additionalData.guestCapacity && (
                    <Col xs={12} s={6}>
                      <FormGroup>
                        <Label>{__('Kapacita sektora hostí')}</Label>
                        <Text>
                          {match.sportGround.additionalData.guestCapacity}
                        </Text>
                      </FormGroup>
                    </Col>
                  )}
                {!!match.sportGround.additionalData &&
                  !!match.sportGround.additionalData.vipSeatingCapacity && (
                    <Col xs={12} s={6}>
                      <FormGroup>
                        <Label>{__('Počet VIP sedadiel')}</Label>
                        <Text>
                          {match.sportGround.additionalData.vipSeatingCapacity}
                        </Text>
                      </FormGroup>
                    </Col>
                  )}
              </Row>
            </Segment>
          </Col>
          {!!match.managers && !!match.managers.length && (
            <Col xs={12} m={6}>
              <Segment
                raised
                header={
                  <StyledSegmentHeader>
                    <SegmentHeader withSeparator size="m">
                      {__('Zodpovedné osoby')}
                    </SegmentHeader>
                  </StyledSegmentHeader>
                }
              >
                <Row>
                  {match.managers.map((delegate, idx) => {
                    const delegateCodelistItem = (sportSectorDelegates[
                      match.rules.sport_sector
                    ]
                      ? sportSectorDelegates[match.rules.sport_sector].items
                      : []
                    ).find(i => i._id === delegate.type.value);
                    if (delegateCodelistItem) {
                      return (
                        <Col
                          key={`delegate_${delegate.user.name}_${idx}`}
                          xs={12}
                          s={6}
                        >
                          <FormGroup>
                            <Label>{delegate.type.label}</Label>
                            <Text>{delegate.user.name}</Text>
                          </FormGroup>
                        </Col>
                      );
                    }
                    return null;
                  })}
                </Row>
              </Segment>
            </Col>
          )}
        </Row>
      </EntryAnimation>
    );
  }
}

export default compose<React.FC<Props>>(
  connect(mapStateToProps),
  withTheme,
  connectSsr(),
)(CompetitionMatch);
