import DefaultLoader from '@sportnet/ui/lib/Loader';
import { rem } from 'polished';
import * as React from 'react';
import styled from '../../theme/styled-components';

const Wrapper = styled('div')`
  width: 100%;
  height: ${rem(80)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

class Loader extends React.PureComponent<{}> {
  render() {
    return (
      <Wrapper>
        <DefaultLoader size="l" />
      </Wrapper>
    );
  }
}

export default Loader;
