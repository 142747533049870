import * as React from 'react';
import Layout from '../../containers/Layout';

class Page extends React.PureComponent<{}> {
  render() {
    return <Layout>{this.props.children}</Layout>;
  }
}

export default Page;
