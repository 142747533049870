import { combineReducers } from 'redux';
import { reducer as listReducer } from 'redux-list';
import { appSettingsReducer } from './containers/DomainResolver/reducer';
import {
  applicationReducer,
  entitiesReducer,
  loadArticleReducer,
  loadPathReducer,
  loadSectionReducer,
  sectionTreeBySectionIdOrUniqIdReducer,
} from './pages/App/reducer';
import { currentArticleIdReducer } from './pages/Article/reducer';
import {
  currentSectionIdReducer,
  sectionArticlesByIdReducer,
} from './pages/Section/reducer';
import { urlDetailByUrlReducer } from './pages/UrlMap/reducer';

const rootReducer = combineReducers({
  application: applicationReducer,
  entities: entitiesReducer,
  list: listReducer,
  settings: appSettingsReducer,
  detail: combineReducers({
    articleById: loadArticleReducer,
    sectionById: loadSectionReducer,
    pathBySectionId: loadPathReducer,
    sectionTreeBySectionIdOrUniqId: sectionTreeBySectionIdOrUniqIdReducer,
  }),
  currentArticleId: currentArticleIdReducer,
  currentSectionId: currentSectionIdReducer,
  sectionArticlesById: sectionArticlesByIdReducer,
  urlDetailByUrl: urlDetailByUrlReducer,
});

export default rootReducer;

interface IRootState extends ReturnType<typeof rootReducer> {}
export type RootState = IRootState;
