import { getListResults } from 'redux-list/lib/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { IDetailInitialState } from '../../pages/App/reducer';
import {
  detailTypeSelector,
  entitiesSelector,
} from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';

// Current article id
export const currentArticleIdSelector = (state: RootState) =>
  state.currentArticleId;

const currentArticleDomain = createSelector(
  currentArticleIdSelector,
  detailTypeSelector('articleById'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

export const currentArticleIsFetchingSelector = createSelector(
  currentArticleDomain,
  domain => !!domain.isFetching,
);

export const currentArticleErrorSelector = createSelector(
  currentArticleDomain,
  domain => domain.error,
);

export const currentArticleSelector = createSelector(
  entitiesSelector,
  currentArticleIdSelector,
  (entities, id) =>
    id !== null && id in entities.articles ? entities.articles[id] : null,
);

export const similarArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_SIMILAR_ARTICLES),
  (entities, ids) => at(entities.articles, ids || []),
);

export const currentArticleSimilarArticlesSelector = createSelector(
  similarArticlesSelector,
  currentArticleSelector,
  (similarArticles, currentArticle) => {
    if (currentArticle) {
      return similarArticles.filter(
        article => article._id !== currentArticle._id,
      );
    }
    return [];
  },
);
