import { commit, CommitError, getListParameters } from 'redux-list/lib/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { Article } from '../../api/CmsApi';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import { updateEntities } from '../../containers/App/actions';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import ForbiddenError from '../../ForbiddenError';
import { NormalizedEntities, Pager } from '../../library/App';
import { ArticleId } from '../../library/CMS';
import NotFoundError from '../../NotFoundError';
import { RootState } from '../../rootReducer';
import UnauthorizedError from '../../UnauthorizedError';
import normalizeEntities from '../../utilities/normalizeEntities';
import { loadPath } from '../UrlMap/actions';

const create = actionCreatorFactory('ARTICLE');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const normalizeArticles = (data: Article[]) => {
  return {
    entities: {
      articles: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: Article },
    },
    result: data.map(item => item._id as ArticleId),
  };
};

export const loadArticle = createAsync<
  { id: ArticleId; contentDivider: string },
  Article
>(
  'LOAD_ARTICLE',
  async ({ id, contentDivider }, dispatch, getState, { CmsApi }) => {
    const { appSpace } = appSettingsSelector(getState());
    try {
      const response = await CmsApi.getPublicArticlesById(
        config.APP_ID,
        appSpace,
        contentDivider,
        Number(id),
        {
          expandWidgets: true,
        },
      );
      if (response.sectionid) {
        const sectionId = Array.isArray(response.sectionid)
          ? response.sectionid[0]
          : response.sectionid;
        await dispatch(loadPath.action({ id: sectionId, contentDivider }));
      }
      const { entities } = normalizeEntities('articles', [response]);
      dispatch(updateEntities(entities));
      return response;
    } catch (e) {
      if (e && e.details && e.details.code === 401) {
        throw new UnauthorizedError(e);
      }
      if (e && e.details && e.details.code === 403) {
        throw new ForbiddenError(e);
      }
      throw new NotFoundError(e);
    }
  },
);

// Set current article id
export const setCurrentArticleId = create<ArticleId | null>(
  'SET_CURRENT_ARTICLE_ID',
);

export const loadArticles = createAsync<
  {
    limit?: number;
    q?: string;
    page?: number;
    offset?: number;
    smarttags?: string[];
    contentDivider?: string;
    // articleId?: ArticleId;
  },
  Pick<NormalizedEntities<'articles'>, 'results'> & Pager
>('LOAD_ARTICLES', async (parameters, dispatch, getState, { CmsApi }) => {
  const { appSpace } = appSettingsSelector(getState());
  const params: {
    offset: number;
    limit: number;
    q?: string;
    smarttags?: string[];
  } = {
    offset: 0,
    limit: 12,
  };
  if (parameters.limit || parameters.limit === 0) {
    params.limit = parameters.limit;
  }
  if (parameters.page && parameters.page > 1) {
    params.offset = (parameters.page - 1) * params.limit;
  }
  if (parameters.offset || parameters.offset === 0) {
    params.offset = parameters.offset;
  }
  if (parameters.q || parameters.q === '') {
    params.q = parameters.q;
  }
  if (parameters.smarttags && parameters.smarttags.length > 0) {
    params.smarttags = parameters.smarttags;
  }
  const { articles, limit, offset, total } = await CmsApi.getPublicArticles(
    config.APP_ID,
    appSpace,
    parameters.contentDivider || config.DEFAULT_CONTENT_DIVIDER,
    params,
  );

  const { entities, results } = normalizeEntities('articles', articles!); // should be required
  dispatch(updateEntities(entities));

  return {
    results,
    limit: limit!,
    offset: offset!,
    total: total!,
  };
});

export const loadSimilarArticlesList = (params: {
  contentDivider: string;
}): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.LIST_SIMILAR_ARTICLES)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: config.LIST_SIMILAR_ARTICLES,
        load: async () => {
          try {
            const { results, limit, offset, total } = await dispatch(
              loadArticles.action({
                ...parameters,
                contentDivider: params.contentDivider,
              }),
            );
            return {
              total,
              limit,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
